.register-container {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../public/images/Background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .register-container .heading {
    margin-top: 40px;
    margin-bottom: 40px;
    color: #fff;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 40px;
  }
  
  .register-container .or {
    color: #fff;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 25px;
  }
  
  form .names {
    display: flex;
    gap: 15px;
  }
  
  form .names input {
    width: 100%;
    height: 50px;
    padding: 20px;
    border-radius: 25px;
    border: none;
    font-family: 'Poppins';
    color: #7D7D7D;
    font-weight: 500;
    font-size: 19px;
  }
  
  form .email input,
  form .password input {
    width: 100%;
    height: 50px;
    padding: 20px;
    border-radius: 25px;
    border: none;
    font-family: 'Poppins';
    color: #7D7D7D;
    font-weight: 500;
    font-size: 19px;
  }
  
  .register-form input::placeholder {
    padding-left: 10px;
    font-family: 'Poppins';
    color: #7D7D7D;
    font-weight: 500;
    font-size: 19px;
  }
  
  .register-form {
    min-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-logo-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
  
  .form-logo-section img {
    max-width: 100%;
    height: auto;
  }
  
  .google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .rounded-button {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    border: none;
    border-radius: 25px;
    padding: 10px 40px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    max-width: 330px;
  }
  
  .google-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  
  .button-text {
    font-weight: 500;
    font-size: 17px;
    font-family: 'Poppins';
  }
  
  .register-btn {
    display: flex;
    justify-content: center;
  }
  
  .register-btn button {
    position: relative;
    width: 180px;
    height: 50px;
    padding: 10px 30px;
    border: none;
    border-radius: 30px;
    background-color: #ff613a;
    color: white;
    font-weight: bold;
    cursor: pointer;
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 19px;
  }
  
  .term-conditions {
    text-align: center;
    color: #fff;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 18px;
  }
  
  .top-logo {
    position: absolute;
    top: 5px;
    left: 5px;
  }
  
  .top-logo > img {
    width: 60px;
    height: 55px;
  }
  
  @media (max-width: 764px){
    .register-container{
      padding-top: 35px;
      padding-bottom: 20px;
    }
    .register-form{
      padding: 20px;
    }
    .names {
      flex-direction: column;
    }
  }

  @media (max-width: 600px) {
    .register-form {
      min-width: 100%;
    }
  }
