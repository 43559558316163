.subscription-plan {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    color: black;
    background-image: url("../public/images/Background.jpg");
    background-size: cover;
    min-height: 100vh;
}

.form-logo-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.form-logo-section img {
  max-width: 100%;
  height: auto;
}

.plan-title-head {
  color: #ffffff;
  margin-top: 60px;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 40px;
}

.top-logo {
    position: absolute;
    top: 5px;
    left: 5px;
}

.top-logo > img {
    width: 60px;
    height: 55px;
}

.register-btn {
    display: flex;
    justify-content: center;
}

.register-btn button {
    width: 160px;
    height: 50px;
    padding: 10px 30px;
    border: none;
    border-radius: 30px;
    background-color: #ff613a;
    color: white;
    font-weight: bold;
    cursor: pointer;
    font-family: "Poppins";
    font-weight: 700;
    font-size: 19px;
}

.plan-text{
  color: #ffffff;
  font-weight: 500;
  margin-top: 10px;
}

.payment-options {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 40px;
}

.plan-options {
    display: flex;
    justify-content: center;
    gap: 20px;
}

@media screen and (max-width: 1024px) {
    .plan-options {
        flex-direction: column;
        align-items: center;
    }
   .plan-options label {
    width: 300px;
    height: 200px;
   }
    .subscription-plan{
        padding: 20px;
    }
}

label {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 15px;
    transition: all 0.3s ease;
    border : 3px solid #225E9B
}

@media screen and (max-width: 1024px) {
    label {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1px;
        background-color: #f1f1f1;
        padding: 10px;
        border-radius: 5px;
        transition: all 0.3s ease;
    }
    label .plan-title,
    label .plan-price,
    label .plan-stories {
        margin: 0;
        padding: 7px;
    }
}

.plan-title {
   color: #225E9B;
   font-size: 28px;
   font-weight: 700;
}

.plan-price {
  color: #0A3D67;
  font-size: 25px;
  font-weight: bold;
}

.plan-stories {
  color: #0A3D67;
  font-size: 15px;
  font-weight: 400;
}

.selected-plan {
    color: #ffffff;
    border-radius: 15px;
    background-color: #2f83d8; 
    border: 3px solid #cce5ff;
}

.selected-plan .plan-title {
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
}
.selected-plan .plan-price {
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;
}
.selected-plan .plan-stories {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

input[type="radio"] {
    display: none;
}

h1,
h2 {
    margin-bottom: 20px;
}

.input-placeholder::placeholder {
    color: gray;
}

.payment-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url(background_img.png);
    background-size: cover;
    padding: 5%;
    min-height: 100vh;
}

@media screen and (max-width: 1024px) {
    .payment-page {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-image: url(background_img.png);
        background-size: cover;
        min-height: 100vh;
    }
}

.plan-details {
    width: 50%;
    display: flex;
    justify-content: center;
    width: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.payment-form {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: left;
    color: black;
}

.footer {
    text-align: center;
    height: 1%;
    bottom: 0;
    width: 100%;
}

/* button:disabled {
    background-color: #cccccc;
    color: #666666;
} */

.toggle-container {
    display: flex;
    width: 325px; /* Increase the width to accommodate the larger Annual section */
    height: 50px;
    border: 2px solid #ffffff;
    background-color: #f0f0f0;
    border-radius: 30px;
    overflow: hidden;
    cursor: pointer;
}

.toggle-option {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding: 15px;
    font-weight: bold;
    color: #2985d6;
    background-color: #ffffff;
    transition: background-color 0.3s;
}

.toggle-option.monthly {
    flex-basis: 40%; /* Reduce the width percentage for the Monthly section */
}

.toggle-option.annual {
    flex-basis: 60%; /* Increase the width percentage for the Annual section */
}

.toggle-option.selected {
    background-color: #2985d6;
    color: #ffffff;
}
