.dashboard-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../public/images/Background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.dashboard-title-head {
  color: #ffffff;
  font-family: "Lato";
  margin-top: 60px;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 40px;
}

.stories-card {
  width: 100%;
  max-width: 1000px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.create-story-card {
  width: 232px;
  height: 145px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 5px dashed #ffffff;
  border-radius: 20px;
  color: #ffffff;
  padding-top: 10px;
  cursor: pointer;
}

.create-story-card p {
  text-align: center;
  font-weight: 600;
}

.story-cards {
  width: 232px;
  height: 194px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  /* justify-content: center;  */
}

.story-cards p {
  margin-top: -15px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .dashboard-container {
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .stories-card{
    justify-content: center;
  }
}

.top-right-btn{
    position: absolute;
    top: 5px;
    right: 5px;
}

.generate-story {
    display: flex;
    justify-content: center;
  }
  
  .generate-story button {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    gap: 5px;
    border: none;
    border-radius: 30px;
    background-color: #ff613a;
    color: white;
    font-weight: bold;
    width: 220px;
    height: 50px;
    cursor: pointer;
  }
  
  .generate-story > button > img {
    width: 45px !important;
    height: 35px !important;
  }