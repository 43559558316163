.billing {
  font-family: "Lato";
  color: black;
  font-size: 16px;
  line-height: 1.375;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../public/images/Background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  min-height: 100vh;
}

.payment-title-head {
  margin-top: 50px;
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 30px;
  font-family: "Lato";
  font-weight: 700;
}

.top-logo {
  position: absolute;
  top: 5px;
  left: 5px;
}

.top-logo > img {
  width: 40px;
  height: 35px;
}

.pay-table {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

#tablehead {
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-left: 23px;
  padding-right: 50px;
  color: white;
}

#tablehead h4,h3{
  max-width: 200px;
  width: 100%;
}

#tabledata {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-left: 35px;
  padding-right: 20px;
  color: white;
  gap:4px;
  margin-top: 10px;
}

#noPayment {
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  margin-top: 10px;
}

@media (max-width: 768px) {
  #tabledata {
    align-items: flex-start;
  }
  
  #tabledata h4 {
    margin-bottom: 10px;
  }
  
  #tabledata h4:nth-child(2) {
    margin-left: 0;
  }
}


@media (max-width: 768px) {
  #tablehead {
    align-items: flex-start;
    padding-left: 14px;
    padding-right: 22px;
  }
  
  #tablehead h3 {
    font-size: 1.075em;
  }


  #tabledata h4 {
  font-size: 1.025em;
}

  #tabledata{
    padding-right: 11px;
  }

  #tablehead h4 {
    margin-bottom: 10px;
  }
  
  #tablehead h4:nth-child(2) {
    margin-right: 0;
  }
}