.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  color: black;
  background-image: url("../public/images/Background.jpg");
  background-size: cover;
  padding-bottom: 20px;
  min-height: 100vh;
}

.inner-payment-container{
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-direction: column;
}


.payment-title-head {
  color: #ffffff;
  margin-top: 35px;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 40px;
}

#saveBtn:disabled{
    cursor: not-allowed;
}

.payment-card{
    width: 100%;
    height: 70px;
    max-width: 600px;
    background-color: #2E83D8;
    border: 3px solid #ffffff;
    border-radius: 10px;
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-left:15px;
    padding-right:15px;
    align-self: flex-end;
    color: #ffffff;
}

.payment-footer{
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    margin-top: 15px;
    color: #ffffff;
    width: 100%;
    max-width: 600px;
}



.payment-footer-link :nth-child(1){
    color: #ffffff;
    margin-left: 0px !important;
}

.payment-footer-link :nth-child(2){
    color: #E8E09F !important;
}

.payment-footer-link :nth-child(3){
    color: #E8E09F !important;
}

.payment-btn {
    display: flex;
    margin-top: 30px;
    justify-content: center;
}

.payment-btn button {
    width: 160px;
    height: 50px;
    padding: 10px 30px;
    border: none;
    border-radius: 30px;
    background-color: #ff613a;
    color: white;
    font-weight: bold;
    cursor: pointer;
    font-family: "Poppins";
    font-weight: 700;
    font-size: 19px;
}

.error-message{ 
    color: red; 
    text-align: center; 
    margin-left: 20px;
    margin-top:10px;
    font-size:20px;
    font-weight:bold
}

@media (max-width: 1024px) {
    .payment-container {
        padding: 30px;
    }
}


@media (max-width: 768px){
    .payment-container {
        padding: 30px;
    }
    .payment-btn {
        justify-content: flex-start;
        margin-left: 0px !important;
    }
    .payment-card{
        width: 55%;
        flex-direction: column;
        height: auto;
        align-self: center;
        padding-bottom: 30px;
    }
    .profile-fields {
        gap: 0px !important;
    }
}