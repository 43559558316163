.side-menu {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 400px;
  justify-content: center;
  margin-left: 0;
  position: fixed;
  width: 100%;
  z-index: 1;
  max-width: 250px;
}

/* iPhone Vertical */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .side-menu {
    position: absolute;
    width: 100%;
    max-width: 230px;
    height: 100%;
    max-height: 399px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
}

/* iPhone Horizontal */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .side-menu {
    position: absolute;
    width: 100%;
    max-width: 225px;
    height: 100%;
    max-height: 382px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.side-menu.visible {
  left: 0; /* slide in from the left */
}

.user-icon {
  width: 50%;
  height: 50%;
  /* align-items: center */
}

.user-icon-wrapper {
  position: fixed; /* Make the menu bar stick to the top left corner */
  top: 0; /* Position at the top */
  left: 0; /* Position at the left */
  /* color: #2985d6; */
  /* background-color: white; */
  padding: 0.3%;
  z-index: 2;
  /*
    width: 2.5%;
    height: auto; */
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .user-icon {
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .user-icon-wrapper {
    position: fixed; /* Make the menu bar stick to the top left corner */
    top: 0; /* Position at the top */
    left: 0; /* Position at the left */
    color: #2985d6;
    /* background-color: white; */
    padding: 0.3%;
    z-index: 2;
    width: 100%;
    max-width: 55px;
    height: auto;
    align-items: center;
  }
}

/* iPhone and iPad vertical */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .user-icon {
    width: 100%;
    max-width: 200px;
    height: 100%;
  }

  .user-icon-wrapper {
    position: fixed; /* Make the menu bar stick to the top left corner */
    top: 0; /* Position at the top */
    left: 0; /* Position at the left */
    color: #2985d6;
    /* background-color: white; */
    padding: 0.3%;
    z-index: 2;
    width: 100%;
    height: auto;
    max-width: 60px;
    align-items: center;
  }
}

.side_menu_ul {
  position: relative;
  top: 30px;
  z-index: 995;
  text-align: center !important;
  float: right;
  display: flex;
  flex-direction: column;
}

.side_menu_li {
  display: inline-block;
  padding: 0;
  /* margin: 5px !important; */
  width: 200px;
  height: 50px;
}

.side_menu_link {
  padding: 1px 1px;
  font-size: 22px !important;
  margin: 3px;
}

/* iPhone Horizontal */
@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .side_menu_ul {
    position: relative;
    z-index: 995;
    text-align: center !important;
    float: right;
    display: flex;
    flex-direction: column;
  }

  .side_menu_li {
    display: inline-block;
    padding: 0;
    margin: 2px !important;
    width: 200px;
    height: 50px;
  }

  .side_menu_link {
    padding: 1px 1px;
    font-size: 18px !important;
    margin: 2px;
  }
}

/* iPad Vertical */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
  .side_menu_ul {
    position: relative;
    z-index: 995;
    text-align: center;
    float: right;
    display: flex;
    flex-direction: column;
  }
  .side_menu_li {
    /* float: right; */
    display: inline-block;
    padding: 10px;
    width: 200px;
    height: 50px;
  }

  .side_menu_link {
    padding: 1px 1px;
    font-size: 24px !important;
    margin: 1px;
  }
}

/* iPhone Vertical */
@media only screen and (max-width: 600px) and (orientation: portrait) {
  .side_menu_ul {
    position: relative;
    z-index: 995;
    text-align: center;
    float: right;
    display: flex;
    flex-direction: column;
  }
  .side_menu_li {
    display: inline-block;
    padding: 10px;
    width: 166px;
    height: 46px;
  }

  .side_menu_link {
    padding: 1px 1px;
    font-size: 15px !important;
    margin: 1px;
  }
}
