.slideshow {
  position: relative;
  width: 100%;
  height: 330px; /* Adjust the height as needed */
  overflow: hidden;
}

@media (max-width: 1024px) {
.slideshow {
  position: relative;
  width: 100%;
  height: 220px; /* Adjust the height as needed */
  overflow: hidden;
}
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  display: flex;
}

.slide.active {
  opacity: 1;
}

.slide-image {
  width: 50%;
  height: 100%;
  background-size: 100% 100%;
  background-position: center;
}

.slide-text {
  background-color: #8fc0ea;
  color: #fff;
  padding: 20px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Add media query for screen sizes smaller than 1024px */
@media (orientation: portrait) {

   .slideshow {
       height: 1100px; /* Increase the height for mobile devices */
    }

  .slide {
    flex-direction: column;
  }

  .slide-text {
    background-color: #8fc0ea;
      color: #fff;
      width: 100%;
      height: 50%;
      order: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .slide-image {
    width: 100%;
    height: 45%;
    background-size: 100% 100%;
    order: 1;
    padding: 10px;
  }
}

@media (orientation: portrait) and (max-width: 800px) {

   .slideshow {
       height: 500px; /* Increase the height for mobile devices */
    }

  .slide {
    flex-direction: column;
  }

  .slide-text {
    background-color: #8fc0ea;
      color: #fff;
      width: 100%;
      height: 50%;
      order: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .slide-image {
    width: 100%;
    height: 45%;
    order: 1;
    padding: 10px;
  }
}

.header-text {
  background-color: #3185cb;
  padding: 10px;
  border-radius: 5px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  width: 70%;
  margin-left: 3%;
  text-align: center;
}

.body-text {
  background-color: #ff633c;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  margin-left: 3%;
}

@media (max-width: 800px) {
    .header-text {
      background-color: #3185cb;
      padding: 8px;
      border-radius: 5px;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
      width: 85%;
      margin-left: 3%;
      text-align: center;
    }

    .body-text {
      background-color: #ff633c;
      padding: 10px;
      border-radius: 5px;
      font-size: 12px;
      margin-left: 3%;
    }

}


.arrow {
  position: absolute;
  top: calc(50% - 1rem);
  font-size: 2rem;
  color: #fff;


  cursor: pointer;
  z-index: 2;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

.dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
  padding: 5px;
}

.dot {
  width: 12px; /* Increase the size of the dots */
  height: 12px;
  background-color: #bbb;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}

.body-text-line {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  font-size: 20px;
}

/* iPad Vertical */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .body-text-line {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
      font-size: 30px;
    }
}

/* iPhone Vertical */
@media only screen and (max-width: 800px) and (orientation: portrait) {
    .body-text-line {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
      font-size: 12px;
    }
}


/* iPhone Horizontal */
@media only screen and (max-width: 800px) and (orientation: landscape) {
    .body-text-line {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
      font-size: 12px;
    }
}

.bullet-icon {
  font-size: 18px;
  margin-right: 5px;
}
