.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../public/images/Background.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.timer-message {
  margin-bottom: 20px;
  color: white;
}

.timer-message > h1 {
  font-family: "Poppins";
  font-size: 45px;
  font-weight: 600;
}

.timer-message > p {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.animation-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 176px;
  height: 117px;
}

.timer-wrapper {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.timer {
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  color: white;
}

.value {
  font-size: 60px;
  color: white !important;
  font-family: "Poppins";
}

.info {
  max-width: 360px;
  margin: 40px auto 0;
  text-align: center;
  font-size: 26px;
}

.bottom-logo {
  position: absolute;
  left: 15px;
  bottom: 25px 
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .overlay-container {
    padding: 20px;
  }
  
  .timer-wrapper {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  .timer-message{
    text-align: center;
  }

  .timer-message > h1 {
    font-size: 30px;
  }
  
  .bottom-logo {
    position: absolute;
    bottom: 9px 
  }

  .timer-message > p {
    font-size: 16px;
  }
  
  .animation-image {
    width: 144px;
    height: 87px;
  }
  
  .value {
    font-size: 40px;
  }
  
  .info {
    max-width: 250px;
    font-size: 20px;
  }
}

@media screen and (max-width: 667px) and (max-height: 375px) and (orientation: landscape) {
  .timer-wrapper {
    margin-top: -40px;
  }
}

