/* .App {
} */

.chatbox {
    display: flex;
    background-color: #282c34;
    color: white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.body {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 92%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.chat-input-holder {
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10%;
    max-height: 70px;
    background-color: #8fc0ea;
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .body {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 99%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .chat-input-holder {
        padding: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 22%;
        background-color: #8fc0ea;
    }
}
/* iPad vertical */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .body {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .chat-input-holder {
        padding: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 20%;
        max-height: 120px;
        background-color: #8fc0ea;
    }
}

/* iPhone vertical */
@media only screen and (max-width: 600px) and (orientation: portrait) {
    .body {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 84%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .chat-input-holder {
        padding: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 17%;
        max-height: 120px;
        background-color: #8fc0ea;
    }
}

.text-container {
    overflow-y: scroll;
    text-align: left;
    padding: 2% 2% 7% 2%;
    font-family: "Comic Sans MS", sans-serif;
    background-image: url("background_img.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
    float: left;
    flex: 1;
    font-size: 18px;
}

@media only screen and (max-width: 900px) {
    .text-container {
        overflow-y: scroll;
        text-align: left;
        padding: 2% 2% 7% 2%;
        font-family: "Comic Sans MS", sans-serif;
        background-image: url("background_img.png");
        background-repeat: no-repeat;
        width: 50%;
        height: 93%;
        float: left;
    }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .text-container {
        overflow-y: scroll;
        text-align: left;
        padding: 2% 2% 7% 2%;
        font-family: "Comic Sans MS", sans-serif;
        background-image: url("background_img.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        height: auto;
        float: left;
    }
}

.popup {
    position: fixed; /* Positioning relative to the viewport */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -160%); /* Correct offset */
    background: #fff;
    color: #000;
    border-radius: 10px;
    padding: 3%;
    width: 40%; /* Set width to 60% to leave 20% space on both right and left */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* iPhone horizontal */
@media only screen and (max-width: 900px) and (orientation: landscape) {
    .popup {
        position: fixed; /* Positioning relative to the viewport */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        transform: translate(-50%, -100%); /* Correct offset */
        background: #fff;
        color: #000;
        border-radius: 10px;
        padding: 3%;
        width: 40%; /* Set width to 60% to leave 20% space on both right and left */
        box-sizing: border-box; /* Include padding and border in element's total width and height */
    }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .popup {
        position: fixed; /* Positioning relative to the viewport */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        transform: translate(-50%, -220%); /* Correct offset */
        background: #fff;
        color: #000;
        border-radius: 10px;
        padding: 3%;
        width: 60%; /* Set width to 60% to leave 20% space on both right and left */
        box-sizing: border-box; /* Include padding and border in element's total width and height */
    }
}

@media only screen and (max-width: 800px) and (orientation: portrait) {
    .popup {
        position: fixed; /* Positioning relative to the viewport */
        top: 50%; /* Center vertically */
        left: 50%; /* Center horizontally */
        transform: translate(-50%, -140%); /* Correct offset */
        background: #fff;
        color: #000;
        border-radius: 10px;
        padding: 3%;
        width: 70%; /* Set width to 60% to leave 20% space on both right and left */
        box-sizing: border-box; /* Include padding and border in element's total width and height */
    }
}

.feedbackthanks {
    background: #fff;
    color: #000;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: "50px";
    transform: translate(-50%, -650%);
}

@media only screen and (max-width: 900px) {
    .feedbackthanks {
        background: #fff;
        color: #000;
        font-weight: normal;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 1%;
        transform: translate(-50%, -260%);
        font-size: 80%;
    }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .feedbackthanks {
        background: #fff;
        color: #000;
        font-weight: normal;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 1%;
        transform: translate(-50%, -600%);
        font-size: 80%;
    }
}

.flag-icon-container {
    position: absolute;
    top: 70%;
    left: 2%;
    width: 15%;
    height: 15%;
    z-index: 1;
}

.book {
    align-items: center;
    justify-content: center;
    padding: 15%;
    background-image: url("background_img.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 50%;
    height: auto;
    float: left;
}

@media only screen and (max-width: 900px) {
    .book {
        align-items: left;
        justify-content: left;
        padding: 10% 10% 10% 20%;
        background-image: url("background_img.png");
        background-repeat: no-repeat;
        width: 50%;
        height: 93%;
        float: left;
    }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .book {
        align-items: left;
        justify-content: left;
        padding: 20% 20% 20% 35%;
        background-image: url("background_img.png");
        background-repeat: no-repeat;
        width: 100%;
        height: 50%;
        float: left;
    }
}

.Image {
    background-image: url("default_image3.jpeg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 50%;
    height: auto;
    float: right;
}

@media only screen and (max-width: 900px) {
    .Image {
        background-image: url("default_image3.jpeg");
        background-repeat: no-repeat;
        width: 50%;
        height: 93%;
        float: right;
    }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .Image {
        background-image: url("default_image3.jpeg");
        background-repeat: no-repeat;
        width: 100%;
        height: 50%;
        float: right;
    }
}

.img {
    padding: 0;
    width: 100%;
    height: 100%;
    float: top;
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .img {
        padding: 0;
        width: 100%;
        height: 100%;
        float: top;
    }
}

.sidemenu {
    width: 260px;
    padding: 10px;
    background-color: #202123;
}
/* under 640px do this */
@media (max-width: 640px) {
    .sidemenu {
        display: none;
    }
}

.side-menu-button {
    padding: 12px;
    border: 1px solid white;
    border-radius: 5px;
    text-align: left;
    transition: ease 0.25s all;
    cursor: pointer;
}
.side-menu-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
.side-menu-button span {
    padding-left: 6px;
    padding-right: 12px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.voices-menu {
    background-color: white;
    width: 130%;
    padding: 5px;
    border-radius: 5px;
    color: black;
    font-size: 1.25em;
    border: none;
    outline: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}
.age-input {
    background-color: white;
    width: 80%;
    padding: 5px;
    border-radius: 5px;
    color: black;
    font-size: 1.25em;
    border: none;
    outline: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.chat-message.chatgpt {
    color: #ffffff; /* #00509e */
}

.chat-message-center {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding: 12px;
    padding-left: 24px;
    padding-right: 24px;
}

.message {
    padding-left: 1px;
    padding-right: 24px;
    padding-top: 5px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.select-models {
    border: 1px solid white;
    padding: 12px;
    border-radius: 5px;
    color: white;
    background: transparent;
    outline: none;
    cursor: pointer;
    max-width: 100%;
    min-width: 100%;
}
.select-models option {
    background: black;
    color: white;
}

.button-picker {
    border: none;
    padding: 6px 12px;
    border-radius: 5px;
    color: white;
    background: #444654;
    margin-top: 6px;
    cursor: pointer;
}
.button-picker:hover {
    background: #626473;
}

.info {
    opacity: 0.66;
    font-size: 0.8em;
    margin-top: 6px;
}

.side-label {
    font-size: 0.9em;
    margin-top: 1em;
    margin-bottom: 0.5em;
    padding-bottom: 0em;
    display: block;
}

.form {
    display: flex;
    gap: 1px;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .form {
        display: flex;
        gap: 0px;
        flex-direction: column;
    }
}

.input-fields {
    display: flex;
    gap: 2%;
    flex-direction: row;
    width: 100%;
    color: #3185cb; /* Dark Blue */
    font-weight: bold; /* Bold text */
    height: 90%;
    margin-top: 5px;
    font-size: 20px;
}

.input-fields::placeholder {
    color: gray;
    font-weight: normal;
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .input-fields {
        display: flex;
        gap: 1%;
        flex-direction: row;
        color: #3185cb; /* Dark Blue */
        font-weight: bold; /* Bold text */
        margin-top: 1px;
    }

    .input-fields::placeholder {
        color: gray;
        font-weight: normal;
    }
}

.controls {
    display: flex;
    gap: 1px;
    flex-direction: row;
    height: 60px;
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .controls {
        display: flex;
        gap: 1%;
        flex-direction: row;
    }
}

.submit {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background: #2985d6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 12px;
    font-size: 14px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Salsa", cursive;
}

.feedbackControls {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background: #2985d6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 5px;
    font-size: 12px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.submit:hover {
    background: #216bab;
}

.pause {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background: #8fc0ea;
    display: flex;
    /* align-items:center;
  justify-content:center; */
    padding: 5px 12px;
    font-size: 15px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.routes-container {
    width: 100%;
    background-color: #fff; /* Add background color */
}

.AudioInProgress {
    position: absolute;
    top: 50%;
    left: 60%;
    right: 10%;
    bottom: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .AudioInProgress {
        position: absolute;
        top: 60%;
        left: 10%;
        right: 10%;
        bottom: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
}

.Dots {
    visibility: hidden;
    animation: dots 1s infinite;
}

@keyframes dots {
    0% {
        visibility: hidden;
    }
    33% {
        visibility: visible;
    }
    66% {
        visibility: visible;
    }
    100% {
        visibility: visible;
    }
}

.loading-container {
    position: absolute;
    top: 60%;
    left: 10%;
    right: 10%;
    bottom: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 50%;
    height: 50%;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Adjust the z-index as needed */
}

.loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

.spinner-icon {
    font-size: 45px;
    color: #ffffff; /* Change spinner color to white */
}

.spinner-text {
    font-size: 20px;
    color: #ffffff; /* Change text color to white */
}

.play-button.pulsate {
    animation: pulsate-animation 1s infinite;
}

@keyframes pulsate-animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.4);
    }
    100% {
        transform: scale(1);
    }
}

.slick-next {
    right: 40px !important;
}

@media (max-width: 1024px) {
    /* #storyparent {
        flex-direction: column !important;
        height: auto !important;
    } */

    .div5 {
        min-height: 340px !important;
        padding-top: 40px !important;
        padding-left: 22px !important;
        padding-right: 5px !important;
        border-radius: 10px 0 0 0;
        justify-content: center;
        /* padding-bottom: 65px !important; */
    }

    .div6 {
        width: auto !important;
        padding-top: 20px;
        padding-left: 50px !important;
        padding-bottom: 50px !important;
        background: white !important;
        justify-content: center !important;
        border-radius: 0 0 0 10px;
    }

    .slick-slide img {
        width: 327px !important;
    }

    .div6 > img {
        max-width: 100% !important;
    }

    /* .div6 {
        width: 100% !important;
    } */

    /* .div1,
    .div2,
    .div3,
    .div4 {
    display: none !important;
  } */
    .audio-button {
        top: 7px !important;
        left: -3px !important;
    }
    .audio-button > button {
        width: 111px !important;
        padding: 7px !important;
    }
    .story-actions {
        top: 7px;
        left: 85% !important;
        padding: 7px !important;
    }
    .slick-next {
        right: -23px !important;
    }
    .carousel-container {
        margin-top: 30px !important;
    }

    .chatbox-container:nth-child(2) {
        order: 1;
    }
    .message:nth-child(2) {
        font-size: 15px;
    }
}

.highlighted {
    background-color: red;
}
.highlight {
    background-color: red;
}

@media (max-width: 767px) {
    .story-actions {
        top: 7px;
        left: 78% !important;
        padding: 7px !important;
    }
}
