/*
-----------------------------------------------------------------------------------
-- CSS Reset
-- Definition Lists
-- BASE
-- GENERAL
 	-- GRID
 	-- LAYOUT
 	-- Mini-gallery
 	-- MODULES
 	-- TOP PANEL
 	-- MAIN MENU
 		-- Sub Menu
 	-- SLIDER
 	-- VIDEO
 	-- Image wrapper
 	-- Middle container
 	-- Top content
 	-- Bottom content
 	-- jCarousel
 	-- Gallery
 	-- Isotope
 	-- footer language bar
 	-- Bottom container
 	-- Posts container
 	-- Back to top button
 	-- Styles for Buttons
 	-- Drop-caps
 	-- Pull-quotes
	-- Widget
 		-- Widget Recent Entries, Archives, Twitter
 		-- Widget Accordion
 		-- Highlighter
 		-- Widget Tabbed
 		-- Widget Toggle
		-- TABS
		-- Widget Pagenav
		-- Callout
		-- Widget Categories Type-1
		-- Widget Categories Type-2
		-- Widget Categories Type-3
 		-- Widget Video
		-- Widget Latest Posts
		-- Widget Recent Tweets
		-- Widget Recent Posts
		-- Widget Popular Posts
		-- Media Categories
		-- Flickr
	-- Contact Info
	-- User Login
	-- Contact Us
	-- 404 Page
	-- Sitemap
	-- Contact Form
	-- Dividers and Borders
	-- Custom Box Styles
	-- Notifications Box Styles
	-- Progress bar
	-- List Styles
	-- Tables
	-- Pricing Tables
	-- Elements styling
	-- portfolio
	-- Blog Page
	-- Comment list
	-- owl-carousel
	-- color versions (carousel)
	-- FORM
	-- Fonts Theme
	-- Colors for: top bar, menu, buttons, widget titles


*/

/* - CSS Reset -> Begin ----------------------------------------- */
@import url(https://fonts.googleapis.com/css?family=Lobster+Two:regular&subset=latin);
@import url(https://fonts.googleapis.com/css?family=Lobster+Two:regular&subset=latin);
@import url(https://fonts.googleapis.com/css?family=Lato:regular&subset=latin);



html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

*,*:hover,*:focus,*:active,*:visited{
	outline:none;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
    display: block;
}

nav ul,
nav ol {
    list-style: none;
    list-style-image: none;
}

ul {
    list-style: none;
    margin: 0;
}
ol{
	list-style-type: decimal !important;
}


q {
    font-style: italic;
    quotes: "\00ab" "\00bb";
}

abbr[title] {
	border-bottom: 1px dotted;
	cursor: help;
}

small {
	font-size:11px;
}

big {
	font-size:125%;
}

ins {
    text-decoration: none;
    background: #FCEC53;
}

del {
    text-decoration: line-through;
}
sup{
	display: inline-block;
	vertical-align: super;
}

b,
strong {
	font-weight: 700;
}

sub{
	display: inline-block;
	vertical-align: sub;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

object {
	display: block;
	border: 1px solid #fff;
}
cite,
dfn,
em{
	font-style: italic;
}
blockquote,
.slogan,
.testimonial{
	position: relative;
	border-radius: 9px;
	padding: 15px 45px;
	content:"";
	font-style: italic;
	margin-bottom: 20px;
	font-size: 1em;
	line-height: 1.375;
	font-weight: 100;
	quotes:"\201C""\201D" !important;
	z-index: 1;
}

img{
	max-width: 100%;
	height: auto;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
}
.testimonial{
	padding: 15px;
	padding-right: 20px;
}
.slogan{
	border-radius: 0;
	margin: 0 auto 20px;
	position: relative;
	z-index: 1;
	text-align: center;
}
blockquote:before,
blockquote:after,
.slogan>.widget_text:before,
.slogan>.widget_text:after,
.testimonial p:before,
.testimonial p:after{
	position: absolute;
	top: 30px;
	left: 14px;
	content: open-quote !important;
	font-size: 60px;
	line-height: 10px;
	font-style: normal;
	font-weight: 700;
}
.testimonial p:before,
.testimonial p:after{
	position: static;
	line-height: 0;
	margin-bottom: -5px;
	display: inline-block;
	vertical-align: bottom;
}
.testimonial p:after{
	line-height: 0;
	margin-left: 4px;
}
blockquote:after,
.slogan>.widget_text:after,
.testimonial p:after{
	bottom: -5px;
	right: 11px;
	top: auto;
	left: auto;
	content: close-quote !important;
}
.testimonial img{
	margin-right: 15px;
	float: left;
	width: 85px;
	height: 85px;
	border-radius: 10px;
	overflow: hidden;
	max-width: 100%;
	height: auto;
}
.testimonial .author{
	margin-top: -20px;
	font-weight: 700;
	text-align: right;
}
.slogan:after{
	content: "";
	position: absolute;
	bottom: 0;
	left: 10%;
	right: 10%;
	border-bottom: 1px solid;
}
.slogan>*:last-child{
	margin-bottom: 0;
}
table{
	border-collapse: separate !important;
	border-spacing: 0 !important;
	font-size: 0.875em;
	line-height: 1.2857142857;
	margin-bottom: 20px;
	border-width: 1px 0 0 1px !important;
	width: 100%;
}

table,
th,
td {
	border: 1px solid rgba(0, 0, 0, 0.1);
}
th {
	border-width: 0 1px 1px 0;
}
th,
th {
	font-weight: 700;
	padding: 8px;
	text-transform: uppercase;
}
td,
td {
	padding: 8px;
}
td {
	border-width: 0 1px 1px 0;
}
caption, th, td {
font-weight: normal;
text-align: left;
}

/* Definition Lists */
dl{
	margin-bottom: 20px;
}
dt{
	font-weight: 700;
}
dd{
	margin-bottom: 20px;
}

input, textarea {
	outline: none;
	font-family: inherit;
	font-size: inherit;
	font-variant: inherit;
	font-stretch: inherit;
	-webkit-box-shadow: none;
	-moz-box-shadow:    none;
	box-shadow:         none;
}

/* Chrome, Safari */
input::-webkit-input-placeholder {
	color: inherit;
	font-size: inherit;
}
textarea::-webkit-input-placeholder {
	color: inherit;
	font-size: inherit;
}

/* Firefox */
input:-moz-placeholder {
	color: inherit;
	font: inherit;
}
textarea:-moz-placeholder {
	color: inherit;
	font: inherit;
}

/* - CSS Reset -> End ----------------------------------------- */

header, footer, section, article, nav {
    display:block;
    margin:0;
    padding:0;
}

/*
##############################################################
BASE -> Begin
##############################################################

/* - CSS Reset -> End ----------------------------------------- */

body {
    background-color: #fff;
	font: 14px/1.5 Tahoma, Arial, Helvetica, sans-serif;
	-webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
	-webkit-text-size-adjust: 100%;
}

a {
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: background 250ms linear 0s, color 250ms linear 0s;
	   -moz-transition: background 250ms linear 0s, color 250ms linear 0s;
	    -ms-transition: background 250ms linear 0s, color 250ms linear 0s;
		 -o-transition: background 250ms linear 0s, color 250ms linear 0s;
			transition: background 250ms linear 0s, color 250ms linear 0s;
}

a:hover{
	color: inherit;
}

p {
	margin: 0 0 20px 0;
	padding: 0;
}

b {
	font-size: 14px;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	margin-bottom: 15px;
}

h1 {
	font-size: 1.625em;
	line-height: 1.3846153846;
}
h2 {
	font-size: 1.5em;
	line-height: 1;
}
h3 {
	font-size: 1.375em;
	line-height: 1.0909090909;
}
h4 {
	font-size: 1.25em;
	line-height: 1.2;
}
h5 {
	font-size: 1.125em;
	line-height: 1.3333333333;
}
h6 {
	font-size: 1em;
	line-height: 1.5;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	margin-top: 0;
}

address{
	font-style: italic;
	margin-bottom: 20px;
}

code, kbd, tt, var, samp, pre {
	font-family: monospace, serif;
	font-size: 0.875em;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
	line-height: 1.571;
}

pre{
	border: 1px solid rgba(0, 0, 0, 0.1);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin-bottom: 20px;
	max-width: 100%;
	overflow: auto;
	padding: 12px;
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

ul{
	margin-bottom: 20px;
}
li>ul{
	margin-left: 20px;
	margin-bottom: 0;
}
ul li,
ol li{
	position: relative;
}
li li{
	font-size: inherit;
}
ul li:before{
	content: "\f00c";
	font-size: 0.55em;
	color: #fff;
	height: 1.6em;
	width: 1.6em;
	line-height: 1.6em;
	margin-right: 7px;
	text-align: center;
	vertical-align: middle;
	position: relative;
	top: -0.1em;
	border-radius: 0.85em;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

ol{
	margin-left: 24px;
}


li.widget{
	font-size: inherit;
	position: static;
	padding: 0;
}
li.widget:before{
	display: none;
}
.t-header-1 h1, .t-header-1 h2, .t-header-1 h3, .t-header-1 h4, .t-header-1 h5, .t-header-1 h6 {font-family: 'Jockey One', cursive;}
.t-header-2 h1, .t-header-2 h2, .t-header-2 h3, .t-header-2 h4, .t-header-2 h5, .t-header-2 h6 {font-family: 'Oswald', sans-serif;}
.t-header-3 h1, .t-header-3 h2, .t-header-3 h3, .t-header-3 h4, .t-header-3 h5, .t-header-3 h6 {font-family: 'Yanone Kaffeesatz', sans-serif; font-weight: 600;}

.kids_clear {clear: both;}

.clearfix:after {
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
	font-size: 0;
}

.wrapper {
	overflow: hidden;
}
/*
##############################################################
BASE -> End
##############################################################
*/

/*
##############################################################
GENERAL -> Begin
##############################################################
*/

.icon {
	padding: 0 10px 0 0;
	vertical-align: middle;
}

/* GRID */
.grid-row + .grid-row{
	margin-top: 30px;
}
div[class*="grid-col-"]{
	float:left;
}
div[class*="grid-col-"] + div[class*="grid-col-"]{
	margin-left:2.5%;
}
.grid-col-4{
/*	width: 31.6%;*/
	width: 31.4%;
}
.grid-col-3{
	width: 23.1%;
}
.grid-col-6{
	width: 48.745%;
}
.grid-col-8{
	width: 65.83%;
}
.grid-col-9{
	width: 74.35%;
}
.grid-col-12{
	width: 100%;
}


/*
##############################################################
LAYOUT -> Begin
##############################################################
*/

.l-page-width {
    width: 1170px;
    text-align: left;
    margin: 0 auto;
}

.l-float-left {
    float: left;
}

.l-float-right {
    float: right;
}

.last {
	margin-right:0 !important;
}

.page-content{
	position: relative;
	width: 100%;
	z-index: 1;
}
.page-content .container{
	position: relative;
	z-index: 1;
}

@media screen and (max-width: 1190px){
	.l-page-width{
		width: 940px;
	}
}
@media screen and (max-width: 980px){
	.l-page-width{
		width: 738px;
	}
}
@media screen and (max-width: 767px){
	.l-page-width{
		width: 100%;
		box-sizing: border-box;
		padding-left: 20px;
		padding-right: 20px;
	}

	.grid-row{
		margin: 0 !important;
	}
	.grid-col + .grid-col{
		margin-top: 30px;
	}
	.grid-row + .grid-row{
		margin-top: 30px !important;
	}
	.grid-col{
		width: 100%;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

/* -- Minigallery --> Begin -- */
.pp_gallery li{
	font-size: 1em;
}
.pp_gallery li:before{
	display: none;
}

.minigallery li {
	-webkit-transition: background-color .25s linear;
	   -moz-transition: background-color .25s linear;
		 -o-transition: background-color .25s linear;
			transition: background-color .25s linear;
}
.minigallery li img {display: block;}

.camera_wrap .camera_pag .camera_pag_ul li.cameracurrent ,.flex-control-nav li a.flex-active {
    /*background-image: url('../images/blue/slider_control_active_item_bg.png');*/
}

.post-meta {
	position: absolute;
	top: 10px;
	left: 0;
	width: 70px;
}

/*
##############################################################
MODULES -> Begin
##############################################################
*/

.kids-bg-level-1 {
	position: relative;
    padding: 0;
    background-position: 0 0;
    background-repeat: repeat-x;
}

.bg-level-2-page-width-container {
    position: relative;
	z-index: 3;
}


.secondary-page .bg-level-2-page-width-container {
    background:none!important;
}

.bg-level-1{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-position: center top;
    background-repeat: repeat-x;
    z-index: 0;
}

.bg-level-1-right {
    position: absolute;
    top: 0;
    height: 100%;
    background-position: left top;
    background-repeat: repeat-x;
}
.bg-level-2{
	position: absolute;
	top: -14px;
	bottom: 0;
	left: 0;
    z-index: 1;
}
.with-slider>.bg-level-2{
	bottom: 58px;
}
.bg-level-2.first-part{
	background-position: right top;
}
.bg-level-2.second-part{
	background-position: left top;
	left: auto;
	right: 0;
}

.t-pattern-1 .bg-level-2 {
    background-image: url("../images/pattern_balloons.png");
}

.t-pattern-2 .bg-level-2{
    background-image: url("../images/pattern_star.png");
}

.t-pattern-3 .bg-level-2{
    background-image: url("../images/pattern_feather.png");
}

.t-pattern-4 .bg-level-2{
    background-image: url("../images/pattern_dandelion.png");
}

.t-pattern-5 .bg-level-2{
    background-image: url("../images/pattern_dragonfly.png");
}

.t-pattern-5 .bg-level-2.second-part{
	background-position: right top;
	-webkit-transform: rotateY(180deg);
	-moz-transform: rotateY(180deg);
	-ms-transform: rotateY(180deg);
	transform: rotateY(180deg);
}

.t-pattern-6 .bg-level-2{
   	background-image: url("../images/pattern_butterfly.png");
}

.t-pattern-7 .bg-level-2{
    background-image: url("../images/pattern_airplanes.png");
}

.t-pattern-8 .bg-level-2{
    background-image: url("../images/pattern_owl.png");
}

.t-pattern-9 .bg-level-2{
    background-image: url("../images/pattern_present.png");
}

.t-pattern-10 .bg-level-2{
    background: transparent;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (-o-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {

	.t-pattern-1 .bg-level-2 {
    	background-image: url("../images/pattern_balloons@2x.png");
    	background-size: 381px;
	}

	.t-pattern-2 .bg-level-2{
	    background-image: url("../images/pattern_star@2x.png");
	    background-size: 340px;
	}

	.t-pattern-3 .bg-level-2{
	    background-image: url("../images/pattern_feather@2x.png");
	    background-size: 336px;
	}

	.t-pattern-4 .bg-level-2{
	    background-image: url("../images/pattern_dandelion@2x.png");
	    background-size: 357px;
	}

	.t-pattern-5 .bg-level-2{
	    background-image: url("../images/pattern_dragonfly@2x.png");
	   	background-size: 338px;
	}

	.t-pattern-6 .bg-level-2{
	   	background-image: url("../images/pattern_butterfly@2x.png");
	   	background-size: 322px;
	}

	.t-pattern-7 .bg-level-2{
	    background-image: url("../images/pattern_airplanes@2x.png");
	    background-size: 357px;
	}

	.t-pattern-8 .bg-level-2{
	    background-image: url("../images/pattern_owl@2x.png");
	    background-size: 356px;
	}

	.t-pattern-9 .bg-level-2{
	    background-image: url("../images/pattern_present@2x.png");
	    background-size: 351px;
	}

}


/*
== TOP PANEL - Begin ==============================================================================
*/

.top-panel {
	border-bottom-width: 6px;
	border-bottom-style: solid;
	color: #fff;
	position: relative;
	z-index: 5;
}

.top-panel .l-page-width{
	padding-top: 15px;
	display: none;
	opacity: 0;
}

#kids_header {
	margin: 0 auto;
	padding-bottom: 0;
	position: relative;
	z-index: 4;
}

.secondary-page #kids_header {padding-bottom: 7px;}

#kids_header #kids_logo_block {
	margin: -1px 0 10px;
	position: relative;
	z-index: 1;
}

#kids_header #kids_logo_block img{
	max-width: 100%;
}

#kids_header .l-page-width {
    position: relative;
}

#kids_header a {
    color:inherit;
}

#kids_header a:hover {
	text-decoration:none;
}


@media screen and (max-width: 666px){
	#kids_header #kids_logo_block,
	#kids_main_nav{
		float: none !important;
		text-align: center;
	}
	#kids_main_nav .menu-button{
		float: none;
	}
	#kids_main_nav>ul{
		text-align: left;
	}
	#kids_main_nav > ul{
		position: relative !important;
	}
}


/* - Menu -> End - */

#kids_header .kids_social {
    float: right;
    margin-bottom: 25px;
    width: 100%;
    position: relative;
	z-index: 1001;
}


#kids_header #search-form {
	padding: 5px;
}

#kids_header #search-form input[type="submit"] {display: none;}

	#kids_header #search-form input[type="text"] {
		padding: 0 10px;
		width: 115px;
		height: 25px;
		line-height: 25px;
		border: none;
		outline: none;
		margin: 0;
		border-radius: 0;
	}

#kids_header .kids_social li.search+li{
	opacity: 0;
	display: none !important;
	margin-right: -1px;
	-webkit-border-radius: 0 0 0 3px;
	   -moz-border-radius: 0 0 0 3px;
			border-radius: 0 0 0 3px;
}
#kids_header .kids_social li.search+li.hide{
	display: block !important;
}
#kids_header .kids_social li.search{
	-webkit-border-radius: 0 0 3px 3px;
	   -moz-border-radius: 0 0 3px 3px;
			border-radius: 0 0 3px 3px;
}
#kids_header .kids_social li.search.hide{
	-webkit-border-radius: 0 0 3px 0;
	   -moz-border-radius: 0 0 3px 0;
			border-radius: 0 0 3px 0;
}

#kids_header .kids_social li {
	position: relative;
	float: right;
	margin: 0 0 0 1px;
	padding: 0;
	-webkit-border-radius: 0 0 3px 3px;
	   -moz-border-radius: 0 0 3px 3px;
			border-radius: 0 0 3px 3px;
}
#kids_header .kids_social li:before{
	display: none;
}

#kids_header .kids_social a {
	position: relative;
	z-index: 2;
	display: block;
	width: 36px;
	height: 35px;
	background-repeat: no-repeat;
	text-align:center;
	color: #fff;
	-webkit-border-radius: 0 0 3px 3px;
	   -moz-border-radius: 0 0 3px 3px;
			border-radius: 0 0 3px 3px;
	-webkit-font-smoothing: antialiased;
	-webkit-transition: all .25s linear;
	   -moz-transition: all .25s linear;
		-ms-transition: all .25s linear;
		 -o-transition: all .25s linear;
			transition: all .25s linear;
}
#kids_header .kids_social li.search a{
	font-size: 22px;
	line-height: 1;
}
#kids_header .kids_social li.search a:before{
	content: "\f002";
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 33px;
}


#kids_header .kids_social a i.fa{
	background-image:none;
	text-indent:none;
	font-size: 22px;
	line-height: 35px;
}

#kids_header .kids_social>li>span {
	position: absolute;
	display: block;
	top: 0;
	height: 0;
	left: 0;
	opacity: 0;
	right: 0;
	z-index: 1;
	-webkit-border-radius: 0 0 3px 3px;
	   -moz-border-radius: 0 0 3px 3px;
			border-radius: 0 0 3px 3px;
}

#kids_header .kids_social .openbtn a{
	position: relative;
	z-index: 1;
	overflow: hidden;
}
#kids_header .kids_social .openbtn a:before,
#kids_header .kids_social .openbtn a:after{
	content: "";
	position: absolute;
	top: 9px;
	bottom: 9px;
	right: 9px;
	left: 9px;
	border-top: 2px solid #fff;
	border-bottom: 2px solid #fff;
	padding-bottom: 9px;
	box-sizing:border-box;
	-webkit-transition: all .25s linear;
		   -moz-transition: all .25s linear;
			-ms-transition: all .25s linear;
			 -o-transition: all .25s linear;
				transition: all .25s linear;
}
#kids_header .kids_social .openbtn a:after{
	right: calc(50% - 1px);
	border: 0;
	border-right: 2px solid #fff;
}

#kids_header .kids_social .openbtn a:before,
#kids_header .kids_social .openbtn a:after{
	-webkit-transform:translateY(0);
	-moz-transform:translateY(0);
	-ms-transform:translateY(0);
	transform:translateY(0);
}

#kids_header .kids_social .openbtn a:before{
	-webkit-transform: translateY(8px);
	-moz-transform: translateY(8px);
	-ms-transform: translateY(8px);
	transform: translateY(8px);	bottom: -9px;
}

#kids_header .kids_social .openbtn a.hide:before{
	-webkit-transform: translateY(-26px);
	-moz-transform: translateY(-26px);
	-ms-transform: translateY(-26px);
	transform: translateY(-26px);
}
#kids_header .kids_social .openbtn a.hide:after{
	-webkit-transform: translateY(-26px);
	-moz-transform: translateY(-26px);
	-ms-transform: translateY(-26px);
	transform: translateY(-26px);
}

.kids_social li.lang_bar{
	background: transparent !important ;
}

.kids_social .lang_bar>div ul{
	margin: 0;
}
.kids_social .lang_bar>div li{
	margin: 0 !important;
	float: none !important;
	background: transparent !important;
}
.kids_social .lang_bar>div>ul a,
#kids_header .kids_social .lang_bar>div>ul a{
	width: auto !important;
	font-size: 0.4px;
	color: transparent;
	height: auto;
	padding: 0 8px;
	text-align: left;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	position: relative;
	z-index: 1;
}
.kids_social .lang_bar>div>ul a>span{
	color: #fff;
	font-size: 14px;
	line-height: 1;
	padding: 2px 0;
	border-bottom: 1px solid transparent;
	background-color: transparent !important;
	-webkit-transition: all .25s linear;
	-moz-transition: all .25s linear;
	-ms-transition: all .25s linear;
	-o-transition: all .25s linear;
	transition: all .25s linear;
}
.kids_social .lang_bar>div:not(.lang_sel_list_horizontal)>ul a:hover>span{
	border-bottom: 1px solid #fff;
}
.kids_social .lang_bar>div>ul a>img,
.kids_social .lang_bar>div>ul a>span{
	display: inline;
	vertical-align: middle;
}
.kids_social .lang_bar>div a.lang_sel_sel>span{
	margin-right: 20px;
}
.kids_social .lang_bar>div>ul a>img{
	padding-right: 3px;
	padding-left: 3px;
	padding-bottom: 11px;
	padding-top: 12px
}
.footer .kids_social .lang_bar>div>ul a>img {
	padding: 7px 3px;
}
.kids_social .lang_bar>div>ul a:before,
.kids_social .lang_bar>div>ul a:after{
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
}
.kids_social .lang_bar>div.lang_sel_list_horizontal>ul a:before,
.kids_social .lang_bar>div.lang_sel_list_horizontal>ul a:after{
	-webkit-border-radius: 0 0 3px 3px;
	-moz-border-radius: 0 0 3px 3px;
	border-radius: 0 0 3px 3px;
}
.kids_social .lang_bar>div>ul a:after{
	-webkit-transition: all .25s linear;
	-moz-transition: all .25s linear;
	-ms-transition: all .25s linear;
	-o-transition: all .25s linear;
	transition: all .25s linear;
	opacity: 0;
}
#kids_header .kids_social .lang_bar>div:not(.lang_sel_list_horizontal):not(.lang_sel_list_vertical)>ul>li>a:before,
#kids_header .kids_social .lang_bar>div:not(.lang_sel_list_horizontal):not(.lang_sel_list_vertical)>ul>li>a:after,
.kids_social .lang_bar>div.lang_sel_list_vertical>ul>li:last-child>a:after,
.kids_social .lang_bar>div.lang_sel_list_vertical>ul>li:last-child>a:before{
	-webkit-border-radius: 0 0 3px 3px;
	-moz-border-radius: 0 0 3px 3px;
	border-radius: 0 0 3px 3px;
}
.kids_social .lang_bar>div>ul>li>a:after{
	-webkit-transform:translateY(-100%);
	-moz-transform:translateY(-100%);
	-ms-transform:translateY(-100%);
	transform:translateY(-100%);
	opacity: 1;
}
.kids_social .lang_bar>div.lang_sel_list_vertical>ul>li>a:after{
	opacity: 0;
}
.kids_social .lang_bar>div>ul>li:hover>a:after,
.kids_social .lang_bar>div.lang_sel_list_vertical>ul>li>a:after{
	-webkit-transform:translateY(0);
	-moz-transform:translateY(0);
	-ms-transform:translateY(0);
	transform:translateY(0);
}
.kids_social .lang_bar>div>ul a:hover:after{
	opacity: 1;
}
.kids_social .lang_bar>div>ul>li{
	position: relative;
	z-index: 1;
}
.kids_social .lang_bar>div.lang_sel_list_horizontal>ul>li{
	float: left !important;
	margin-left: 1px !important;
}
.kids_social .lang_bar>div.lang_sel_list_horizontal>ul>li:first-child{
	margin-left: 0px !important;
}
.kids_social .lang_bar>div.lang_sel_list_horizontal a.lang_sel_sel>div{
	margin-right: 0;
}
.kids_social .lang_bar>div>ul ul{
	position: absolute;
	top: 35px;
	left: -1px;
	right: -1px;
	border-left: 1px solid;
	border-right: 1px solid;
	border-bottom: 1px solid;
	opacity: 0;
	-webkit-border-radius: 0 0 3px 3px;
	-moz-border-radius: 0 0 3px 3px;
	border-radius: 0 0 3px 3px;
	/**/
	-webkit-transition: opacity .25s linear;
	-moz-transition: opacity .25s linear;
	-ms-transition: opacity .25s linear;
	-o-transition: opacity .25s linear;
	transition: opacity .25s linear;
	/**/
	-webkit-transition-delay: .25s;
	-moz-transition-delay: .25s;
	-ms-transition-delay: .25s;
    transition-delay: .25s;
	/**/
	-webkit-transform:translateX(-10000px);
	-moz-transform:translateX(-10000px);
	-ms-transform:translateX(-10000px);
	transform:translateX(-10000px);
}
.kids_social .lang_bar>div>ul>li:hover ul{
	opacity: 1;
	-webkit-transform:translateX(0);
	-moz-transform:translateX(0);
	-ms-transform:translateX(0);
	transform:translateX(0);
}
.kids_social .lang_bar>div>ul ul li{
	border-radius: 0;
}
.kids_social .lang_bar>div>ul ul li:first-child>a{
	padding-top: 5px;
	margin-top: -5px;
}
.kids_social .lang_bar>div>ul ul li:last-child a{
	-webkit-border-radius: 0 0 3px 3px;
	-moz-border-radius: 0 0 3px 3px;
	border-radius: 0 0 3px 3px;
}

#lang_sel_footer{
	display: none;
}


/*
== MAIN MENU - Begin ==============================================================================
*/
/*
== MAIN MENU - Begin ==============================================================================
*/
#kids_logo_block.logo-position-left,
#kids_main_nav.menu-position-left {
	float: left;
}
#kids_main_nav.menu-position-right,
#kids_logo_block.logo-position-right {
	float: right;
	text-align: right;
}

#kids_main_nav {
	position: relative;
	z-index: 1000;
}

#kids_main_nav > ul {
    position: relative;
    z-index: 995;
    text-align: left;
}
#kids_main_nav  ul  li{
	padding: 0;
}
#kids_main_nav  ul  li:before{
	display: none;
}

#kids_main_nav > ul > li {
	position: relative;
	float: left;
	padding-bottom: 5px;
	vertical-align: middle;
}

#kids_main_nav > ul > li > a {
	display: block;
	padding: 1px 10px;
	text-decoration: none;
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-ms-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}

/* For screens smaller than 768px (i.e., phones) */
@media (max-width: 1200px) {
  #kids_main_nav > ul > li {
    float: left;
    display: inline-block;
    padding: 0;
    margin: 0px;
  }
  #kids_main_nav > ul > li > a {
    padding: 1px 2px;
    font-size: 17px;
    margin: 4px;
  }
   #kids_main_nav > ul > li > Link {
      padding: 1px 1px;
      font-size: 17px;
      margin: 2px;
    }
}

/* For screens smaller than 768px (i.e., phones) */
@media (max-width: 768px) {
 #kids_main_nav > ul {
     position: relative;
     z-index: 995;
     text-align: right;
     float: right;
 }
  #kids_main_nav > ul > li {
    float: left;
    display: inline-block;
    padding: 0;
    margin: 0px;
  }
  #kids_main_nav > ul > li > a {
    padding: 1px 1px;
    font-size: 11.5px;
    margin: 1px;
  }
   #kids_main_nav > ul > li > Link {
      padding: 1px 1px;
      font-size: 11px;
      margin: 1px;
    }
}


#kids_main_nav.merged > ul > li:first-child > a:before,
#kids_main_nav.merged > ul > li:first-child > a:after{
	-webkit-border-top-right-radius: 0;
	-webkit-border-bottom-right-radius: 0;
	-moz-border-radius-topright: 0;
	-moz-border-radius-bottomright: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
#kids_main_nav.merged > ul > li:last-child > a:before,
#kids_main_nav.merged > ul > li:last-child > a:after{
	-webkit-border-top-left-radius: 0;
	-webkit-border-bottom-left-radius: 0;
	-moz-border-radius-topleft: 0;
	-moz-border-radius-bottomleft: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

#kids_main_nav.merged > ul > li:not(:first-child):not(:last-child) > a:after{
	-webkit-border-radius: 0;
	   -moz-border-radius: 0;
			border-radius: 0;
}

#kids_main_nav.merged > ul > li:not(:first-child):not(:last-child) > a:before {
	-webkit-border-radius: 0;
	   -moz-border-radius: 0;
			border-radius: 0;
}
#kids_main_nav > ul > li > a {
	position: relative;
	z-index: 1;
}
#kids_main_nav > ul > li > a:after{
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 4px;
	background: rgba(0,0,0,0.12);

	-webkit-border-bottom-right-radius: 4px;
	-webkit-border-bottom-left-radius: 4px;
	-moz-border-bottom-right-radius: 4px;
	-moz-border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
#kids_main_nav > ul > li > a:before{
	content: "";
	position: absolute;
	bottom: 0;
	left: 1px;
	right: 0;
	top: 0;
	z-index: -1;

	-webkit-border-radius: 4px;
	   -moz-border-radius: 4px;
			border-radius: 4px;

}
#kids_main_nav.merged > ul > li.current-menu-item > a:before,
#kids_main_nav.merged > ul > li.current-menu-item + li >a:before,
#kids_main_nav.merged > ul > li.current-menu-ancestor > a:before,
#kids_main_nav.merged > ul > li.current-menu-ancestor + li >a:before,
#kids_main_nav.merged > ul > li:hover > a:before,
#kids_main_nav.merged > ul > li:hover + li > a:before{
	left: 0;
}
#kids_main_nav.merged > ul > li:hover + li.current-menu-item > a:before,
#kids_main_nav.merged > ul > li.current-menu-item + li:hover > a:before,
#kids_main_nav.merged > ul > li:hover + li.current-menu-ancestor > a:before,
#kids_main_nav.merged > ul > li.current-menu-ancestor + li:hover > a:before{
	left: 1px!important;
}

/* Sub Menu */

#kids_main_nav ul ul {
    position: absolute;
    display: none;
	z-index: 1500;
	width: 180px;
	-webkit-border-radius: 4px;
	   -moz-border-radius: 4px;
			border-radius: 4px;
}
#kids_main_nav>ul>li>ul{
	margin-top: 3px;
}
#kids_main_nav ul ul li {
	position: relative;
	text-decoration: none;
	background: rgba(0,0,0,0.15);
	-webkit-transition: all .3s linear;
	   -moz-transition: all .3s linear;
	    -ms-transition: all .3s linear;
		 -o-transition: all .3s linear;
			transition: all .3s linear;
}
#kids_main_nav ul ul li:first-child{
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}
#kids_main_nav ul ul li:last-child{
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}
#kids_main_nav > ul > li ul li.current-menu-item,
#kids_main_nav > ul > li ul li.current-menu-parent{
	margin: -1px 0 0;
	padding-bottom: 1px;
	z-index: 2;
}
#kids_main_nav > ul > li ul li.current-menu-item:first-child,
#kids_main_nav > ul > li ul li.current-menu-parent:first-child{
	margin: 0;
}
#kids_main_nav > ul > li ul li.current-menu-item>a,
#kids_main_nav > ul > li ul li.current-menu-parent>a{
	border-top: 0;
}
#kids_main_nav ul ul li.menu-item-has-children>a:before{
	content: "\f054";
	padding-right: 5px;
	font-size: 0.7em;
	color: inherit;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
#kids_main_nav ul ul li.menu-item-has-children:after{
	content: "";
	position: absolute;
	right: -2px;
	top: 0;
	width: 2px;
	height: 100%;
}
#kids_main_nav ul ul:after{
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 4px;
	background: rgba(0,0,0,0.12);
	z-index: 3;
}
#kids_main_nav .sub-menu a{
	font-size: 0.84em;
}

#kids_main_nav ul ul a {
	display: block;
	padding: 8px 0px;
	margin: 0 20px;
	border-top: 1px solid rgba(255,255,255,0.3);
	-webkit-transition: all 250ms linear 0s;
	-moz-transition: all 250ms linear 0s;
	-ms-transition: all 250ms linear 0s;
	-o-transition: all 250ms linear 0s;
	transition: all 250ms linear 0s;
}
#kids_main_nav ul ul li:first-child>a {border-top: none;}

#kids_main_nav ul ul li:hover>a,
#kids_main_nav ul ul li:hover + li>a,
#kids_main_nav > ul > li ul li.current-menu-item + li > a ,
#kids_main_nav > ul > li ul li.current-menu-parent+ li > a{
	border-color: transparent!important;
}

#kids_main_nav ul ul li:first-child > a {
	-webkit-border-radius: 7px 7px 0 0;
	   -moz-border-radius: 7px 7px 0 0;
			border-radius: 7px 7px 0 0;
}

#kids_main_nav ul ul li:last-child > a {
	-webkit-border-radius: 0 0 7px 7px;
	   -moz-border-radius: 0 0 7px 7px;
			border-radius: 0 0 7px 7px;
}

#kids_main_nav ul ul ul {
	left: 1px !important;
	top: 0;
	margin-top: 0;
}
.ie8 #kids_main_nav ul ul ul{
	left: 100% !important;
}
#kids_main_nav ul ul{
	left:0 !important;
	text-align:left;
	margin-left: 0;
}
#kids_main_nav ul ul ul{
	left: 100% !important;
	margin-left: 1px;
	top: 0;
}
.ie8 #kids_main_nav ul ul{
	top:100%;
}
.ie8 #kids_main_nav ul ul ul{
	top:0;
}
#kids_main_nav ul li {
	position:relative;
}

/*
== MAIN MENU - END ==============================================================================
*/

/*
== SLIDER - Begin ==============================================================================
*/

.kids_slider_bg {
	position: relative;
	z-index: 3;
	margin-bottom: 20px;
}
body.home .kids_slider_bg {
	margin-bottom: -58px;
}
body.home .kids_slider_bg.none{
	display: none;
}
.kids_top_content_middle{
	position: relative;
}
body.home .kids_top_content_middle.homepage{
	padding-top: 60px;
	padding-bottom: 25px;
}
body.home .kids_top_content_middle.homepage .bottom-border{
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	border-bottom: 15px solid;
}

.kids_slider_wrapper {
	position: relative;
    padding: 10px;
    background: #fff;
    border-radius: 8px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    height: 100%;
    background-size: contain;
}
.kids_slider_wrapper:after{
	content: "";
	position: absolute;
	bottom: -5px;
	right: 0;
	left: 0;
	height: 5px;
	border: 9px solid rgba(0,0,0,0.08);
	border-radius: 8px;
	z-index: -1;
}

.kids_slider_inner_wrapper {
	overflow:hidden;
}
.kids_slider_inner_wrapper>object{
	width:916px;
	height:420px;
}

.kids_slider_inner_wrapper > * {
	display: block;
	width: 100%;
}

@media screen and (max-width: 767px){
	.kids_slider_inner_wrapper > iframe{
		height: 200px;
	}
}

/* -- Nivo slider --> Begin */

.nivo-directionNav {
    position:absolute;
    bottom:5px;
    left:0;
    width:100%;
}

.camera_prev,.flex-prev {
    width:40px;
    height:40px;
    display:block;
    z-index:300;
    opacity:0.3 !important;
	-webkit-transition:all 0.5s ease;
	-moz-transition:all 0.5s ease;
	transition:all 0.5s ease;
}

.camera_next,.flex-next {
    width:40px;
    height:40px;
    display:block;
    z-index:300;
    opacity:0.3 !important;
	-webkit-transition:all 0.5s ease;
	-moz-transition:all 0.5s ease;
	transition:all 0.5s ease;
}
.camera_wrap:hover .camera_prev,.camera_wrap:hover .camera_next,.flexslider:hover .flex-prev,.flexslider:hover .flex-next{
	opacity:1 !important;
}

.nivo-controlNavWrapper {
    position: absolute;
    width:100%;
    text-align: center;
    bottom:0px;
    left:0;
    z-index:200;
    height:48px;
    overflow: hidden;
}

.camerapag_left {
    display:inline-block;
    margin:0 auto;
    padding-left:16px;
    background-position:left bottom;
    background-repeat: no-repeat;
    opacity:0.3;
	-webkit-transition:all 0.5s ease;
	-moz-transition:all 0.5s ease;
	transition:all 0.5s ease;
}
.camera_wrap:hover .camerapag_left,.flexslider:hover .camerapag_left{
	opacity:1;
}

.camerapag_right {
    background-position:right bottom;
    background-repeat: no-repeat;
    padding-right:16px;
}

.camera_pag {
    background-repeat: repeat-x;
    background-position: left bottom;
    height:11px;
    padding:18px 3px 18px;
    overflow: hidden;
}

.camera_pag_ul li{
	float:left;
	margin-left:4px !important;
}

.camera_pag_ul li {
    text-indent: -9999px;
    width:11px;
    height:11px;
    display:block;
    background-repeat: no-repeat;
    background-position: 0 0;

}

.camera_pag_ul li:first-child {
    margin-left:0 !important;
}

.camera_pagination_wrapper {
	text-align: center;
	position: absolute;
	width: 100%;
	bottom: 2px;
}

.camera_pagination{
	display: inline-block;
}

/* -- Nivo slider --> End */

/* -- Anything slider --> Begin */

div.anythingSlider .start-stop {
    display:none!important;
}

div.anythingSlider .arrow.back a {
    background-position: 0 0;
    background-repeat: no-repeat;
}

div.anythingSlider .arrow.forward a {
    background-position: 0 0;
    background-repeat: no-repeat;
}

/* -- Anything slider --> End */

/* -- Lof slider --> Begin */

.lof-main-outer {
    width: 916px;
    height: 400px;
}

.lof-next {
    top: 50%;
    position: absolute;
    right:0;
    margin-top:-20px;
    height: 40px;
    width: 40px;
    opacity: 0.3;
    background-position: 0 0;
    background-repeat: no-repeat;
    cursor:pointer;
}

.lof-previous {
    top: 50%;
    position: absolute;
    left:0;
    margin-top:-20px;
    height: 40px;
    width: 40px;
    opacity: 0.3;
    background-position: 0 0;
    background-repeat: no-repeat;
    cursor:pointer;
}

/* -- Lof slider --> End */

/*
== SLIDER - End ==============================================================================
*/

/*
== VIDEO - Begin ==============================================================================
*/

.video-js-box .vjs-controls {
    position: absolute; margin: 0; opacity: 0.85; color: #fff;
    display: none; /* Start hidden */
    left: 0; right: 0; /* 100% width of video-js-box */
    width: 100%;
    bottom: 0px; /* Distance from the bottom of the box/video. Keep 0. Use height to add more bottom margin. */
    height: 35px; /* Including any margin you want above or below control items */
    padding: 0; /* Controls are absolutely position, so no padding necessary */
}

.video-js-box .vjs-controls > div { /* Direct div children of control bar */
    position: absolute; /* Use top, bottom, left, and right to specifically position the control. */
    text-align: center; margin: 0; padding: 0;
    height: 25px; /* Default height of individual controls */
    top: 5px; /* Top margin to put space between video and controls when controls are below */

    /* CSS Background Gradients
       Using to give the aqua-ish look. */
    /* Default */ background-color: #0B151A;
    /* Webkit  */ background: #1F3744 -webkit-gradient(linear, left top, left bottom, from(#0B151A), to(#1F3744)) left 12px;
    /* Firefox */ background: #1F3744 -moz-linear-gradient(top,  #0B151A,  #1F3744) left 12px;

    /* CSS Curved Corners */
    border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px;

    /* CSS Shadows */
    box-shadow: 1px 1px 2px #000; -webkit-box-shadow: 1px 1px 2px #000; -moz-box-shadow: 1px 1px 2px #000;
}

/* Placement of Control Items
   - Left side of pogress bar, use left & width
   - Rigth side of progress bar, use right & width
   - Expand with the video (like progress bar) use left & right */
.vjs-controls > div.vjs-play-control       { left: 5px;   width: 25px;  }
.vjs-controls > div.vjs-progress-control   { left: 35px;  right: 165px; } /* Using left & right so it expands with the width of the video */
.vjs-controls > div.vjs-time-control       { width: 75px; right: 90px;  } /* Time control and progress bar are combined to look like one */
.vjs-controls > div.vjs-volume-control     { width: 50px; right: 35px;  }
.vjs-controls > div.vjs-fullscreen-control { width: 25px; right: 5px;   }

/* Removing curved corners on progress control and time control to join them. */
.vjs-controls > div.vjs-progress-control {
    border-top-right-radius: 0; -webkit-border-top-right-radius: 0; -moz-border-radius-topright: 0;
    border-bottom-right-radius: 0; -webkit-border-bottom-right-radius: 0; -moz-border-radius-bottomright: 0;
}
.vjs-controls > div.vjs-time-control {
    border-top-left-radius: 0; -webkit-border-top-left-radius: 0; -moz-border-radius-topleft: 0;
    border-bottom-left-radius: 0; -webkit-border-bottom-left-radius: 0; -moz-border-radius-bottomleft: 0;
}

/* Play/Pause
-------------------------------------------------------------------------------- */
.vjs-play-control { cursor: pointer !important; }
/* Play Icon */
.vjs-play-control span { display: block; font-size: 0; line-height: 0; }
.vjs-paused .vjs-play-control span {
    width: 0; height: 0; margin: 8px 0 0 8px;
    /* Drawing the play triangle with borders - http://www.infimum.dk/HTML/slantinfo.html */
    border-left: 10px solid #fff; /* Width & Color of play icon */
    /* Height of play icon is total top & bottom border widths. Color is transparent. */
    border-top: 5px solid rgba(0,0,0,0); border-bottom: 5px solid rgba(0,0,0,0);
}
.vjs-playing .vjs-play-control span {
    width: 3px; height: 10px; margin: 8px auto 0;
    /* Drawing the pause bars with borders */
    border-top: 0px; border-left: 3px solid #fff; border-bottom: 0px; border-right: 3px solid #fff;
}

/* Progress
-------------------------------------------------------------------------------- */
.vjs-progress-holder { /* Box containing play and load progresses */
    position: relative; padding: 0; overflow:hidden; cursor: pointer !important;
    height: 9px; border: 1px solid #777;
    margin: 7px 1px 0 5px; /* Placement within the progress control item */
    border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px;
}
.vjs-progress-holder div { /* Progress Bars */
    position: absolute; display: block; width: 0; height: 9px; margin: 0; padding: 0;
    border-radius: 5px; -webkit-border-radius: 5px; -moz-border-radius: 5px;
}
.vjs-play-progress {
    /* CSS Gradient */
    /* Default */ background: #fff;
    /* Webkit  */ background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#777));
    /* Firefox */ background: -moz-linear-gradient(top,  #fff,  #777);
}
.vjs-load-progress {
    opacity: 0.8;
    /* CSS Gradient */
    /* Default */ background-color: #555;
    /* Webkit  */ background: -webkit-gradient(linear, left top, left bottom, from(#555), to(#aaa));
    /* Firefox */ background: -moz-linear-gradient(top,  #555,  #aaa);
}

/* Time Display
-------------------------------------------------------------------------------- */
.vjs-controls .vjs-time-control { font-size: 10px; line-height: 1; font-weight: normal; }
.vjs-controls .vjs-time-control span { line-height: 25px; /* Centering vertically */ }

/* Volume
-------------------------------------------------------------------------------- */
.vjs-volume-control { cursor: pointer !important; }
.vjs-volume-control div { display: block; margin: 0 5px 0 5px; padding: 4px 0 0 0; }
/* Drawing the volume icon using 6 span elements */
.vjs-volume-control div span { /* Individual volume bars */
    float: left; padding: 0;
    margin: 0 2px 0 0; /* Space between */
    width: 5px; height: 0px; /* Total height is height + bottom border */
    border-bottom: 18px solid #555; /* Default (off) color and height of visible portion */
}
.vjs-volume-control div span.vjs-volume-level-on { border-color: #fff; /* Volume on bar color */ }
/* Creating differnt bar heights through height (transparent) and bottom border (visible). */
.vjs-volume-control div span:nth-child(1) { border-bottom-width: 2px; height: 16px; }
.vjs-volume-control div span:nth-child(2) { border-bottom-width: 4px; height: 14px; }
.vjs-volume-control div span:nth-child(3) { border-bottom-width: 7px; height: 11px; }
.vjs-volume-control div span:nth-child(4) { border-bottom-width: 10px; height: 8px; }
.vjs-volume-control div span:nth-child(5) { border-bottom-width: 14px; height: 4px; }
.vjs-volume-control div span:nth-child(6) { margin-right: 0; }

/* Fullscreen
-------------------------------------------------------------------------------- */
.vjs-fullscreen-control { cursor: pointer !important; }
.vjs-fullscreen-control div {
    padding: 0; text-align: left; vertical-align: top; cursor: pointer !important;
    margin: 5px 0 0 5px; /* Placement within the fullscreen control item */
    width: 20px; height: 20px;
}
/* Drawing the fullscreen icon using 4 span elements */
.vjs-fullscreen-control div span { float: left; margin: 0; padding: 0; font-size: 0; line-height: 0; width: 0; text-align: left; vertical-align: top; }
.vjs-fullscreen-control div span:nth-child(1) { /* Top-left triangle */
    margin-right: 3px; /* Space between top-left and top-right */
    margin-bottom: 3px; /* Space between top-left and bottom-left */
    border-top: 6px solid #fff; /* Height and color */
    border-right: 6px solid rgba(0,0,0,0);  /* Width */
}
.vjs-fullscreen-control div span:nth-child(2) { border-top: 6px solid #fff; border-left: 6px solid rgba(0,0,0,0); }
.vjs-fullscreen-control div span:nth-child(3) { clear: both; margin: 0 3px 0 0; border-bottom: 6px solid #fff; border-right: 6px solid rgba(0,0,0,0); }
.vjs-fullscreen-control div span:nth-child(4) { border-bottom: 6px solid #fff; border-left: 6px solid rgba(0,0,0,0); }
/* Icon when video is in fullscreen mode */
.vjs-fullscreen .vjs-fullscreen-control div span:nth-child(1) { border: none; border-bottom: 6px solid #fff; border-left: 6px solid rgba(0,0,0,0); }
.vjs-fullscreen .vjs-fullscreen-control div span:nth-child(2) { border: none; border-bottom: 6px solid #fff; border-right: 6px solid rgba(0,0,0,0); }
.vjs-fullscreen .vjs-fullscreen-control div span:nth-child(3) { border: none; border-top: 6px solid #fff; border-left: 6px solid rgba(0,0,0,0); }
.vjs-fullscreen .vjs-fullscreen-control div span:nth-child(4) { border: none; border-top: 6px solid #fff; border-right: 6px solid rgba(0,0,0,0); }

/* Download Links - Used for browsers that don't support any video.
---------------------------------------------------------*/
.vjs-no-video { font-size: small; line-height: 1.5; }

/* Big Play Button (at start)
---------------------------------------------------------*/
div.vjs-big-play-button {
    display: none; /* Start hidden */ z-index: 2;
    position: absolute; top: 50%; left: 50%; width: 80px; height: 80px; margin: -43px 0 0 -43px; text-align: center; vertical-align: center; cursor: pointer !important;
    border: 3px solid #fff; opacity: 0.9;
    border-radius: 20px; -webkit-border-radius: 20px; -moz-border-radius: 20px;

    /* CSS Background Gradients */
    /* Default */ background-color: #0B151A;
    /* Webkit  */ background: #1F3744 -webkit-gradient(linear, left top, left bottom, from(#0B151A), to(#1F3744)) left 40px;
    /* Firefox */ background: #1F3744 -moz-linear-gradient(top,  #0B151A,  #1F3744) left 40px;

    /* CSS Shadows */
    box-shadow: 4px 4px 8px #000; -webkit-box-shadow: 4px 4px 8px #000; -moz-box-shadow: 4px 4px 8px #000;
}
div.vjs-big-play-button:hover {
    box-shadow: 0px 0px 80px #fff; -webkit-box-shadow: 0px 0px 80px #fff; -moz-box-shadow: 0px 0px 80px #fff;
}

div.vjs-big-play-button span {
    display: block; font-size: 0; line-height: 0;
    width: 0; height: 0; margin: 20px 0 0 23px;
    /* Drawing the play triangle with borders - http://www.infimum.dk/HTML/slantinfo.html */
    border-left: 40px solid #fff; /* Width & Color of play icon */
    /* Height of play icon is total top & bottom border widths. Color is transparent. */
    border-top: 20px solid rgba(0,0,0,0); border-bottom: 20px solid rgba(0,0,0,0);
}

/* Spinner Styles
---------------------------------------------------------*/
/* CSS Spinners by Kilian Valkhof - http://kilianvalkhof.com/2010/css-xhtml/css3-loading-spinners-without-images/ */
.vjs-spinner { display: none; position: absolute; top: 50%; left: 50%; width: 100px; height: 100px; z-index: 1; margin: -50px 0 0 -50px;
               /* Scaling makes the circles look smoother. */
               transform: scale(0.5); -webkit-transform:scale(0.5); -moz-transform:scale(0.5);
}
/* Spinner circles */
.vjs-spinner div { position:absolute; left: 40px; top: 40px; width: 20px; height: 20px; background: #fff;
                   border-radius: 20px; -webkit-border-radius: 20px; -moz-border-radius: 20px;
                   border: 1px solid #ccc; /* Added border so can be visible on white backgrounds */
}
/* Each circle */
.vjs-spinner div:nth-child(1) { opacity: 0.12; transform: rotate(000deg) translate(0, -40px) scale(0.1); -webkit-transform: rotate(000deg) translate(0, -40px) scale(0.1); -moz-transform: rotate(000deg) translate(0, -40px) scale(0.1); }
.vjs-spinner div:nth-child(2) { opacity: 0.25; transform: rotate(045deg) translate(0, -40px) scale(0.2); -webkit-transform: rotate(045deg) translate(0, -40px) scale(0.2); -moz-transform: rotate(045deg) translate(0, -40px) scale(0.2); }
.vjs-spinner div:nth-child(3) { opacity: 0.37; transform: rotate(090deg) translate(0, -40px) scale(0.4); -webkit-transform: rotate(090deg) translate(0, -40px) scale(0.4); -moz-transform: rotate(090deg) translate(0, -40px) scale(0.4); }
.vjs-spinner div:nth-child(4) { opacity: 0.50; transform: rotate(135deg) translate(0, -40px) scale(0.6); -webkit-transform: rotate(135deg) translate(0, -40px) scale(0.6); -moz-transform: rotate(135deg) translate(0, -40px) scale(0.6); }
.vjs-spinner div:nth-child(5) { opacity: 0.62; transform: rotate(180deg) translate(0, -40px) scale(0.8); -webkit-transform: rotate(180deg) translate(0, -40px) scale(0.8); -moz-transform: rotate(180deg) translate(0, -40px) scale(0.8); }
.vjs-spinner div:nth-child(6) { opacity: 0.75; transform: rotate(225deg) translate(0, -40px) scale(1.0); -webkit-transform: rotate(225deg) translate(0, -40px) scale(1.0); -moz-transform: rotate(225deg) translate(0, -40px) scale(1.0); }
.vjs-spinner div:nth-child(7) { opacity: 0.87; transform: rotate(270deg) translate(0, -40px) scale(1.1); -webkit-transform: rotate(270deg) translate(0, -40px) scale(1.1); -moz-transform: rotate(270deg) translate(0, -40px) scale(1.1); }
.vjs-spinner div:nth-child(8) { opacity: 1.00; transform: rotate(315deg) translate(0, -40px) scale(1.3); -webkit-transform: rotate(315deg) translate(0, -40px) scale(1.3); -moz-transform: rotate(315deg) translate(0, -40px) scale(1.3); }

/*
== VIDEO - End ==============================================================================
*/

/* -- Image wrapper --> Begin -- */

.kids_image_wrapper {
    position:relative;
    z-index: 1;
}

.prettyPhoto {
	position: relative;
    cursor: pointer;
    display: block;
}

.kids_curtain {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(255,255,255,0.7);
    opacity: 0;
    -webkit-transition: all .3s;
	   -moz-transition: all .3s;
		-ms-transition: all .3s;
		 -o-transition: all .3s;
			transition: all .3s;
}

.kids_curtain:before{
	content: "\f002";
	font-size: 30px;
	line-height: 50px;
	color: #fff;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	position:absolute;
    display: block;
    text-align: center;
	left: 50%;
	top: 50%;
	width: 50px;
	height: 50px;
	border-radius: 9px;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	overflow: hidden!important;

	-webkit-transition: all .3s;
	   -moz-transition: all .3s;
		-ms-transition: all .3s;
		 -o-transition: all .3s;
			transition: all .3s;



	-webkit-transform:translate(-25px,-25px) translateZ(0);
	-moz-transform:translate(-25px,-25px) translateZ(0);
	-ms-transform:translate(-25px,-25px) translateZ(0);
	transform:translate(-25px,-25px) translateZ(0);
}
.kids_mini_picture .kids_curtain:before{
	display: none;
}
.kids_mini_picture .kids_curtain{
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	border-radius: 0;
	background: rgba(255,255,255,0.7);
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-webkit-transform:translate3d(0,0,0);
	-moz-transform:translate3d(0,0,0);
	-ms-transform:translate3d(0,0,0);
	transform:translate3d(0,0,0);
}

.prettyPhoto:hover .kids_curtain,
.kids_mini_picture:hover .kids_curtain{
	opacity: 1;
	filter: alpha(opacity = 70);
}
.kids_image_wrapper {
	border: 2px solid;
	border-radius: 4px;
}
.footer .kids_image_wrapper,
.top-panel .kids_image_wrapper{
	border-color: rgba(255,255,255,0.5);
}
.footer .content-wrapper figure,
.top-panel .content-wrapper figure{
	border: 2px solid rgba(255,255,255,0.5);
	border-radius: 4px;
}
.kids_image_wrapper a img{
	display: block;
}

.kids_link{
	display:block;
	position:relative;
	cursor:pointer;
}

/* fix for smooth font on sale span */
.products .kids_curtain{
	background: rgba(255,255,255,0) !important;
    opacity: 1 !important;
}
.products .prettyPhoto:hover .kids_curtain{
	background: rgba(255,255,255,0.7) !important;
}
.products  .kids_curtain:before{
	opacity: 0;
}
.products .prettyPhoto:hover .kids_curtain:before{
	opacity: 1;
}

/* -- Image wrapper --> End -- */

/* -- Middle container --> Begin -- */

.secondary-page #kids_middle_container {
    position: relative;
}

/* -- Middle container --> End -- */

/* -- Top content --> Begin -- */

.kids-front-page .kids_top_content {
    position: relative;
    z-index: 2;
}

.secondary-page .kids_top_content {
    width:100%;
}
.kids_top_content_middle {
    border-top: 7px solid;
}

/* -- Top content --> End -- */

/* -- Bottom content --> Begin -- */
.home .entry-container{
	background:none;
}
.entry-container:after{
	content: "";
	clear: both;
	display: table;
}

.kids_bottom_content {
    position: relative;
    z-index: 1;
}
.kids_bottom_content .content_bottom_bg{
	background-position: center bottom;
	background-repeat: repeat-x;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 130px;
	z-index: 4;
}

.kids_bottom_content_container {
    padding: 20px 0 0;
}

.kids_bottom_content_container .kids_post_content {
    text-align: left;
	font-size: 1em;
}

.header_container {
    padding: 2px 0 6px;
    min-height: 46px;
}
.header_container.nocrumbs h1{
	line-height: 46px;
}
.header_container h1 {
 	margin-bottom:0;
 	font-weight: 700;
 	font-size: 1.5em;
 	line-height: 1.16;
}
#breadcrumbs li{
	display: inline;
	font-size: inherit;
	padding: 0;
	position: static;
}
#breadcrumbs li:before{
	display: none;
}
#breadcrumbs {
    font-size: 0.875em;
    margin-bottom: 0;
}

#breadcrumbs li a {
	color: inherit;
}
#breadcrumbs li a:hover{
	text-decoration: underline;
}

#breadcrumbs li:first-child {
    padding-left:0;
    background: none;
}

#breadcrumbs .delimiter {
	color: rgba(242, 18, 18, 0) !important;
	position: relative;
	line-height: 1;
	width: 0.5em;
	display: inline-block;
}
#breadcrumbs .delimiter:before{
	content: "\f105";
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	text-align: center;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.kids_bottom_content_container .section-title {
	font-weight: 700;
}

/* -- jCarousel --> Begin */

.recent_projects {overflow: hidden;}
.jcarousel-next.hide,.jcarousel-prev.hide{
	display:none !important;
}
.recent_projects ul li {
	position: relative;
	overflow: hidden;
	margin: 0 20px 0 0;
	display: inline-block;
	width: 225px;
	list-style: none;
}
.recent_projects ul li>*{
	margin-bottom: 10px;
	text-align: center;
}


	.recent_projects ul .title {
		font-size: 23px;
		font-weight: 700;
		text-align: center;
		line-height: 1;
	}


	.jcarousel-container {position: relative;}

		.jcarousel-prev,
		.jcarousel-next {
			display: inline-block;
			width: 19px;
			height: 15px;
			border-width: 1px;
			border-style: solid;
			/*background-image: url(../images/icons/carousel-nav.png);
			background-repeat: no-repeat;*/
			-webkit-border-radius: 4px;
			   -moz-border-radius: 4px;
					border-radius: 4px;
			text-indent: -9999px;
			cursor: pointer;
			float:left;
		}
		.jcarousel-next{
			margin-left:5px;
		}
		.carousel-nav{
			width:50px;
			float:left;
			margin-left:10px;
			padding-top:8px;
		}
		.carousel-nav:after{
			content:"";
			display:block;
			clear:both;
		}
		.recent_projects>h3{
			margin-bottom: 0px;
		}


/* -- jCarousel --> End */

/* -- Bottom content --> End -- */

/* -- Gallery --> Begin -- */

.content-divider {
	clear: both;
	padding: .5em 0 .8em 0;
	height: 6px;
	width: 100%;
	/*background: url(../images/gallery-divider.png) repeat-x;*/
}


/* Isotope */

.isotope-hidden.isotope-item {
	pointer-events: none;
	z-index: 1;
}

.isotope, .isotope .isotope-item {
	-webkit-transition-duration: 0.6s;
	   -moz-transition-duration: 0.6s;
		-ms-transition-duration: 0.6s;
		 -o-transition-duration: 0.6s;
			transition-duration: 0.6s;
	z-index: 2;
}

.isotope {
	-webkit-transition-property: height, width;
	   -moz-transition-property: height, width;
		-ms-transition-property: height, width;
		 -o-transition-property: height, width;
			transition-property: height, width;
}

.isotope .isotope-item {
	-webkit-transition-property: -webkit-transform, opacity;
	   -moz-transition-property:	-moz-transform, opacity;
		-ms-transition-property:		 top, left, opacity;
		 -o-transition-property:		 top, left, opacity;
			transition-property:		 transform, opacity;
}


.nav_cat {position: relative;}
.nav_cat li{
	padding: 0;
	font-size: inherit;
	position: static;
}
.nav_cat li:before{
	display: none;
}
.nav_cat.type-2,
.nav_cat.type-3 {padding-bottom: 14px;}

.gl_cat ul > li a,
.nav_cat ul > li a {
	display: block;
	position: relative;
	margin: 3px 0;
	padding: 6px 0 6px 10px;
}
#sidebar .gl_cat ul > li a.selectedLava:not(.not_active),
#sidebar .nav_cat ul > li a.selectedLava:not(.not_active),
#sidebar-left  .gl_cat ul > li a.selectedLava:not(.not_active),
#sidebar-left  .nav_cat ul > li a.selectedLava:not(.not_active),
#sidebar-right  .gl_cat ul > li a.selectedLava:not(.not_active),
#sidebar-right  .nav_cat ul > li a.selectedLava:not(.not_active){
	color: #fff!important;
}
body .entry-container #sidebar .gl_cat ul > li a:hover,
body .entry-container #sidebar .nav_cat ul > li a:hover,
body .entry-container #sidebar-left  .gl_cat ul > li a:hover,
body .entry-container #sidebar-left  .nav_cat ul > li a:hover,
body .entry-container #sidebar-right  .gl_cat ul > li a:hover,
body .entry-container #sidebar-right  .nav_cat ul > li a:hover{
	color: #fff!important;
}
.gl_cat ul > li {
	border-top: 1px solid rgba(255,255,255,0.5);
	overflow: hidden;
}
.nav_cat ul > li {
	border-bottom: 1px solid rgba(255,255,255,0.5);
	overflow: hidden;
}

.footer .gl_cat ul > li,
.footer .nav_cat ul > li,
.top-panel .gl_cat ul > li,
.top-panel .nav_cat ul > li{
	border-color: rgba(255,255,255,0.5);
}

.footer .gl_cat .backLava,
.footer .nav_cat .backLava,
.top-panel .gl_cat .backLava,
.top-panel .nav_cat .backLava{
	background: #fff;
}

.gl_cat ul > li:last-child,
.nav_cat ul > li:last-child {border-bottom: none;}

.gl_cat > ul li ul,
.nav_cat > ul li ul {
	padding-left: 15px;
}

.gl_cat ul li ul li a,
.nav_cat ul li ul li a {
	margin: 4px 0;
	padding: 8px 0 8px 10px;
	font-size: 0.917em;
}


.gl_cat .backLava,
.nav_cat .backLava {
	position: absolute;
	display: block;
	width: 100%;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border: 0;
	opacity: .4;
}

#filter {
	position: absolute;
	top: -42px;
	right: 14px;
	height: 32px;
	border: 1px solid #d3c69e;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	/*background: url(../images/bg_splitter.png) repeat-x;*/
}
#filter .backLava {
    position: absolute;
    top: 1px;
    z-index: -0;
	height: 28px;
	border: 1px solid #d3c69e;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	/*background: url(../images/bg_splitter_current.png) repeat-x;*/
}

.splitter {
	position: relative;
	z-index: 5;
}

.splitter li {
	float: left;
	margin: 1px 3px 0 2px;
	height: 28px;
	border: 1px solid transparent;
	line-height: 30px;
}

.splitter li a {
	position: relative;
	display: block;
	padding: 0 12px;
	color: #575655;
	cursor: pointer;
}
.tweets .textwidget{
	padding: 0px;
	}
.kids-footer-copyrights {
	position: relative;
	z-index: 1;
	color: rgba(255,255,255,0.5);
}
.kids-footer-copyrights>.dark-mask{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0,0,0,0.12);
	z-index: -1;
}

.kids-footer-copyrights.footer  a:not(.cws_button){
	color: rgba(255,255,255,0.5);
}
.kids-footer-copyrights  a:hover{
	color:#fff;
	text-decoration: underline;
}

.kids-footer-copyrights p {
	margin-bottom:0;
}

.kids-footer-copyrights .widget {
	margin: 0;
}

.kids-footer-copyrights .wrapper .kids_social{
	float: right;
	margin-bottom: 0;
}
.kids-footer-copyrights .kids_social li{
	font-size: 1.2em;
	float: left;
	padding-left: 1em;
}
.kids-footer-copyrights .kids_social li:before{
	display: none;
}
.kids-footer-copyrights .kids_social li>span{
	display: none;
}
.kids-footer-copyrights .kids_social li i.fa{
	font-size: 1em;
}
.kids-footer-copyrights .l-page-width>.wrapper{
	overflow: visible;
}

/* footer lang bar */
.kids-footer-copyrights .kids_social .lang_bar li{
	font-size: 0em;
	float: none;
	padding-left: 0;
}
.kids-footer-copyrights .kids_social .lang_bar li:after{
	content: "";
	clear: both;
	display: table;
}
.kids-footer-copyrights .kids_social .lang_bar li a{
	display: block;
	line-height: 26px !important;
}
.kids-footer-copyrights .kids_social .lang_bar li a:before{
	background-color: rgba(0,0,0,0.12) !important;
}
.kids-footer-copyrights .kids_social .lang_bar li a:after{
	z-index: -2 !important;
	opacity: 1 !important;
}
.kids-footer-copyrights .kids_social #lang_sel>ul{
	margin-left: -8px;
}
.kids-footer-copyrights .kids_social #lang_sel>ul ul{
	top: auto;
	bottom: 26px;
	border: 0;
}
.kids-footer-copyrights .kids_social #lang_sel>ul ul li:first-child>a{
	-webkit-border-radius: 3px 3px 0 0;
	-moz-border-radius: 3px 3px 0 0;
	border-radius: 3px 3px 0 0;
	overflow: hidden;
}
.kids-footer-copyrights .kids_social .lang_bar>div>ul>li>a:after{
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}
@media screen and (max-width:767px){
	.kids-footer-copyrights .wrapper .kids_social{
		float: none;
		text-align: center;
	}
	.kids-footer-copyrights .kids_social li{
		float: none;
		display: inline-block;
		vertical-align: middle;
	}
}
/* -- Footer layout --> End -- */

/* -- Bottom container --> Begin -- */

.kids_bottom_container{
	border-top: 15px solid rgba(0,0,0,0.12);
	color: #fff;
}
.kids_bottom_container #calendar_wrap thead tr th span {
	color: #ffffff;
}
.footer a:hover,
.top-panel a:hover{
	text-decoration: underline;
}

/* -- Widget --> Begin -- */


/* -- Widget Recent Entries, Archives, Twitter --> Begin -- */

.widget_wrapper{
	display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    /**/
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
	flex-wrap: wrap;
	/**/
	margin-left: -30px;
	text-align: center;
}
.widget_wrapper .widget{
	-webkit-box-flex: 1;
    -moz-box-flex: 1;
    -webkit-flex: 1 1 256px;
    -ms-flex: 1 1 256px;
    flex: 1 1 256px;
	padding-left: 30px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
}

.widget_recent_entries ul li,
.widget_archive ul li,
.widget_pages ul li,
.widget_nav_menu ul li,
.widget_categories ul li,
.widget_recent_comments ul li,
.widget_meta ul li,
.widget.woocommerce.widget_product_categories li,
.widget.woocommerce.widget_layered_nav li{
	font-size: inherit;
	position: relative;
	padding: 0.43em 0.714em 0.43em 2.14em;
	border-top: 1px solid;
}
.footer .widget_recent_entries ul li,
.footer .widget_archive ul li,
.footer .widget_pages ul li,
.footer .widget_nav_menu ul li,
.footer .widget_categories ul li,
.footer .widget_recent_comments ul li,
.footer .widget_meta ul li,
.footer .widget.woocommerce.widget_product_categories li,
.footer .widget.woocommerce.widget_layered_nav li,
.top-panel .widget_recent_entries ul li,
.top-panel .widget_archive ul li,
.top-panel .widget_pages ul li,
.top-panel .widget_nav_menu ul li,
.top-panel .widget_categories ul li,
.top-panel .widget_recent_comments ul li,
.top-panel .widget_meta ul li,
.top-panel .widget.woocommerce.widget_product_categories li,
.top-panel .widget.woocommerce.widget_layered_nav li{
	border-color: rgba(255,255,255,0.5);
}

.widget_nav_menu ul li:before{
	display: none;
}

.widget_recent_comments ul li:first-child,
.widget_meta>ul>li:first-child,
.widget_pages>ul>li:first-child{
	border-top: 0;
}

.widget_recent_entries ul li:last-child,
.widget_pages ul li:last-child,
.widget_nav_menu ul li:last-child,
.widget_archive ul li:last-child
{
	border-bottom: none;
}

.widget_recent_entries ul li:before,
.widget_archive ul li:before,
.widget_pages ul li:before,
.widget_recent_comments ul li:before,
.widget_meta>ul>li:before,
.widget.woocommerce.widget_product_categories li:before,
.widget.woocommerce.widget_layered_nav li:before{
	content: "\f040";
	padding:0;
	width: auto;
	height: auto;
	position: absolute;
	font-size: 1.714em;
	line-height: 1;
	left: 0;
	top: 4px;
	display: block !important;
}
.widget_recent_entries ul li:before,
.widget_recent_comments ul li:before{
	content: "\f075";
}

.footer  .widget_recent_entries ul li:before,
.footer  .widget_archive ul li:before,
.footer .widget_pages ul li:before,
.footer .widget_recent_comments ul li:before,
.footer .widget_meta ul li:before,
.top-panel  .widget_recent_entries ul li:before,
.top-panel  .widget_archive ul li:before,
.top-panel .widget_pages ul li:before,
.top-panel .widget_recent_comments ul li:before,
.top-panel .widget_meta ul li:before,
.top-panel .widget.woocommerce.widget_product_categories li:before,
.top-panel .widget.woocommerce.widget_layered_nav li:before,
.footer .widget.woocommerce.widget_product_categories li:before,
.footer .widget.woocommerce.widget_layered_nav li:before,
.footer .widget_recent_comments ul li.recentcomments:before,
.top-panel .widget_recent_comments ul li.recentcomments:before{
	color: rgba(255,255,255,0.5);
	background-color: transparent;
	width: auto;
	height: auto;
	font-size: 1.714em;
	line-height: 1;
	left: 0;
}
.widget_recent_entries ul li:first-child,
.widget_archive ul li:first-child,
.widget_twitter ul li:first-child,
.widget_categories>ul>li:first-child,
.widget_nav_menu .menu>li:first-child{
	border-top: none;
}

/* -- Widget Recent Entries, Archives, Twitter --> End -- */


/* -- Widget --> End -- */

/* -- Bottom container --> End -- */

/* -- Posts container --> Begin -- */

.kids_top_content .kids_post_block .kids_post_content {
    text-align: left;
}

.kids_top_content .kids_post_block .kids_post_content p {
	margin-bottom: .5em;
}

.kids_top_content .kids_post_block {
    display: inline-block;
	margin: 0;
    text-align: center;
}
/* -- Posts container --> End -- */

/* -- Posts container --> Begin -- */
.kids_posts_container{
	overflow: hidden;
}
.kids_posts_container .widget_wrapper>div{
	padding: 10px 15px;
	border-left: 1px solid;
	margin-top: 20px;
}
.kids_posts_container .widget_wrapper{
	margin-left: -15px;
	margin-right: -15px;
}

.widget_cws_benefits .widget-icon{
	display: inline-block;
	float: left;
	margin-right: 15px;
	padding: 0;
}

aside .widget_cws_benefits .widget-icon{
	float: none;
	text-align: center;
	width: 100%;
	margin-right: 0;
	margin-bottom: 10px;
}

.widget_cws_benefits .widget-icon>i{
	border-radius: 3em;
	width: 1.83em;
	line-height: 1.84;
	text-align: center;
}
.widget_cws_benefits .widget-title{
	margin-bottom: 0;
	text-align: left;
	clear: none;
	margin-bottom: 10px;	}
.kids_posts_container .widget-title{
	font-size: 1.75em;

}
.widget_cws_benefits .cws-widget-content{
	text-align: center;
}
.widget_cws_benefits .cws-widget-content .text_part{
	text-align: left;
	margin-bottom: 10px;

}
.kids_ads_box .kids_posts_container {
    margin: 0 20px 0;
}

.kids_ads_box .kids_post_block .kids_post_content {
    text-align: left;
}

.kids_ads_box .kids_post_block .kids_post_content p {
	margin-bottom: .5em;
}

@media screen and (max-width:980px){
	.widget_cws_benefits .widget-icon img{
		width: 50px;
	}
	.widget_cws_benefits .widget-icon>i{
		font-size: 1.70455em;
		line-height: 1.85em;
	}
}



/* -- Posts container --> End -- */

/* -- Back to top button --> Begin -- */

#kids-back-top {
	position: fixed;
	bottom: 30px;
    right: 10px;
    z-index: 1000;
}

#kids-back-top a {
	display: block;
	width: 43px;
	color: #bbb;
}

#kids-back-top span {
	display: block;
	margin-bottom: 7px;
	width: 34px;
	height: 34px;
	background-repeat:no-repeat;
	background-position: center center;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border: 1px solid #fff;
	font-size: 0;
	-webkit-transition: all .25s linear;
	   -moz-transition: all .25s linear;
		-ms-transition: all .25s linear;
		 -o-transition: all .25s linear;
			transition: all .25s linear;

	position: relative;
	z-index: 1;
}

#kids-back-top span:after{
	content: "\f062";
	position: absolute;
	color: #fff;
	top: 0;
	left: 0;
	right: 0;
	line-height: 34px;
	font-size: 30px;
	display: inline-block;
	font-family: FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 23px;
	text-align: center;
}



/* -- Back to top button --> End -- */

/*
##############################################################
STYLED ELEMENTS -> End
##############################################################
*/

/* --- Styles for Buttons --> Begin -- */

.cws_button {
	position: relative;
    display: inline-block;
	padding: 0.35em 0.6em 0.45em;
    font-size: 18px;
	outline: none;
	text-align: center;
	font-weight: 700;
    text-decoration: none !important;
	font-style: normal !important;
	cursor: pointer;
	font-family: Arial;
	line-height: 1;
	color: #fff;
	-webkit-border-radius: 4px;
	   -moz-border-radius: 4px;
			border-radius: 4px;
}
.cws_button:after{
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 4px;
	background: rgba(0,0,0,0.12);
}
.cws_button:hover{
	color: #fff;
}

.cws_button>.button-icon{
	margin-left: 0.5em;
}

.cws_button.square{
	-webkit-border-radius: 0;
	   -moz-border-radius: 0;
			border-radius: 0;
}
.cws_button.large {
    padding: .65em 1em .65em;
    font-size: 19px;
}
.cws_button.small {
    font-size: 15px;
}
.cws_button.mini {
	padding: 0.25em 0.6em 0.5em;
    font-size: 13px;
}
.cws_button.align-btn-left {
    float: left;
}

.cws_button.align-btn-right {
    float: right;
}

.rectangle-block-buttons {
	width:155px;
	margin-right:100px;
}

.rounded-block-buttons {
	width:160px;
	margin-right:100px;
}

.medium-block-buttons {
	width:115px;
	margin-right:100px;
}

.small-block-buttons {
	width: 80px;
}

.ie8 ul.tabs li {
	/*background: url(../images/bg_tabs.png) repeat-x #ddf2fa;*/
}

/* --- Styles for Buttons --> End -- */

/* --- Dropcap --> Begin -- */

.dropcap {
	float: left;
	margin: 0.1em 0.2em 0 0;
	font-weight: 700;
	font-size: 3em;
	line-height: 0.8;
}

/* --- Styles for Buttons --> End -- */

/* --- Pullquotes --> Begin -- */

.pullright,
.pullleft{
	float: right;
	margin-left: 1em;
	width: 160px;
	font-size: 1.5em;
	line-height: 1.4em !important;
	margin: 0.5em 0 0.5em 0.5em !important;
}

.pullleft {
	float: left;
	margin-right: 1em;
	margin: 0.5em 0.5em 0.5em 0 !important;
}

/* --- Pullquotes --> End -- */

/*
##############################################################
WIDGETS -> Begin
##############################################################
*/

.widget {
	margin-bottom: 20px;
}

.kids-footer-copyrights  .widget {
	margin: 0px;
}

/* ----- Widget Accordion ----- */

.accordion {
	border:1px solid #fee1a6;
	-webkit-border-radius:8px;
	-moz-border-radius:8px;
	border-radius:8px;
	overflow: hidden;
}

.accordion li:first-child {
	border-top:none;
}

.accordion li {
	padding:0;
	font-size: inherit;
	position:relative;
	display:block;
	border-top:1px solid #fee1a6;
	text-decoration:none;
}
.accordion li:before{
	display: none;
}

.accordion li a.opener {
    display: block;
    position: relative;
	padding: 8px 10px 8px 35px;
    outline: medium none;
	text-decoration:none;
	font-style:normal;
	font-size:16px;
}
.accordion li a.opener:before{
	content: "";
	position: absolute;
	top: 13px;
	left: 11px;
	padding: 0 4px 0 5px;
	height: 15px;
	line-height: 15px;
	text-align: center;
	border-radius: 8px;
	content: "\f054";
	font-size: 9px;
	color: #fff;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.accordion li a.ui-state-active,
.accordion li a.ui-state-hover {
	background:#fee6b4;
}

.accordion li .slide-holder {
	padding: 15px 35px 0;
}
.accordion li .slide-holder .slide{
	overflow: hidden;
}
.accordion li .slide-holder.ui-accordion-content-active{
	background: #fcf5d5;
}
.accordion li .slide-holder h3 {
	margin-bottom: 5px;
}

/* --- Highlighter --- */

.highlighter {
	margin-bottom:1em;
}

.highlighter .opener {
	/*background:url(../images/bg-code.png) no-repeat left top;*/
	display:block;
	width:180px;
	height:46px;
	line-height:44px;
	padding-left:42px;
	color:#2f2f2f;
	text-decoration:none;
	font-size:1.5em;
	outline: none;
	position:relative;
	z-index:4;
	font-style:normal;
}

.highlighter li {
	position:relative;
}

.highlighter .opener span {
	/*background:url(../images/opener-arrow.png) no-repeat left top;*/
	left:10px;
	top:8px;
	display:block;
	position:absolute;
	height:27px;
	width:27px;
}

.highlighter .ui-state-active span {
	background-position:-33px 0;
}

.highlighter .slide-holder {
	position: relative;
	z-index: 3;
	margin-top: -8px;
	margin-left: 20px;
	padding: 15px 10px 15px 15px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
	line-height: 1.5em;
	min-width: 275px;
	/*background: url(../images/get-code-area.png) no-repeat left bottom #fff;*/
}

/* ----- Widget Tabbed ----- */

ul.tabs {
	margin-left: 1px;
	float: left;
	margin: 0;
	padding: 0;
	width: 100%;
	list-style: none;
}
ul.tabs>li	{
	float: left;
	overflow:hidden;
	font-size: inherit;
	margin: 0;
	margin-left: -1px;
	padding: 0;
	border-top:1px solid;
	border-right:1px solid;
	border-left:1px solid;
	line-height:40px;
	box-sizing: border-box;
	-webkti-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
}
ul.tabs>li:before{
	display: none;
}
ul.tabs>li:first-child{
	margin: 0;
	-webkit-border-radius:8px 0 0;
	-moz-border-radius:8px 0 0;
	border-radius:8px 0 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	box-sizing: border-box;
}
ul.tabs>li:last-child{
	-webkit-border-radius: 0 8px 0 0;
	-moz-border-radius: 0 8px 0 0;
	border-radius: 0 8px 0 0;
}
ul.tabs>li>a {
	display: block;
	padding:0 15px;
	outline: none;
	border-bottom:none;
	text-align:center;
	text-decoration: none;
	font-style:normal;
	font-size: 1.14em;
	line-height:inherit;
	color: inherit;
}
.tab_container {
	clear: both;
	overflow: hidden;
	margin-bottom:2em;
	width: 100%;
	border:1px solid #fee1a6;
	-webkit-border-radius:0 8px 8px 8px;
	-moz-border-radius:0 8px 8px 8px;
	border-radius:0 8px 8px 8px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
}

.tab_content {
	display:none;
	padding: 15px 15px 0;
	font-size: 13px;
}

.tab_content h3 {
	margin-bottom: 5px;
}

/* ----- Widget Toggle ----- */

.box-toggle {
	position:relative;
	margin-bottom:15px;
}

b.trigger {
	position:relative;
	display:block;
	padding: 8px 15px;
	border: 1px solid #fee1a6;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	text-decoration: none;
	font-weight: normal;
	font-size: 16px;
	cursor:pointer;
	background: #fee6b4;
}

b.trigger:before {
	content: "\f067";
	position: absolute;
	top: 13px;
	right: 15px;
	padding: 0 4px 0 4px;
	height: 15px;
	line-height: 15px;
	text-align: center;
	border-radius: 8px;
	font-size: 10px;
	color: #fff;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.trigger.active b.trigger:before{
	content: "\f068";
}

.toggle_container  {
	margin-top: -6px;
	padding: 1.5em 1em 0 1em;
	border-right:1px solid #fee1a6;
	border-bottom:1px solid #fee1a6;
	border-left:1px solid #fee1a6;
	-webkit-border-radius: 0 0 10px 10px;
	-moz-border-radius:  0 0 10px 10px;
	border-radius: 0 0 10px 10px;
}

/** ACCORDION */

.accordion_widget,
.toggle_widget{
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	overflow: hidden;
	border: 1px solid;
}
.accordion_section{
	border-bottom: 1px solid;
}
.accordion_section:last-child{
	border-bottom: 0;
}
.accordion_title{
	position: relative;
	font-size: 1.14em;
	line-height: 1.5em;
	padding: 8px 35px 8px 15px;
	cursor: pointer;
	-webkit-transition: background 250ms linear 0s, color 250ms linear 0s;
	-moz-transition: background 250ms linear 0s, color 250ms linear 0s;
	-ms-transition: background 250ms linear 0s, color 250ms linear 0s;
	-o-transition: background 250ms linear 0s, color 250ms linear 0s;
	transition: background 250ms linear 0s, color 250ms linear 0s;
}
.accordion_title .accordion_icon{
	position: absolute;
	top: 14px;
	right: 7px;
	width: 18px;
	height: 18px;
	border-radius: 50%;
}

.accordion_title  .acc_featured_icon,
.accordion_title .acc_featured_img{
	font-size: 21px;
	width: 32px;
	line-height: 32px;
	text-align: center;
	color: #fff;
	border-radius: 4px;
	overflow: hidden;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	margin-top: -6px;
	margin-bottom: -2px;
	margin-left: -7px;
}
.accordion_title .acc_featured_img>img{
	display: block;
}

.accordion_title .accordion_icon:before{
	left: 50%;
  	position: absolute;
  	top: 50%;
	transform-origin: 55% 10%;
	-webkit-transform-origin: 55% 10%;
    -moz-transform-origin: 55% 10%;
    -o-transform-origin: 55% 10%;
    -ms-transform-origin: 55% 10%;
	transform: translate(-38%, -50%);
	-ms-transform: translate(-38%, -50%);
	-webkit-transform: translate(-38%, -50%);
	-moz-transform: translate(-38%, -50%);
	content: "\f054";
	position: absolute;
	line-height: 1;
	font-size: 10px;
	color: #fff;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-align: center;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-webkit-transition: all 250ms;
	-moz-transition: all 250ms;
	-ms-transition: all 250ms;
	-o-transition: all 250ms;
	transition: all 250ms;
}
.accordion_section.active .accordion_title .accordion_icon:before{
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
.accordion_content{
	padding: 15px;
	overflow: hidden;
}
.accordion_content .prettyPhoto.wp-image-popup .kids_curtain,
.tab_item .prettyPhoto.wp-image-popup .kids_curtain{
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.accordion_content img[class*="wp-image"],
.tab_items img[class*="wp-image"]{
	border: 0;
	overflow: hidden;
	-webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
	-webkit-transform: rotate(0.000001deg);
}
.accordion_section + .accordion_section{
	margin-top: -1px;
}
/** TABS */
.tabs{
	position: relative;
	z-index: 1;
}
.tabs .tab{
	position: relative;
	display: inline-block;
	font-size: 1.14em;
	padding: 0 15px;
	text-align: center;
	border: 1px solid;
	margin-bottom: -1px;
	line-height: 40px;
	margin-right: -1px;
	color: inherit;
}
.tabs .tab:first-child{
	-webkit-border-radius: 8px 0 0;
	-moz-border-radius: 8px 0 0;
	border-radius: 8px 0 0;
}
.tabs .tab:last-child{
	-webkit-border-radius: 0 8px 0 0;
	-moz-border-radius: 0 8px 0 0;
	border-radius: 0 8px 0 0;
}
.tab_items{
	position: relative;
	z-index: 2;
	font-size: 0.928em;
	padding: 15px;
	border: 1px solid;
	-webkit-border-radius: 0 8px 8px 8px;
	-moz-border-radius: 0 8px 8px 8px;
	border-radius: 0 8px 8px 8px;
}
.tab_item>*:last-child{
	margin-bottom: 0;
}

.tab_widget.type-vertical{
	display: table;
	width: 100%;
}
.tab_widget.type-vertical>div{
	display: table-cell;
}
.tab_widget.type-vertical>.tabs{
	white-space: nowrap;
}
.tab_widget.type-vertical>.tabs a{
	display: block;
	margin-bottom: 0;
	border-top-width: 0;
}
.tab_widget.type-vertical>.tabs a:first-child{
	border-top-width: 1px;
	border-top-style: solid;
}
.tab_widget.type-vertical>.tabs{
	vertical-align: top;
}
.tab_widget.type-vertical>.tab_items{
	width: 100%;
	-webkit-border-radius: 0 8px 8px 0;
	-moz-border-radius: 0 8px 8px 0;
	border-radius: 0 8px 8px 0;
}
.tab_widget.type-vertical .tabs .tab:last-child {
  -webkit-border-radius: 0 0 0 8px;
  -moz-border-radius: 0 0 0 8px;
  border-radius: 0 0 0 8px;
}
@media screen and (max-width:767px){
	.tabs .tab{
		width: 100%;
		box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		-ms-box-sizing: border-box;
	}
	.tab_widget:not(.type-vertical) .tabs .tab:first-child {
		-webkit-border-radius: 8px 8px 0 0 !important;
		-moz-border-radius: 8px 8px 0 0 !important;
		border-radius: 8px 8px 0 0 !important;
	}
	.tab_widget:not(.type-vertical) .tabs .tab:last-child{
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
	}
	.tab_widget:not(.type-vertical) .tab_items{
		-webkit-border-radius: 0 0 8px 8px;
		-moz-border-radius: 0 0 8px 8px;
		border-radius: 0 0 8px 8px;
	}
}
#kids_bottom_container .tweet_list li  {
	margin-bottom: 1em;
	font-size: 1.083em;
	line-height: 1.5em;
}

#kids_bottom_container .tweet_list a {
	color: #385EA7;
	font-size: 1em;
	line-height: 1.5em;
}

#kids_bottom_container .tweet_list .tweet_time a {
	display: block;
    margin-top: 0.5em;
	color: #fff;
    font-style: italic;
    font-size: 1em;
}

.most-view-widget li:first-child,
.latest-posts-widget li:first-child {
	margin-top:0;
}

.latest-posts-widget li {margin-bottom: 2em;}

.latest-posts-widget li:after {
	clear: both;
	display: block;
	visibility: hidden;
	height: 0;
	content: ".";
}

.latest-posts-widget .kids_post_content p {
	margin-bottom:.5em;
}

/* ----- Widget Pagenavi ----- */

.pagenavi {
	padding: 1em 1em .7em;
	margin: 45px 0 0 0;
	text-align: center;
}

#dsb .pagenavi {
	margin-left: 10px;
	margin-right: 10px;
}

.pagenavi>*{
	border-radius: 5px;
	color: inherit !important;
	vertical-align: middle;
}

.pagenavi .pages {
	font-size: 1em;
	margin-right: 12px;
}
.pagenavi>.pages{
	display: none;
}


.pagenavi .prevpostslink,
.pagenavi .nextpostslink {
	font-size: 0;
}
.pagenavi .prevpostslink:before,
.pagenavi .nextpostslink:before{
	content: "\f100";
	display: inline-block;
	font: normal normal normal 1em/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 40px;
}
.pagenavi .nextpostslink:before{
	content: "\f101";
}

.pagenavi .page,
.pagenavi .current,
.pagenavi .prevpostslink,
.pagenavi .nextpostslink {
	height:40px;
	width:40px;
	margin-right: 1px;
	display: inline-block;
	font-style: normal !important;
	text-align: center;
	line-height: 40px;
}


/* callout */

.callout_widget{
	padding: 5px;
	border: 1px solid;
	border-radius: 9px;
	padding: 10px 20px;
}
.callout_widget.with_icon{
	padding-left: 5px;
}
.callout_widget:after{
	content: "";
	clear: both;
	display: table;
}
.callout_widget .icons_part{
	float: left;
	font-size: 3em;
	width: 1.41em;
	line-height: 1.42em;
	border-radius: 5px;
	border: 1px solid;
	text-align: center;
	margin-right: 0.312em;
}
.callout_widget .icons_part.img_icon{
	background-color: transparent;
	border: none;
	width: auto;
	padding-left: 5px;
}
.callout_widget .icons_part.img_icon img{
	display: block;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	-ms-border-radius: 9px;
	border-radius: 9px;
}
.callout_widget .content_wrapper{
	display: table;
	table-layout: fixed;
}
.callout_widget .content_wrapper>*{
	display: table-cell;
	vertical-align: middle;
}
.callout_widget .content_wrapper .text_part{
	width: 100%;
}
.callout_widget .button_part{
	padding-right: 15px;
	padding-left: 10px;
	white-space: nowrap;
}
.callout_widget .content_wrapper .title{
	font-size: 1.688em;
	font-weight: 700;
	margin-bottom: 3px;
}


/* ----- Widget Categories Type-1 -> Begin ----- */


aside .widget>.latest-posts-widget>.widget-content,
aside .widget.widget_categories>.ui-widget,
aside .widget.widget_archive>ul,
aside .widget_calendar>#calendar_wrap,
aside .widget_recent_comments>#recentcomments,
aside .widget>.textwidget,
aside .widget>.tagcloud,
aside .widget_search>#search-form,
aside .widget_cws_video .widget-content>.widget_video,
aside .widget>.contact-us,
aside .widget_cws_nav>.widget-content>.nav_cat,
aside .widget_meta>ul,
aside .widget_recent_entries>ul,
aside .widget_pages>ul,
aside .widget_categories>ul,
aside .cws_tweets,
aside .postform,
aside .flickr-badge,
aside .widget_cws_benefits .cws-widget-content .text_part,
aside .widget_shopping_cart_content,
aside .woocommerce.widget_price_filter>form,
aside .woocommerce.widget_product_categories .product-categories,
aside .woocommerce.widget_products .product_list_widget,
aside .woocommerce.widget_product_search>form,
aside .woocommerce.widget_recent_reviews .product_list_widget,
aside .woocommerce.widget_recently_viewed_products .product_list_widget,
aside .woocommerce.widget_top_rated_products  .product_list_widget,
aside .woocommerce.widget_layered_nav_filters>ul{
	border: 5px solid;
	border-radius: 9px;
	padding: 5px;
	padding-top: 20px;
	overflow: hidden;
	position: relative;
	width: 100%;
	z-index: 2;
	background: #fff;
	box-sizing:border-box;
	-webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
	-webkit-transform: rotate(0.000001deg);
}
aside .widget>.widget-title+.tagcloud,
aside .postform{
	padding-top: 5px;
}
aside .widget_recent_comments>#recentcomments>li:first-child,
aside .widget.widget_categories>ul>li:first-child,
aside .widget_cws_nav>.widget-content>.nav_cat>ul,
.widget.woocommerce.widget_product_categories .product-categories>li:first-child{
	margin-top: -0.7em;
}
aside .widget .textwidget,
aside .widget_cws_benefits .cws-widget-content .text_part,
aside .widget_search>#search-form,
aside .widget_calendar>#calendar_wrap,
aside .woocommerce.widget_price_filter .widget-title+form,
aside .woocommerce.widget_product_search>form,
aside .woocommerce.widget_layered_nav_filters>ul{
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 20px;
}
aside .tagcloud{
	text-align: center;
}
aside .widget_cws_video .widget-content>.widget_video{
	padding: 0;
}

aside .widget_cws_video.type-3 .widget-content>.widget_video{
	padding-top: 18px;
}

aside .widget.type-3>.latest-posts-widget>.widget-title+.widget-content,
aside .widget.type-3.widget_categories>.widget-title+.ui-widget,
aside .widget.type-3.widget_archive>.widget-title+ul,
aside .widget_calendar.type-3>.widget-title+#calendar_wrap,
aside .widget_recent_comments.type-3>.widget-title+#recentcomments,
aside .widget.type-3>.widget-title+.textwidget,
aside .widget.type-3>.widget-title+.tagcloud,
aside .widget_search.type-3>.widget-title+#search-form,
aside .widget_cws_video.type-3 .widget-content>.widget-title+.widget_video,
aside .widget.type-3>.widget-title+.contact-us,
aside .widget_cws_nav.type-3>.widget-title+.widget-content>.nav_cat,
aside .widget_meta.type-3>.widget-title+ul,
aside .widget_recent_entries.type-3>.widget-title+ul,
aside .widget_pages.type-3>.widget-title+ul,
aside .widget_categories.type-3>.widget-title+ul,
aside .widget_cws_tweets.type-3>.widget-title+.widget-content,
aside .widget_categories.type-3 .postform,
aside .widget.type-3 .widget-title+.flickr-badge,
aside .widget_cws_benefits.type-3 .cws-widget-content .widget-title+.text_part,
aside .type-3 .widget-title+.widget_shopping_cart_content,
aside .woocommerce.widget_price_filter.type-3 .widget-title+form,
aside .woocommerce.widget_product_categories.type-3 .widget-title+ .product-categories,
aside .woocommerce.widget_products.type-3 .widget-title+ .product_list_widget,
aside .woocommerce.widget_product_search.type-3 .widget-title+ form,
aside .woocommerce.widget_recent_reviews.type-3 .widget-title+ .product_list_widget,
aside .woocommerce.widget_top_rated_products.type-3 .widget-title+  .product_list_widget,
aside .woocommerce.widget_recently_viewed_products.type-3 .widget-title+ .product_list_widget{
	padding-top: 35px;
}
aside .widget.type-3>.widget-title+.tagcloud{
	padding-top: 20px;
}
aside .widget_categories.type-3 .postform{
	height: 80px;
}
aside .widget_cws_tweets>.widget-content .tweet_list{
	margin-top: -0.7em;
	margin-bottom: 0;
}

aside .flickr-badge{
	padding: 10px 15px 20px;
}

aside .widget.type-3 .widget-title+.flickr-badge{
	margin-top: -10px;
	display: inline-block;
}

aside .widget_cws_tweets .widget_recent_comments ul.tweet_list>li{
	padding-left: 10px;
	padding-right: 10px;
	border-color: #fee1a6;
}

aside .widget h3.widget-title{
	margin-bottom: -10px;
	margin-top: 0;
	padding: 7px 0 17px;
	color: #fff!important;
	position: relative;
	z-index: 1;
	-webkit-border-top-left-radius: 9px;
	-webkit-border-top-right-radius: 9px;
	-moz-border-radius-topleft: 9px;
	-moz-border-radius-topright: 9px;
	border-top-left-radius: 9px;
	border-top-right-radius: 9px;
}
h3.widget-title{
	text-align: center;
	position: relative;
	margin-bottom: 1em;
	z-index: 1;
}

.widget_categories ul li:before{
	display: none;
}

/* ----- Widget Categories Type-1 -> End ----- */

/* ----- Widget Categories Type-2 -> Begin ----- */

aside .widget.type-2 h3.widget-title{
	padding-left: 35px;
	padding-right: 35px;
	box-sizing:border-box;
}
aside .widget.type-2 h3.widget-title:before,
aside .widget.type-2 h3.widget-title:after{
	content: "";
	position: absolute;
	top: calc(50% - 19px);
	left: 3px;
	background: url("../images/flower.png") no-repeat 0px 0px transparent;
	width: 33px;
	height: 33px;
	display: inline-block;
}
aside .widget.type-2 h3.widget-title:after{
	left: auto;
	right: 2px;
}
aside .widget.type-3 h3.widget-title{
	z-index: 3;
	border-radius: 1000px;
	padding: 7px 35px;
	margin-top: 10px;
	margin-bottom: -20px;
	border-top-left-radius: 1000px;
	border-top-right-radius: 1000px;
	border-bottom-left-radius: 1000px;
	border-bottom-right-radius: 1000px;
}
aside .widget.type-3 h3.widget-title .cloud-element-1,
aside .widget.type-3 h3.widget-title .cloud-element-2,
aside .widget.type-3 h3.widget-title .cloud-element-3{
	position: absolute;
	top: -10px;
	left: 16px;
	width: calc(40% - 30px);
	height: 100%;
	overflow: hidden;
	z-index: -1;
}
aside .widget.type-3 h3.widget-title .cloud-element-2{
	left: calc(13px + 29%);
	width: calc(40% - 26.6px);
}
aside .widget.type-3 h3.widget-title .cloud-element-3{
	left: calc(67% - 15px);
	width: calc(42% - 26.6px);
}
aside .widget.type-3 h3.widget-title .cloud-element-1:before{
	content: "";
	position: absolute;
	width: 100%;
	top: 2px;
	left: -14%;
	height: 300px;
	border-radius: 1000px;
	border-top-left-radius: 1000px;
	border-top-right-radius: 1000px;
	border-bottom-left-radius: 1000px;
	border-bottom-right-radius: 1000px;
}
aside .widget.type-3 h3.widget-title .cloud-element-1:after{
	content: "";
	position: absolute;
	width: 62%;
	top: 0px;
	right: -14%;
	height: 300px;
	border-radius: 1000px;
	border-top-left-radius: 1000px;
	border-top-right-radius: 1000px;
	border-bottom-left-radius: 1000px;
	border-bottom-right-radius: 1000px;
}
aside .widget.type-3 h3.widget-title .cloud-element-2:before{
	content: "";
	position: absolute;
	width: 160%;
	top: 3px;
	left: -61%;
	height: 300px;
	border-radius: 1000px;
	border-top-left-radius: 1000px;
	border-top-right-radius: 1000px;
	border-bottom-left-radius: 1000px;
	border-bottom-right-radius: 1000px;
}
aside .widget.type-3 h3.widget-title .cloud-element-2:after{
	content: "";
	position: absolute;
	width: 67%;
	top: 2px;
	left: 43%;
	height: 300px;
	border-radius: 1000px;
	border-top-left-radius: 1000px;
	border-top-right-radius: 1000px;
	border-bottom-left-radius: 1000px;
	border-bottom-right-radius: 1000px;
}
aside .widget.type-3 h3.widget-title .cloud-element-3:before{
	content: "";
	position: absolute;
	width: 183%;
	top: 0px;
	left: -60%;
	height: 300px;
	border-radius: 1000px;
	border-top-left-radius: 1000px;
	border-top-right-radius: 1000px;
	border-bottom-left-radius: 1000px;
	border-bottom-right-radius: 1000px;
}
aside .widget.type-3 h3.widget-title .cloud-element-3:after{
	content: "";
	position: absolute;
	width: 40%;
	top: 5px;
	right: 0%;
	height: 300px;
	border-radius: 1000px;
	border-top-left-radius: 1000px;
	border-top-right-radius: 1000px;
	border-bottom-left-radius: 1000px;
	border-bottom-right-radius: 1000px;
}

aside .widget.type-3 h3.widget-title:before,
aside .widget.type-3 h3.widget-title:after{
	content: "";
	position: absolute;
	top: calc(50% - 26px);
	left: -3px;
	background: url("../images/flower-title-1.png") no-repeat 0px 0px transparent;
	width: 50px;
	height: 60px;
	display: inline-block;
}
aside .widget.type-3 h3.widget-title:after{
	left: auto;
	right: 0;
	background: url("../images/flower-title-2.png") no-repeat 0px 0px transparent;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (-o-device-pixel-ratio: 3/2),
only screen and (min-device-pixel-ratio: 1.5) {
	aside .widget.type-3 h3.widget-title:before{
		background: url("../images/flower-title-1@2x.png") no-repeat 0px 0px transparent;
		background-size: 45px;
	}
	aside .widget.type-3 h3.widget-title:after{
		background: url("../images/flower-title-2@2x.png") no-repeat 0px 0px transparent;
		background-size: 56px;
	}
	aside .widget.type-2 h3.widget-title:before,
	aside .widget.type-2 h3.widget-title:after{
		background: url("../images/flower@2x.png") no-repeat 0px 0px transparent;
		background-size: 32px;
	}
}

/* ----- Widget Categories Type-3 -> End ----- */

.content-container .widget {
    position: relative;
    padding: 15px 14px 0;
	/*background: url("../images/aside-holder-widget.png") no-repeat scroll center top transparent;*/
}

.content-container .widget_cat ul {
	padding: 10px 5px 0;
}

.content-container .widget_cat ul li a {
	display: block;
	padding: .7em 5px;
}

.content-container .widget_cat ul li.backLava {
	-webkit-border-radius: 6px;
	-moz-borer-radius: 6px;
	border-radius: 6px;
	background: #c8dfed;
}

.content-container .widget_cat ul a {
	position: relative;
	z-index: 2;
	color: #385ea7;
	font-weight: 700;
}

.content-container .widget_cat ul a:hover,
.content-container .widget_cat ul li .selectedLava {
	color: #002c6b;
}

/* ----- Widget Video ----- */
.cws_widget_content p>iframe{
	border: 10px solid #fee6b4;
	border-radius: 10px;
	max-width: 100%;
	box-sizing: border-box;
}

.footer .kids_video_wrapper,
.top-panel .kids_video_wrapper{
	border: 1px solid rgba(255,255,255,0.5);
	padding: 10px;
	border-radius: 8px;
	background: rgba(0,0,0,0.08);
}
.footer .kids_video_wrapper figure,
.top-panel .kids_video_wrapper figure{
	overflow: hidden;
	-webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
	-webkit-transform: rotate(0.000001deg);
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
.kids_video_wrapper figure{
	z-index: 999 !important;
	position: relative;
	padding-bottom: 50%;
}
aside .kids_video_wrapper figure{
	-webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
	-webkit-transform: rotate(0.000001deg);
	border-radius: 4px;
}
.kids_video_wrapper iframe{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.widget_video.type-1 .kids_video_wrapper {
	margin-left: 9px !important;
	margin-right: 9px !important;
}

.widget_video.type-3 {
	padding: 9px;
}

/* ----- Widget Latest Posts ----- */

body.t-text-2 .latest-posts-widget ul {
}

.latest-posts-widget .kids_image_wrapper {
    float: left;
    margin-right: 10px;
}

.latest-posts-widget .kids_post_content h4 a {
    text-align: left;
	color: inherit;
	font-style: normal;
	-moz-transition:all 250ms linear 0s;
	-webkit-transition:all 250ms linear 0s;
	-o-transition:all 250ms linear 0s;
	transition:all 250ms linear 0s;
}

.latest-posts-widget .kids_post_content h4 {
    margin-bottom: .2em;
}

.latest-posts-widget .kids_post_content p {
    line-height: 1.3em;
}

.latest-posts-widget ul li:first-child {
	border-top: none;
	padding-top: .3em;
}

.latest-posts-widget ul li {
	font-size: inherit;
    border-top: 1px solid rgba(255,255,255,0.5);
	margin-bottom: 0;
	padding: .9em 0 .9em;
	clear: both;
}
.latest-posts-widget ul li:before{
	display: none;
}

.latest-posts-widget ul li:after {
	clear: both;
	display: block;
	visibility: hidden;
	height: 0;
	content: ".";
}

.latest-posts-widget ul {
	margin-bottom: 2em;
}
.latest-posts-widget .widget-content>*:last-child{
	margin-bottom: 0;
}
.type-1.latest-posts-widget ul {
	margin-left: 10px;
}

/* ----- Widget Recent Tweets ----- */

.widget_recent_comments {position: relative;}

.widget_recent_comments .tweet_list li {
	margin-left: 10px;
}

.widget_recent_comments .tweet a {
	display: block;
	color: #385EA7;
	font-style: normal !important;
	text-decoration: underline;
	-moz-transition:all 250ms linear 0s;
	-webkit-transition:all 250ms linear 0s;
	-o-transition:all 250ms linear 0s;
	transition:all 250ms linear 0s;
}

/* ----- Widget Recent Posts ----- */

.widget_recent_posts .entry,
.widget_popular_posts .entry {
	padding: 5px 10px;
	text-align: left !important;
}

.widget_recent_posts li:first-child,
.widget_popular_posts li:first-child {
	padding-top: .7em;
	background: none;
}

.widget_recent_posts li {
	padding-top: 1.5em;
	padding-bottom: 1.2em;
	/*background: url(../images/gallery-divider.png) repeat-x left top;*/
}

.widget_recent_posts li .title-recent-posts {
	font-size: 19px;
	margin-bottom: 0;
}

.widget_recent_posts li .title-recent-posts a {
	color: #385ea7;
}

.widget_recent_posts li .recent-posts-date {
	color: #2f2f2f;
	font-style: italic;
}

/* ----- Widget Popular Posts ----- */

.widget_popular_posts li {
	padding-top: 1.7em;
	padding-bottom: .1em;
	/*background: url(../images/gallery-divider.png) repeat-x left top;*/
}

.widget_popular_posts .entry .kids_image_wrapper {
	float: left;
	margin-right: 10px;
}

.widget_popular_posts .entry .excerpt {
	margin-bottom: .8em;
	line-height: 1.5em;
}

/* --- Media Categories --- */

.media_cat.type-1 ul {margin-top: 30px;}
.media_cat.type-3 ul {margin-top: 15px;}

.contact-info.type-1 li:first-child,
.media_cat li:first-child,
.contact-info li:first-child {border-top: none;}

.media_cat li,
.contact-info li {
	margin: 0 10px;
	padding: 11px 0 11px 0;
	border-top: 1px solid #89b3d3;
}

.media_cat.type-2 li,
.contact-info.type-2 li,
.media_cat.type-3 li,
.contact-info.type-3 li {margin: 0;}

.media_cat li a,
.contact-info li a,
.contact-info li span {
	padding-left: 35px;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 1.167em;
}

.media_cat li a {color: #000;}

/*
.media_cat a.audio-icon {
	background: url(../images/icons/audio-icon.png) no-repeat left;
}

.media_cat a.image-icon {
	background: url(../images/icons/image-icon.png) no-repeat left;
}

.media_cat a.texts-icon {
	background: url(../images/icons/texts-icon.png) no-repeat left;
}

.media_cat a.video-posts-icon {
	background: url(../images/icons/video-posts-icon.png) no-repeat left 5px;
}
*/
/* --- Contact Info --- */

.contact-info.type-1 {margin-bottom: 3em;}
.contact-info.type-1 ul {margin-bottom: 1.5em;}
.contact-info.type-2 ul {margin: 1em 0 1.5em;}
/*
.contact-info li span.landline_phone {
	background: url(../images/icons/phone-icon.png) no-repeat left;
}

.contact-info li span.mobile_phone {
	background: url(../images/icons/mobile-phone-icon.png) no-repeat 3px;
}

.contact-info li a.mailto {
	background: url(../images/icons/mailto.png) no-repeat left;
	color: #385EA7;

	-webkit-transition: color .25s linear;
	   -moz-transition: color .25s linear;
		-ms-transition: color .25s linear;
		 -o-transition: color .25s linear;
			transition: color .25s linear;
}

.contact-info li a.mailto:hover {color: #2F2F2F;}

.contact-info li span.male {
	background: url(../images/icons/male-icon.png) no-repeat 2px;
}

.contact-info li span.female {
	background: url(../images/icons/female-icon.png) no-repeat 2px;
}
*/
.contact-info.type-1 .get-code,
.contact-info.type-2 .get-code,
.contact-info.type-3 .get-code {
	display: block;
	margin: 10px;
	padding: .2em;
	width: 86%;
	border: 1px solid #62a7d2;
	text-align: center;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius:5px;
	background: #539ece;
	text-shadow: 0 0 2px #5e9dcc;
	-webkit-box-shadow: 0 3px 5px rgba(0,0,0,.2);
	-moz-box-shadow: 0 3px 5px rgba(0,0,0,.2);
	box-shadow: 0 3px 5px rgba(0,0,0,.2);
	background: -webkit-gradient(linear, left top, left bottom, from(#9ac9e3), to(#539ece));
	background: -moz-linear-gradient(top,  #9ac9e3,  #539ece);
  	background: -o-linear-gradient(rgb(154,221,227),rgb(83,158,206));
	color: #fff;
	font-style: italic;
	font-size: 2em;
}

.contact-info.type-2 .get-code,
.contact-info.type-3 .get-code {
	position: relative;
	z-index: 2;
	display: block;
	padding: .2em .6em;
	margin-left: 0;
	margin-right: 0;
}

/* User Login */

.contact-us label {display: block;}

.capcha-frame {
	float: left;
	width: 85px;
	height: 30px;
}

.contact-us input[type="text"].verify {
	width: 90px;
	float: right;
}

.user-login.type-1 .small,
.user-login.type-2 .small,
.user-login.type-3 .small,
.search.type-1 .small,
.search.type-2 .small,
.search.type-3 .small,
.contact-us.type-1 .small,
.contact-us.type-2 .small,
.contact-us.type-3 .small {margin-bottom: .6em;}

#userLogin,
#search,
#contactWidgetForm {
	padding-top: 1em;
	width: 200px;
}

.widget_cws_contact .row input.error, .contact_Form .row textarea.error {
	border-color: #FF99A6;
}
.widget_cws_contact label.error {
	color: #FF0000 !important;
}

.user-login input[type="text"],
.search input[type="text"],
.contact-us input[type="text"] {
	margin-bottom: 10px;
}

.user-login input[type="text"],
.search input[type="text"],
.contact-us input[type="text"],
.contact-us textarea {
    border: 1px solid;
    border-radius: 3px;
    font-family: Arial;
    font-size: 12px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 100%;
    resize:none;
    box-sizing:border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -ms-box-sizing:border-box;
}
.top-panel .user-login input[type="text"],
.top-panel .search input[type="text"],
.top-panel .contact-us input[type="text"],
.top-panel .contact-us textarea,
.footer .user-login input[type="text"],
.footer .search input[type="text"],
.footer .contact-us input[type="text"],
.footer .contact-us textarea {
	border: 1px solid rgba(255,255,255,0.5);
}
/* Contact Us */

.contact-us #contactWidgetForm textarea {
	margin-bottom: 10px;
	padding: 10px;
	width: 180px;
	min-width: 180px;
	max-width: 180px;
	height: 90px;
	min-height: 90px;
	max-height: 160px;
	border: 1px solid #8fb7d7;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	color: #2f2f2f;
	font-size: 13px;
	font-family: Arial;
}

.contact-us.type-1 fieldset {margin: 0 10px;}

/* Flickr */

.flickr-badge {
	padding-bottom: 7px;
	font-size: 0;
	width: 100%;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 330px;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
.footer .flickr-badge,
.top-panel .flickr-badge{
	margin-left: 0;
}


.flickr-badge li {
	display: inline-block;
	position: relative;
	z-index: 1;
	padding: 0 !important;
	width: 30%;
	max-width: 70px;
	margin-right: 4.9%;
	margin-top: 5%;
}
.flickr-badge li:before{
	display: none;
}
.flickr-badge li:nth-child(3n+3){
	margin-right: 0;
}

.flickr-badge img {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	display: block;
	border-width: 2px;
	border-style: solid;
	-webkit-border-radius: 5px;
	   -moz-border-radius: 5px;
			 border-radius: 5px;

	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
}

.flickr-badge>li>a{
	display: inline-block;
	position: relative;
	width: 100%;
	padding-bottom: 100%;
	z-index: 1;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
}
.flickr-badge>li>a:before{
	content: "";
	position: absolute;
	top: 2px;
	bottom: 2px;
	left: 2px;
	right: 2px;
	background: #fff;
	opacity: 0;
	z-index: 2;
	-webkit-transition: all .3s linear;
	-moz-transition: all .3s linear;
	-o-transition: all .3s linear;
	transition: all .3s linear;
}
.flickr-badge>li:hover a:before{
	opacity: 0.3;
}
.flickr-badge a{
	display: block;
	border-radius: 5px;
}
.footer .flickr-badge img,
.top-panel .flickr-badge img {
	border-color: rgba(255,255,255,0.5);
}

@media screen and (max-width:767px){
	.flickr-badge li,
	.flickr-badge li:nth-child(3n+3){
		width: 70px;
		margin: 5px;
	}
}

/* --- 404 Page --- */

.e404 {
	text-align: center;
}

.holder404 {
	font-weight: 300;
	padding-bottom: 60px;
}
.holder404 .e404{
	padding-top: 100px;
}

.holder404 .e404 h1 {
	display: inline-block;
	margin: 0;
	padding: 0;
	font-size: 13vw !important;
	font-weight: 400;
	line-height: 1;
}

.holder404 .title_error {
	display: inline-block;
}

.holder404 .title_error span {
	display: block;
	color: inherit;
	text-transform: uppercase;
	font-size: 3.25em !important;
	line-height: .4em !important;
}

.holder404 .title_error div {
	font-size: 2.5em;
	color: inherit;
	font-weight: 300;
	line-height: 48.75px !important;
}

.holder404 .description_error {
	color: inherit;
	text-align: center;
	font-size: 2.267em !important;
	line-height: 44.2px !important;
}

.holder404 .description_error a {
	text-decoration: underline;
	font-style: normal;
}

/* --- Sitemap --- */

.map {
	float: left;
	margin: 0 10px 7em 10px;
}

.map li a {
	display: block;
	padding: .5em 5px .5em 8px;
	border-bottom: 1px dotted #919191;
	color: #3c3c3c;
	font-size: 1.024em;
	/*background: url(../images/sitemap-arrow.png) no-repeat 1px center;*/
}

.map li a:hover { color: #385ea7;}
.map ul ul {margin: .5em 0 .5em .5em;}
.map ul ul li a {
	padding: .4em 5px .4em 8px;
	font-size: 0.917em;
}

/*
##############################################################
WIDGETS -> End
##############################################################
*/


/*
##############################################################
MODULES -> End
##############################################################
*/

/*
##############################################################
States -> Begin
##############################################################
*/

/*
##############################################################
States -> End
##############################################################
*/
/* -- Contact  --> Begin --  */

.contactForm {
	margin-top:1.7em;
}

.contactForm fieldset {
	border:1px solid #9bc0dd;
	-moz-border-radius:5px;
	-webkit-border-radius:5px;
	border-radius:5px;
	background:#eaf7fb;
	padding:3em 3.5em 0em 3.5em;
}

.contactForm .row {
	margin-bottom:2em;
}

.contactForm label {
	color:#2f2f2f;
	display:block;
	margin-bottom:.4em;
	font-size:13px;
	font-weight:700;
}

.contactForm .row input[type="text"], .contactForm .row textarea{
	width:100%;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;
	box-sizing:border-box;
}

.contactForm .row input.verify {
	width: 80px;
	vertical-align: top;
}

.contactForm .capcha-frame {
	width: 80px;
	height: 30px;
}

.contactForm .row .wrong-data {border-color: #FF99A6 !important;}

#map_canvas {
	border: 1px solid #fff;
	width: 292px;
	height: 254px;
}

/* --- Address --- */

.contact-address {
	padding-left:12px;
}

.contact-address address {
	font-style:normal;
	line-height:1.5em;
	margin-bottom:1.2em;
}


/* -- Contact --> End -- */

/*
##############################################################
EXTENDED TYPOGRAPHY -> Begin
##############################################################
*/

/* - Dividers and Borders -> Begin ----------------------------------- */

hr{
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	display: block;
	height: 1px;
	border-style: none;
	background: transparent;
	border-top: 1px solid;
	margin: 0;
}

.divider-content {
	height:1px;
	border-bottom:1px solid #97bddb;
	width:100%;
	clear:both;
	overflow:hidden;
	margin-bottom:1.5em;
}
.content-wrapper{
	max-width: 100%;
}
.content-wrapper.alignleft {
	float:left;
	margin: 0 0.9em 0.5em 0;
}
.content-wrapper.alignright {
	margin: 0 0 0.5em 0.9em;
}
.content-wrapper figure{
	border: 10px solid #fee6b4;
	border-radius: 10px;
}
aside .content-wrapper figure{
	border-width: 2px;
	border-style: solid;
	overflow: hidden;
	border-radius: 5px;
}
.content-wrapper img{
	display: block;
	max-width: 100%;
	height: auto;
}

.portfolio .content-wrapper img,
.iso-item  .content-wrapper img{
	width: 100%;
}

.alignleft {
	float: left;
	margin-right: 20px;
}
.alignright {
	float: right;
	margin-left: 20px;
}
.aligncenter {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
	display: block;
}

.add_border:after {
	/*background:url(../images/shadow-sprite.png) no-repeat 0 -41px;*/
	content:"";
	position:absolute;
	bottom:-20px;
	left:0;
	width:220px;
	height:20px;
}

/* - Dividers and Borders -> End -------------------------------------- */

/* - Custom Box Styles -> Begin -------------------------------------- */
.custom-box-wrap{
	position: relative;
}
.message_box{
	color: #fff;
	-moz-border-radius:8px;
	-webkit-border-radius:8px;
	border-radius:8px;
	min-height: 35px;
	padding:15px 15px  15px 53px;
	background-position:20px center;
	background-repeat:no-repeat;
	overflow:hidden;
	position: relative;
	z-index: 1;
}
.message_box>*:last-child,
.message_box>p{
	margin-bottom: 0;
}
.message_box .message_box_header{
	font-size: 1.3em;
	line-height: 1;
}
.message_box:before{
	content: "";
	position: absolute;
	top: 15px;
	left: 10px;
	width: 35px;
	height: 35px;
	background: rgba(255, 255, 255, 0.9);
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	font-size: 28px;
	line-height: 35px;
	text-align: center;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}
.message_box.custom .box-icon{
	position: absolute;
	top: 15px;
	left: 10px;
	width: 35px;
	line-height: 35px;
	text-align: center;
	font-size: 28px;
	overflow: hidden;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
}
.is-ie .message_box.custom .box-icon{
	text-shadow: none !important;
}
.no-ie .message_box.custom .box-icon{
	text-shadow: rgb(207, 207, 207) 1px 1px,
    rgb(207, 207, 207) 2px 2px,
    rgb(207, 207, 207) 3px 3px,
    rgb(207, 207, 207) 4px 4px,
    rgb(207, 207, 207) 5px 5px,
    rgb(207, 207, 207) 6px 6px,
    rgb(207, 207, 207) 7px 7px,
    rgb(207, 207, 207) 8px 8px,
    rgb(207, 207, 207) 9px 9px,
    rgb(207, 207, 207) 10px 10px,
    rgb(207, 207, 207) 11px 11px,
    rgb(207, 207, 207) 12px 12px,
    rgb(207, 207, 207) 13px 13px,
    rgb(207, 207, 207) 14px 14px,
    rgb(207, 207, 207) 15px 15px,
    rgb(207, 207, 207) 16px 16px,
    rgb(207, 207, 207) 17px 17px,
    rgb(207, 207, 207) 18px 18px,
    rgb(207, 207, 207) 19px 19px,
    rgb(207, 207, 207) 20px 20px;
}
.message_box.no-icon:before{
	display: none;
}
.message_box.no-icon{
	padding-left: 15px;
}
.message_box.close{
	padding-right: 42px;
}
.question-box {
	background: #DE92E0;
}
.question-box:before{
	content: "\f128";
	color: #DE92E0;
}
.no-ie .question-box:before{
	text-shadow: rgb(226, 220, 226) 1px 1px,
    rgb(226, 220, 226) 2px 2px,
    rgb(226, 220, 226) 3px 3px,
    rgb(226, 220, 226) 4px 4px,
    rgb(226, 220, 226) 5px 5px,
    rgb(226, 220, 226) 6px 6px,
    rgb(226, 220, 226) 7px 7px,
    rgb(226, 220, 226) 8px 8px,
    rgb(226, 220, 226) 9px 9px,
    rgb(226, 220, 226) 10px 10px,
    rgb(226, 220, 226) 11px 11px,
    rgb(226, 220, 226) 12px 12px,
    rgb(226, 220, 226) 13px 13px,
    rgb(226, 220, 226) 14px 14px,
    rgb(226, 220, 226) 15px 15px,
    rgb(226, 220, 226) 16px 16px,
    rgb(226, 220, 226) 17px 17px,
    rgb(226, 220, 226) 18px 18px,
    rgb(226, 220, 226) 19px 19px,
    rgb(226, 220, 226) 20px 20px;
}
.success-box,
.custom-box-success{
	background: #8ddd67;
}
.success-box:before,
.custom-box-success:before{
	content: "\f00c";
	color: #8ddd67;
}
.no-ie .success-box:before,
.no-ie .custom-box-success:before{
	text-shadow: rgb(219, 226, 216) 1px 1px,
    rgb(219, 226, 216) 2px 2px,
    rgb(219, 226, 216) 3px 3px,
    rgb(219, 226, 216) 4px 4px,
    rgb(219, 226, 216) 5px 5px,
    rgb(219, 226, 216) 6px 6px,
    rgb(219, 226, 216) 7px 7px,
    rgb(219, 226, 216) 8px 8px,
    rgb(219, 226, 216) 9px 9px,
    rgb(219, 226, 216) 10px 10px,
    rgb(219, 226, 216) 11px 11px,
    rgb(219, 226, 216) 12px 12px,
    rgb(219, 226, 216) 13px 13px,
    rgb(219, 226, 216) 14px 14px,
    rgb(219, 226, 216) 15px 15px,
    rgb(219, 226, 216) 16px 16px,
    rgb(219, 226, 216) 17px 17px,
    rgb(219, 226, 216) 18px 18px,
    rgb(219, 226, 216) 19px 19px,
    rgb(219, 226, 216) 20px 20px;
}
.arrow-box {
	background: #EFAB41;
}
.arrow-box:before{
	content: "\f061";
	color: #EFAB41;
}
.no-ie .arrow-box:before{
	text-shadow: rgb(228, 221, 212) 1px 1px,
    rgb(228, 221, 212) 2px 2px,
    rgb(228, 221, 212) 3px 3px,
    rgb(228, 221, 212) 4px 4px,
    rgb(228, 221, 212) 5px 5px,
    rgb(228, 221, 212) 6px 6px,
    rgb(228, 221, 212) 7px 7px,
    rgb(228, 221, 212) 8px 8px,
    rgb(228, 221, 212) 9px 9px,
    rgb(228, 221, 212) 10px 10px,
    rgb(228, 221, 212) 11px 11px,
    rgb(228, 221, 212) 12px 12px,
    rgb(228, 221, 212) 13px 13px,
    rgb(228, 221, 212) 14px 14px,
    rgb(228, 221, 212) 15px 15px,
    rgb(228, 221, 212) 16px 16px,
    rgb(228, 221, 212) 17px 17px,
    rgb(228, 221, 212) 18px 18px,
    rgb(228, 221, 212) 19px 19px,
    rgb(228, 221, 212) 20px 20px;
}
.error-box,
.custom-box-error{
	background: #fb7f7f;

}
.error-box:before,
.custom-box-error:before{
	content: "\f00d";
	color: #fb7f7f;
}
.no-ie .error-box:before,
.no-ie .custom-box-error:before{
	text-shadow: rgb(229, 218, 218) 1px 1px,
    rgb(229, 218, 218) 2px 2px,
    rgb(229, 218, 218) 3px 3px,
    rgb(229, 218, 218) 4px 4px,
    rgb(229, 218, 218) 5px 5px,
    rgb(229, 218, 218) 6px 6px,
    rgb(229, 218, 218) 7px 7px,
    rgb(229, 218, 218) 8px 8px,
    rgb(229, 218, 218) 9px 9px,
    rgb(229, 218, 218) 10px 10px,
    rgb(229, 218, 218) 11px 11px,
    rgb(229, 218, 218) 12px 12px,
    rgb(229, 218, 218) 13px 13px,
    rgb(229, 218, 218) 14px 14px,
    rgb(229, 218, 218) 15px 15px,
    rgb(229, 218, 218) 16px 16px,
    rgb(229, 218, 218) 17px 17px,
    rgb(229, 218, 218) 18px 18px,
    rgb(229, 218, 218) 19px 19px,
    rgb(229, 218, 218) 20px 20px;
}
.alert-box:before{
	content: "\f071";
	color: #FFC600;
}
.no-ie .alert-box:before{
	text-shadow: rgb(239, 234, 216) 1px 1px,
    rgb(239, 234, 216) 2px 2px,
    rgb(239, 234, 216) 3px 3px,
    rgb(239, 234, 216) 4px 4px,
    rgb(239, 234, 216) 5px 5px,
    rgb(239, 234, 216) 6px 6px,
    rgb(239, 234, 216) 7px 7px,
    rgb(239, 234, 216) 8px 8px,
    rgb(239, 234, 216) 9px 9px,
    rgb(239, 234, 216) 10px 10px,
    rgb(239, 234, 216) 11px 11px,
    rgb(239, 234, 216) 12px 12px,
    rgb(239, 234, 216) 13px 13px,
    rgb(239, 234, 216) 14px 14px,
    rgb(239, 234, 216) 15px 15px,
    rgb(239, 234, 216) 16px 16px,
    rgb(239, 234, 216) 17px 17px,
    rgb(239, 234, 216) 18px 18px,
    rgb(239, 234, 216) 19px 19px,
    rgb(239, 234, 216) 20px 20px;
}
.alert-box{
	background: #FFC600;
}
.warning-box,
.custom-box-warning{
	background: #ffdb5e;
}
.warning-box:before,
.custom-box-warning:before{
	content: "\f12a";
	color: #F4D159;
}
.no-ie .warning-box:before,
.no-ie .custom-box-warning:before{
	text-shadow: rgb(241, 238, 227) 1px 1px,
    rgb(241, 238, 227) 2px 2px,
    rgb(241, 238, 227) 3px 3px,
    rgb(241, 238, 227) 4px 4px,
    rgb(241, 238, 227) 5px 5px,
    rgb(241, 238, 227) 6px 6px,
    rgb(241, 238, 227) 7px 7px,
    rgb(241, 238, 227) 8px 8px,
    rgb(241, 238, 227) 9px 9px,
    rgb(241, 238, 227) 10px 10px,
    rgb(241, 238, 227) 11px 11px,
    rgb(241, 238, 227) 12px 12px,
    rgb(241, 238, 227) 13px 13px,
    rgb(241, 238, 227) 14px 14px,
    rgb(241, 238, 227) 15px 15px,
    rgb(241, 238, 227) 16px 16px,
    rgb(241, 238, 227) 17px 17px,
    rgb(241, 238, 227) 18px 18px,
    rgb(241, 238, 227) 19px 19px,
    rgb(241, 238, 227) 20px 20px;
}
.notice-box {
	background: #8fc0ea;
}
.notice-box:before{
	content: "\f12a";
	color: #8fc0ea;
}

.no-ie .notice-box:before{
	text-shadow: rgb(219, 223, 227) 1px 1px,
    rgb(219, 223, 227) 2px 2px,
    rgb(219, 223, 227) 3px 3px,
    rgb(219, 223, 227) 4px 4px,
    rgb(219, 223, 227) 5px 5px,
    rgb(219, 223, 227) 6px 6px,
    rgb(219, 223, 227) 7px 7px,
    rgb(219, 223, 227) 8px 8px,
    rgb(219, 223, 227) 9px 9px,
    rgb(219, 223, 227) 10px 10px,
    rgb(219, 223, 227) 11px 11px,
    rgb(219, 223, 227) 12px 12px,
    rgb(219, 223, 227) 13px 13px,
    rgb(219, 223, 227) 14px 14px,
    rgb(219, 223, 227) 15px 15px,
    rgb(219, 223, 227) 16px 16px,
    rgb(219, 223, 227) 17px 17px,
    rgb(219, 223, 227) 18px 18px,
    rgb(219, 223, 227) 19px 19px,
    rgb(219, 223, 227) 20px 20px;
}

/* - Custom Box Styles -> End --------------------------------------- */

/* - Notifications Box Styles -> Begin ------------------------------ */

 .close-box {
	position: absolute;
	right: 7px;
	top: calc(50% - 15px);
	width: 30px;
	height: 30px;
	display: block;
	cursor: pointer;
	font-size: 1.2em;
	line-height: 30px;
	text-align: center;
}
.close-box:before{
	content: "\f00d";
	color:#fff;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.custom-box-inform {
	background: #8fc0ea;
}
.custom-box-inform:before{
	content: "\f129";
	color: #8fc0ea;
}
.no-ie .custom-box-inform:before{
	text-shadow: rgb(219, 223, 227) 1px 1px,
    rgb(219, 223, 227) 2px 2px,
    rgb(219, 223, 227) 3px 3px,
    rgb(219, 223, 227) 4px 4px,
    rgb(219, 223, 227) 5px 5px,
    rgb(219, 223, 227) 6px 6px,
    rgb(219, 223, 227) 7px 7px,
    rgb(219, 223, 227) 8px 8px,
    rgb(219, 223, 227) 9px 9px,
    rgb(219, 223, 227) 10px 10px,
    rgb(219, 223, 227) 11px 11px,
    rgb(219, 223, 227) 12px 12px,
    rgb(219, 223, 227) 13px 13px,
    rgb(219, 223, 227) 14px 14px,
    rgb(219, 223, 227) 15px 15px,
    rgb(219, 223, 227) 16px 16px,
    rgb(219, 223, 227) 17px 17px,
    rgb(219, 223, 227) 18px 18px,
    rgb(219, 223, 227) 19px 19px,
    rgb(219, 223, 227) 20px 20px;
}

.custom-box-edit {
	background:#E0CCA2;
}
.custom-box-edit:before{
	content: "\f040";
	color: #E0CCA2;
    font-size: 26px;
}

.no-ie .custom-box-edit:before{
	text-shadow: rgb(238, 237, 233) 1px 1px,
    rgb(238, 237, 233) 2px 2px,
    rgb(238, 237, 233) 3px 3px,
    rgb(238, 237, 233) 4px 4px,
    rgb(238, 237, 233) 5px 5px,
    rgb(238, 237, 233) 6px 6px,
    rgb(238, 237, 233) 7px 7px,
    rgb(238, 237, 233) 8px 8px,
    rgb(238, 237, 233) 9px 9px,
    rgb(238, 237, 233) 10px 10px,
    rgb(238, 237, 233) 11px 11px,
    rgb(238, 237, 233) 12px 12px,
    rgb(238, 237, 233) 13px 13px,
    rgb(238, 237, 233) 14px 14px,
    rgb(238, 237, 233) 15px 15px,
    rgb(238, 237, 233) 16px 16px,
    rgb(238, 237, 233) 17px 17px,
    rgb(238, 237, 233) 18px 18px,
    rgb(238, 237, 233) 19px 19px,
    rgb(238, 237, 233) 20px 20px;
}
.custom-box-members {
	background:#88DCF5;
}
.custom-box-members:before{
	color: #88DCF5;
	content: "\f13e";
}
.no-ie .custom-box-members:before{
	text-shadow: rgb(219, 226, 228) 1px 1px,
    rgb(219, 226, 228) 2px 2px,
    rgb(219, 226, 228) 3px 3px,
    rgb(219, 226, 228) 4px 4px,
    rgb(219, 226, 228) 5px 5px,
    rgb(219, 226, 228) 6px 6px,
    rgb(219, 226, 228) 7px 7px,
    rgb(219, 226, 228) 8px 8px,
    rgb(219, 226, 228) 9px 9px,
    rgb(219, 226, 228) 10px 10px,
    rgb(219, 226, 228) 11px 11px,
    rgb(219, 226, 228) 12px 12px,
    rgb(219, 226, 228) 13px 13px,
    rgb(219, 226, 228) 14px 14px,
    rgb(219, 226, 228) 15px 15px,
    rgb(219, 226, 228) 16px 16px,
    rgb(219, 226, 228) 17px 17px,
    rgb(219, 226, 228) 18px 18px,
    rgb(219, 226, 228) 19px 19px,
    rgb(219, 226, 228) 20px 20px;
}
.custom-box-tip {
	background: #47B3B3;
}
.custom-box-tip:before{
	content: "\f0eb";
	color: #47B3B3;
}
.no-ie .custom-box-tip:before{
	text-shadow: rgb(212, 222, 222) 1px 1px,
    rgb(212, 222, 222) 2px 2px,
    rgb(212, 222, 222) 3px 3px,
    rgb(212, 222, 222) 4px 4px,
    rgb(212, 222, 222) 5px 5px,
    rgb(212, 222, 222) 6px 6px,
    rgb(212, 222, 222) 7px 7px,
    rgb(212, 222, 222) 8px 8px,
    rgb(212, 222, 222) 9px 9px,
    rgb(212, 222, 222) 10px 10px,
    rgb(212, 222, 222) 11px 11px,
    rgb(212, 222, 222) 12px 12px,
    rgb(212, 222, 222) 13px 13px,
    rgb(212, 222, 222) 14px 14px,
    rgb(212, 222, 222) 15px 15px,
    rgb(212, 222, 222) 16px 16px,
    rgb(212, 222, 222) 17px 17px,
    rgb(212, 222, 222) 18px 18px,
    rgb(212, 222, 222) 19px 19px,
    rgb(212, 222, 222) 20px 20px;
}
.custom-box-download {
	background: #86A9FA;
}
.custom-box-download:before{
	content: "\f019";
	color: #86A9FA;
	font-size: 22px;
}
.no-ie .custom-box-download:before{
	text-shadow: rgb(219, 221, 229) 1px 1px,
    rgb(219, 221, 229) 2px 2px,
    rgb(219, 221, 229) 3px 3px,
    rgb(219, 221, 229) 4px 4px,
    rgb(219, 221, 229) 5px 5px,
    rgb(219, 221, 229) 6px 6px,
    rgb(219, 221, 229) 7px 7px,
    rgb(219, 221, 229) 8px 8px,
    rgb(219, 221, 229) 9px 9px,
    rgb(219, 221, 229) 10px 10px,
    rgb(219, 221, 229) 11px 11px,
    rgb(219, 221, 229) 12px 12px,
    rgb(219, 221, 229) 13px 13px,
    rgb(219, 221, 229) 14px 14px,
    rgb(219, 221, 229) 15px 15px,
    rgb(219, 221, 229) 16px 16px,
    rgb(219, 221, 229) 17px 17px,
    rgb(219, 221, 229) 18px 18px,
    rgb(219, 221, 229) 19px 19px,
    rgb(219, 221, 229) 20px 20px;
}
.custom-box-hello {
	background: #27ae60;
}
.custom-box-hello:before{
	content: "\f0e0";
	color: #27ae60;
	font-size: 20px;
}
.no-ie .custom-box-hello:before{
	text-shadow: rgb(210, 221, 215) 1px 1px,
    rgb(210, 221, 215) 2px 2px,
    rgb(210, 221, 215) 3px 3px,
    rgb(210, 221, 215) 4px 4px,
    rgb(210, 221, 215) 5px 5px,
    rgb(210, 221, 215) 6px 6px,
    rgb(210, 221, 215) 7px 7px,
    rgb(210, 221, 215) 8px 8px,
    rgb(210, 221, 215) 9px 9px,
    rgb(210, 221, 215) 10px 10px,
    rgb(210, 221, 215) 11px 11px,
    rgb(210, 221, 215) 12px 12px,
    rgb(210, 221, 215) 13px 13px,
    rgb(210, 221, 215) 14px 14px,
    rgb(210, 221, 215) 15px 15px,
    rgb(210, 221, 215) 16px 16px,
    rgb(210, 221, 215) 17px 17px,
    rgb(210, 221, 215) 18px 18px,
    rgb(210, 221, 215) 19px 19px,
    rgb(210, 221, 215) 20px 20px;
}

/* - Notifications Box Styles -> End -------------------------------- */

/* soc_icon */
.soc_icon.fa{
	width: 2em;
	line-height: 2em;
	text-align: center;
	color: #fff;
	overflow: hidden;
	position: relative;
	z-index: 1;
	border-radius: 0.33em;
	-webkit-transition: all .25s linear;
	-moz-transition: all .25s linear;
	-ms-transition: all .25s linear;
	-o-transition: all .25s linear;
	transition: all .25s linear;
	-webkit-border-radius: 0.33em;
	-moz-border-radius: 0.33em;
	-ms-border-radius: 0.33em;
}
.soc_icon.fa:after{
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border-bottom: 4px solid rgba(0,0,0,0.12);
	border-radius: 0.25em;
}


/* Progress bar */

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 40px 0
    }

    to {
        background-position: 0 0
    }
}

.single_bar{
	font-weight: 700;
	margin-bottom: 15px;
}
.single_bar .scale{
	position: relative;
	width: 100%;
	height: 28px;
	display: inline-block;
	vertical-align: middle;
	background: rgba(0,0,0,.07);
	border-radius: 4px;
	overflow: hidden;
}
.single_bar .title{
	font-size: 1.2em;
}
.single_bar .progress{
	position: absolute;
	height: 100%;
	background-image: -webkit-gradient(linear,0 100%,100% 0,color-stop(0.25,rgba(255,255,255,.15)),color-stop(0.25,transparent),color-stop(0.5,transparent),color-stop(0.5,rgba(255,255,255,.15)),color-stop(0.75,rgba(255,255,255,.15)),color-stop(0.75,transparent),to(transparent));
	background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-image: -moz-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-image: -o-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
	background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,rgba(0,0,0,0) 25%,rgba(0,0,0,0) 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,rgba(0,0,0,0) 75%,rgba(0,0,0,0));
	background-size: 40px 40px;
	-webkit-animation: progress-bar-stripes 2s linear infinite;
	-o-animation: progress-bar-stripes 2s linear infinite;
	animation: progress-bar-stripes 2s linear infinite;
	box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
}
.single_bar .indicator{
	float: right;
	padding-left: 5px;
	padding-right: 5px;
	line-height: 28px;
	color: #fff;
	font-weight: 400;
	font-size: 20px;
}

/* - List Styles -> Begin ------------------------------------------- */
.map-container{
	border:10px solid;
	border-radius: 10px;
	max-width: 100%;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.map-container .google-map{
	height: 450px;
	overflow: hidden;
}


.list {
    list-style: none outside none;
    margin: 0;
    padding:0;
}

.list li {
	margin-bottom:.8em;
}


ul.type-1 li:before{
	content: "\f054";
	width: auto;
	height: auto;
	line-height: 1;
	padding: 0.3em 0.4em 0.4em 0.6em;
}

ul.type-2 li:before{
	content: "\f061";
	font-size: 0.8em;
	border-radius: 0em;
	background: transparent;
	height: auto;
	width: auto;
	line-height: 1;
}

/* - List Styles -> End --------------------------------------------- */

/* - Tables -> Begin ------------------------------------------ */

.custom-table,
.custom-table2 {
    margin: 0 0 1.5em 0;
    padding: 0;
    width: 100%;
	background:#fff;
	font-size:13px;
	border: 1px solid #97bddb;
	border-collapse: inherit;
	-moz-border-radius:8px;
	-webkit-border-radius:8px;
	border-radius:8px;
}

.custom-table2 {
	background:#eaf7fb;
}

.custom-table thead th {
	color:#fff;
}

.custom-table thead th,
.custom-table2 thead th {
	padding:8px 10px;
	text-align:center;
	font-size:18px;
	background:#b1d0e5;
}

.custom-table2 thead th {
	font-size:13px;
	font-weight:700;
	border-right: 1px solid #9bc0dd;
}

.custom-table2 thead th:first-of-type {
	-webkit-border-radius: 7px 0 0 0;
		-moz-border-radius: 7px 0 0 0;
			border-radius: 7px 0 0 0;
}

.custom-table2 thead th:last-of-type {
	-webkit-border-radius: 0 7px 0 0;
		-moz-border-radius: 0 7px 0 0;
			border-radius: 0 7px 0 0;
}

.custom-table .radius-left,
.custom-table2 .radius-left {
	-moz-border-radius:7px 0 0 0;
	-webkit-border-radius:7px 0 0 0;
	border-radius:7px 0 0 0;
}

.custom-table .radius-right,
.custom-table2 .radius-right {
	-moz-border-radius:0 7px 0 0;
	-webkit-border-radius:0 7px 0 0;
	border-radius:0 7px 0 0;
}

.custom-table tbody td,
.custom-table2 tbody td {
	border-top: 1px solid #97bddb;
    border-right: 1px solid #97bddb;
	padding:14px 10px;
	text-align:center;
}

.custom-table2 tbody td {
	text-align:center;
	font-size:13px;
	padding:6px 10px;
}

.custom-table2 tbody td:last-of-type {border-right: 0;}

.custom-table tbody td.noborder,
.custom-table2 tbody td.noborder {
	border-right:none;
}

/* ---- Pricing Tables ----- */
.pricing_table,
.grid-row.eq-columns{
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	/**/
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-moz-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	/**/
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
/*** FOR IE 9 */
.pricing_table,
.grid-row.eq-columns{
	display: block\9;
}
.pricing_table .pricing_table_column,
.grid-row.eq-columns .grid-col{
	float: left\9;
}
.pricing_table:after,
.grid-row.eq-columns:after{
	display: none;
}
.pricing_table:after,
.grid-row.eq-columns:after{
	content: "";
	display: block\9;
	clear: both\9;
}
/*** \FOR IE 9 */
.grid-row.eq-columns .pricing_table_column + .grid-col:not(.pricing_table_column){
	padding-left: 2.5%;
}
.grid-row.eq-columns .grid-col:not(.pricing_table_column){
	padding-right: 2.5%;
}
.grid-row.eq-columns .grid-col:not(.pricing_table_column){
	padding-top: 30px;
	padding-bottom: 30px;
}
.pricing_table.col-4 .pricing_table_column,
.grid-row.eq-columns .grid-col-3{
	width: 25%;
}
.pricing_table.col-3 .pricing_table_column,
.grid-row.eq-columns .grid-col-4{
	width: 33.333%;
}
.pricing_table.col-2 .pricing_table_column,
.page-content.double-sidebar .pricing_table.col-4 .pricing_table_column,
.grid-row.eq-columns .grid-col-6,
.double-sidebar .grid-row.eq-columns .grid-col-3,
.double-sidebar .grid-row.eq-columns .grid-col-4,
.double-sidebar .grid-row.eq-columns .grid-col-8,
.double-sidebar .grid-row.eq-columns .grid-col-9{
	width: 50%;
}
.grid-row.eq-columns .grid-col + .grid-col{
	margin-left: 0;
}
.pricing_table_column{
	position: relative;
	padding-bottom: 60px;
	background: #fff;
	text-align: center;
	border: 2px solid;
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;
}
.pricing_table_column.active_table_column .active-ribbon{
	position: absolute;
	top: -3px;
	left: -3px;
	width: 90px;
	height: 90px;
	overflow: hidden;
}
.pricing_table_column.active_table_column .active-ribbon:before{
	content: "best offer";
	position: absolute;
	padding: 0.25em 2em;
	top: 0;
	left: 0;
	color: #fff;
	background: #ff633c;
	text-transform: uppercase;
	font-size: 12px;
	-webkit-transform: rotate(-45deg) translate(-63px , -29px);
	-moz-transform: rotate(-45deg) translate(-63px , -29px);
	-ms-transform: rotate(-45deg) translate(-63px , -29px);
	transform: rotate(-45deg) translate(-63px , -29px);
	width: 150px;
}

.pricing_table_column{
	border-radius: 9px;
	margin-bottom: 30px;
}
.pricing_table_column>div>*:first-child{
	-webkit-border-top-left-radius: 6px;
	-webkit-border-top-right-radius: 6px;
	-moz-border-radius-topleft: 6px;
	-moz-border-radius-topright: 6px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}
.pricing_table_column>div>*:last-child{
	-webkit-border-bottom-right-radius: 6px;
	-webkit-border-bottom-left-radius: 6px;
	-moz-border-radius-bottomright: 6px;
	-moz-border-radius-bottomleft: 6px;
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
}
.pricing_table_column + .pricing_table_column{
	margin-left: 2px!important;
	width: calc(25% - 2px) !important;
}
.pricing_table_column + .pricing_table_column.grid-col-4{
	margin-left: 2px!important;
	width: calc(33.33% - 2px) !important;
}
.pricing_table_column + .pricing_table_column.grid-col-6,
.double-sidebar .grid-row.eq-columns .pricing_table_column + .pricing_table_column.grid-col-3,
.double-sidebar .grid-row.eq-columns .pricing_table_column + .pricing_table_column.grid-col-4{
	width: calc(50% - 2px) !important;
}

.pricing_table_header{
	padding: 12px 0 6px;
	font-size: 0.929em;
	line-height: 1.385em;
	text-align: center;
}
.pricing_table_header .title{
	display: block;
	font-size: 1.923em;
	font-weight: 700;
	line-height: 1em;
}
.pricing_table_header .encouragement{
	padding: 0.25em 0;
}
.pricing_table_column .price_part{
	padding: 0 0 20px;
	font-size: 1.86em;
	line-height: 1em;
	text-align: center;
	font-weight: 700;
}
.pricing_table_column .price_container{
	display: inline-block;
	padding: 3px 18px;
	background: #fff;
	border-radius: 9px;
}
.pricing_table_column .price_container:after{
	content: "";
	clear: both;
	display: table;
}
.pricing_table_column .price_container .price{
	float: left;
}
.pricing_table_column .currency{
	vertical-align: top;
	font-size: 0.96em;
	line-height: 0.6;
}
.pricing_table_column .currency:after{
	content: "/";
	font-size: 0.8em;
	vertical-align: top;
	padding-top: 0.25em;
	margin-left: -0.15em;
	display: inline-block;
}
.pricing_table_column .price_description{
	margin-top: 5px;
	margin-left: -0.1em;
	font-size: 0.7em;
	line-height: 1em;
	text-align: right;
}
.pricing_table_column .content_part{
	padding: 0 10px 5px;
	position: relative;
	z-index: 1;
	overflow: hidden;
	margin-top: -5px;
}
.pricing_table_column .content_part:before{
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	height: 5px;
	background: #fff;
}
.pricing_table_column .content_part ul,
.pricing_table_column .content_part ol{
	line-height: 1.429em;
	margin-left: 0;
}
.pricing_table_column .content_part ul ul,
.pricing_table_column .content_part ol ol,
.pricing_table_column .content_part ul ol,
.pricing_table_column .content_part ol ul{
	margin-left: 16px;
}
.pricing_table_column .content_part li{
	padding: 10px 0;
	border-bottom: 1px solid #fee6b4;
	font-size: 1.214em;
}
.pricing_table_column .content_part ul>li:last-child{
	border-bottom: 0;
}
.pricing_table_column .content_part ul>li:first-child,
.pricing_table_column .content_part ol>li:first-child{
	margin-top: 0;
}
.page-content main .pricing_table_column .content_part li + li{
	margin-top: 0;
}
.pricing_table_column .content_part li:before{
	display: none;
}
.pricing_table_column .button_part{
	border-style: none;
	width: 100%;
	display: block;
	position: absolute;
	bottom: 0;
	height: 60px;
	line-height: 60px;
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;

	-webkit-border-bottom-right-radius: 9px;
	-webkit-border-bottom-left-radius: 9px;
	-moz-border-radius-bottomright: 9px;
	-moz-border-radius-bottomleft: 9px;
	border-bottom-right-radius: 9px;
	border-bottom-left-radius: 9px;
}
.pricing_table_button:hover{
	color: #fff !important;
}
@media screen and (max-width: 1190px){
	.single-sidebar .grid-row.eq-columns .grid-col-4,
	.single-sidebar .grid-row.eq-columns .grid-col-3{
		width: 50%;
	}
	.single-sidebar .grid-row.eq-columns .pricing_table_column + .grid-col-4,
	.single-sidebar .grid-row.eq-columns .pricing_table_column + .grid-col-3{
		width: calc(50% - 2px) !important;
	}
}
@media screen and (max-width: 980px){
	.pricing_table.col-4 .pricing_table_column,
	.pricing_table.col-3 .pricing_table_column,
	.grid-row.eq-columns .grid-col-3,
	.grid-row.eq-columns .grid-col-4{
		width: 50%;
	}
	.pricing_table_column + .pricing_table_column,
	.pricing_table_column + .pricing_table_column.grid-col-4,
	.single-sidebar .pricing_table_column + .pricing_table.col-4 .pricing_table_column,
	.single-sidebar .grid-row.eq-columns .pricing_table_column + .grid-col-3{
		width: calc(50% - 2px) !important;
	}
	.double-sidebar .grid-row.eq-columns .pricing_table_column.grid-col-3,
	.double-sidebar .grid-row.eq-columns .pricing_table_column.grid-col-4,
	.double-sidebar .grid-row.eq-columns .pricing_table_column.grid-col-6,
	.double-sidebar .grid-row.eq-columns .pricing_table_column.grid-col-8,
	.double-sidebar .grid-row.eq-columns .pricing_table_column.grid-col-9{
		width: 100%;
	}
	.double-sidebar .grid-row.eq-columns .pricing_table_column + .pricing_table_column.grid-col-8,
	.double-sidebar .grid-row.eq-columns .pricing_table_column + .pricing_table_column.grid-col-6,
	.double-sidebar .grid-row.eq-columns .pricing_table_column + .pricing_table_column.grid-col-4,
	.double-sidebar .grid-row.eq-columns .pricing_table_column + .pricing_table_column.grid-col-3{
		width: calc(100% - 2px) !important;
	}
}

@media screen and (max-width: 767px){
	.cws_widget_content .soc_icon.fa{
		margin-bottom: 5px;
	}
	.single-sidebar .grid-row.eq-columns .pricing_table_column + .grid-col-4,
	.single-sidebar .grid-row.eq-columns .pricing_table_column + .grid-col-3,
	.single-sidebar .grid-row.eq-columns .pricing_table_column + .grid-col-6,
	.pricing_table_column + .pricing_table_column,
	.pricing_table_column + .pricing_table_column.grid-col-4,
	.single-sidebar .pricing_table_column + .pricing_table.col-4 .pricing_table_column,
	.single-sidebar .grid-row.eq-columns .pricing_table_column + .grid-col-3
	.pricing_table.col-4 .pricing_table_column,
	.pricing_table.col-3 .pricing_table_column,
	.grid-row.eq-columns .pricing_table_column{
		width: 100% !important;
	}
	.callout_widget .content_wrapper>*{
		display: block;
		margin-bottom: 10px;
	}
	.callout_widget .button_part{
		white-space: normal;
	}
}

/* - Tables-> End --------------------------------------------- */


/* - Elements styling -> Begin------------------------------- */

.highlight {
	text-decoration:blink;
}
.t-deleted {
	text-decoration:line-through;
}

/* - Elements styling-> End --------------------------------- */

/*
##############################################################
PAGES STYLES -> Begin
##############################################################
*/

/* portfolio */
/* Isotop column */
.iso-column .iso-item>*,
.portfolio>.item>*,
.portfolio .post-content>*{
	margin-bottom: 20px;
}
.iso-column .iso-item,
.portfolio>.item{
	margin-bottom: 30px;
	width: 100%;
}
.iso-four-column .grid,
.iso-three-column .grid,
.iso-two-column .grid{
	margin-left: -30px;
}
.grid.isotope{
	z-index: 1;
}
.iso-four-column .iso-item,
.iso-three-column .iso-item,
.iso-two-column .iso-item{
	margin-left: 30px;
}
.iso-four-column .iso-item{
	width: 270px;
}
.iso-three-column .iso-item{
	width: 370px;
}
.iso-two-column .iso-item{
	width: 570px;
}

.single-sidebar .iso-two-column .iso-item{
	width: 420px;
}
.single-sidebar .iso-three-column .iso-item,
.single-sidebar .iso-four-column .iso-item,
.double-sidebar .iso-three-column .iso-item,
.double-sidebar .iso-four-column .iso-item,
.double-sidebar .iso-two-column .iso-item{
	width: 270px;
}


.portfolio .content-wrapper{
	margin-bottom: 5px;
}
.portfolio .title{
	text-align: center;
}
.portfolio .gallery-text{
	overflow: hidden;
}
.portfolio .gallery-text:last-child{
	margin-bottom: 0;
}
.portfolio .post-footer{
	background: transparent;
	padding: 0;
	text-align: right;
	margin-bottom: 0;
}
.portfolio .kids_clear{
	margin: 5;
}
.portfolio .filter{
	padding: 6px;
	border: 1px solid;
	font-size: 1em;
	float: right;
	margin-bottom: 20px;
	background: transparent !important;
}
.filter-wrapper:after{
	content: "";
	clear: both;
	display: table;
}

.portfolio .post-item{
	padding-left: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	border-radius: 0;
}
.portfolio .post-item>.post-entry{
	background: transparent !important;
	padding: 0;
	min-height: 100%;
}
.portfolio .post-item>.post-entry .content-wrapper {
	margin-bottom: 10px;
}
body.single .portfolio .post-item>.post-entry>div>*{
	margin-right: 15px;
}
body.single .portfolio .post-item>.post-entry>div{
	margin-right: -15px;
}
body.single .portfolio .post-item>.post-entry .content-wrapper {
	float: left;
}
.owl-item>li:before{
	display: none;
}
.shortcode_carousel{
	margin-bottom: 30px;
	overflow: hidden;
	position: relative;
	z-index: 1;
}
.shortcode_carousel .carousel_content{
	padding-top: 48px;
}
.footer .shortcode_carousel .carousel_content,
.top-panel .shortcode_carousel .carousel_content{
	padding-top: 40px;
}
.shortcode_carousel .carousel_header + .carousel_content{
	padding-top: 0;
}


@media screen and (max-width: 1190px){
	.iso-four-column .grid,
	.iso-three-column .grid,
	.iso-two-column .grid {
		margin-left: -20px;
	}
	.iso-four-column .iso-item{
		width: 220px;
		margin-left: 20px;
	}
	.iso-three-column .iso-item{
		width: 300px;
		margin-left: 20px;
	}
	.iso-two-column .iso-item{
		width: 460px;
		margin-left: 20px;
	}

	.single-sidebar .iso-two-column .iso-item{
		width: 340px;
	}
	.double-sidebar .iso-three-column .iso-item,
	.double-sidebar .iso-four-column .iso-item,
	.double-sidebar .iso-two-column .iso-item,
	.single-sidebar .iso-three-column .iso-item,
	.single-sidebar .iso-four-column .iso-item{
		width: 220px;
	}
}
@media screen and (max-width: 980px){
	.iso-four-column .grid,
	.iso-three-column .grid,
	.iso-two-column .grid {
		margin-left: -18px;
	}
	.iso-four-column .iso-item,
	.iso-three-column .iso-item{
		width: 230px;
		margin-left: 18px;
	}
	.iso-two-column .iso-item{
		width: 360px;
		margin-left: 18px;
	}

	.single-sidebar .iso-two-column .iso-item,
	.single-sidebar .iso-three-column .iso-item,
	.single-sidebar .iso-four-column .iso-item{
		width: 265px;
	}

	.double-sidebar .iso-three-column .iso-item,
	.double-sidebar .iso-four-column .iso-item,
	.double-sidebar .iso-two-column .iso-item{
		width: 171px;
	}
}
@media screen and (max-width: 767px){
	.iso-four-column .grid,
	.iso-three-column .grid,
	.iso-two-column .grid{
		margin-left: 0;
	}
	.iso-four-column .iso-item,
	.iso-three-column .iso-item,
	.iso-two-column .iso-item,
	.double-sidebar .iso-three-column .iso-item,
	.double-sidebar .iso-four-column .iso-item,
	.double-sidebar .iso-two-column .iso-item,
	.single-sidebar .iso-two-column .iso-item,
	.single-sidebar .iso-three-column .iso-item,
	.single-sidebar .iso-four-column .iso-item{
		width: 100%;
		margin-left: 0;
	}
}

/* ----- Blog Page  --> Begin ----- */

.content-container {
	width: 980px;
}

aside{
	width: 270px;
	float: left;
	margin: 0;
	min-height: 1px;
}
.blog .post-item .content-wrapper{
	margin-bottom: 20px;
}
.blog .post-item .content-wrapper figure{
	border: 0;
	overflow: hidden;
	max-width: 100%;
	-webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
	-webkit-transform: rotate(0.000001deg);
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
}
.blog .post-item .content-wrapper figure>iframe{
	max-width: 100%;
	width: inherit !important;
	height: inherit !important;
	display: block;
}
.single-sidebar main{
	float: left;
	width: 870px;
	overflow: hidden;
}

aside#sidebar-left{
	margin-right: 30px;
}

aside#sidebar-right{
	margin-left: 30px;
}

.single-sidebar aside{
	width: 270px;
	float: left;
	margin: 0;
}

.double-sidebar main {
	width: 570px;
	float: left;
}

.double-sidebar #sidebar-left{
	margin-right: 30px;
}

.double-sidebar #sidebar-right{
	margin-left: 30px;
}

.content-container .item-post {
	margin-bottom: 1em;
	padding: 40px 40px 20px 34px;
	min-height: 362px;
	/*background: url(../images/area-holder.png) no-repeat;*/
}

.content-container.details .item-post {
	margin-bottom: 0;
	padding-bottom: 0;
}

.post-item {
	width: 100%;
	-moz-border-radius:8px;
	-webkit-border-radius:8px;
	border-radius:8px;
	position:relative;
	margin-bottom: 50px;
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;
}
.post-item .post-meta ~*{
	margin-left: 70px;
}
body.single .post-item .post-entry>div{
	margin-right: -0.9em;
}
body.single .post-item .post-entry>div>*{
	margin-right: 0.9em;
}

#dsb .post-item {
	margin-left: 0;
	width: auto;
}

.post-entry {
	padding:1.75em 19px;
	min-height: 100px;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	border-radius: 9px;
}

.post-entry:after{
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
	font-size: 0;
}

.content-container .post-title {
	padding-left: 20px;
}

.content-container .post-title h2 {
	margin-bottom: .5em;
}

.content-container .post-title a {
	color: #385EA7;
	-webkit-transition: color .25s linear;
	-moz-transition: color .25s linear;
	-ms-transition: color .25s linear;
	-o-transition: color .25s linear;
	transition: color .25s linear;
}

.content-container .post-title a:hover {color: #2f2f2f;}

.content-container .post-entry {
	padding: 0 0 2em 0;
}

.content-container.details .post-entry {
	padding-bottom: 1em;
}

.content-container .entry {
	text-align: justify;
}
.post-title a,
.portfolio .title,
.recent_projects .title{
	font-size: 1.6875em;
	font-weight: 700;
	color: inherit;
}

.post-item .post-thumb, .post-item .entry-thumbnail {
	-moz-border-radius:9px;
	-webkit-border-radius:9px;
	border-radius: 9px;
    padding: 9px;
	position:relative;
	float:left;
	margin-right:1em;
	margin-bottom: 1em;
}

.post-item .entry-thumbnail {
	float: none;
	margin: 0 0 2em 0;
}

#dsb .entry {
	display: block;
}

.content-container .metadata {
	position: relative;
	z-index: 2;
	left: -27px;
	margin-bottom: 2em;
	height: 40px;
}

.content-container .metaholder {
	position: relative;
	left: -5px;
	float:left;
	padding: 0 18px 0 12px;
	height: 32px;
	-webkit-border-radius: 0 8px 8px 0;
	-moz-border-radius: 0 8px 8px 0;
	border-radius: 0 8px 8px 0;
	/*background: url(../images/bg_metaholder.png) no-repeat;*/
	line-height: 32px;
	margin-bottom: 2em;
}

.content-container .metaholder > span {font-size: 1.167em;}
.content-container .metaholder > span a,
.content-container .metaholder span span {color: #385ea7;}
.content-container .metaholder a:hover {text-decoration: underline;}
.content-container .metadata .post-date {
	float: left;
	width: 120px;
	height: 40px;
	/*background: url(../images/bg_metadata_date.png) no-repeat left;*/
	color: #000;
	text-align: center;
	font-size: 1.167em;
	line-height: 2.3em;
}

.post-meta .post-date {
	text-align: center;
	padding: 8px 0 11px;
	position: relative;
	z-index: 2;
}

.post-meta .post-date:before{
 content: "";
 position: absolute;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 z-index: -1;
 -webkit-border-top-left-radius: 5px;
 -webkit-border-bottom-left-radius: 5px;
 -moz-border-radius-topleft: 5px;
 -moz-border-radius-bottomleft: 5px;
 border-top-left-radius: 5px;
 border-bottom-left-radius: 5px;
}

.post-meta .post-date:after{
 content: "";
 position: absolute;
 bottom: -4px;
 left: 5px;
 right: 0;
 height: 20px;
 -webkit-transform: skewY(6deg);
 -moz-transform: skewY(6deg);
 -ms-transform: skewY(6deg);
 transform: skewY(6deg);
 background: rgba(0,0,0,0.15);
 z-index: -2;
}

.post-meta .post-date .day {
	font-size: 32px;
	line-height: 1;
	color: #fff;
	display: block;
}

.post-meta .post-date .month {
	font-size: 14px;
	color: #fff;
	display: block;
	line-height: 1;
}

.post-meta .post-comments {
	font-size: 13px;
	margin-top: -5px;
	padding: 12px 0 7px;
	text-align: center;
	line-height: 1;
	background: #fee6b4;
	position: relative;
	-moz-border-radius-bottomleft: 5px;
	-webkit-border-bottom-left-radius: 5px;
	border-bottom-left-radius: 5px;
	z-index: 1;
}

.post-meta .post-comments a {
	color: inherit !important;
	font-style: normal !important;
	text-transform: lowercase;
}
.post-meta .post-comments a span{
	display: block;
}


.post-footer {
	padding: 7px 20px;
	margin-top: -1.25em;
	text-align: left;
	-webkit-border-bottom-right-radius: 9px;
	-webkit-border-bottom-left-radius: 9px;
	-moz-border-radius-bottomright: 9px;
	-moz-border-radius-bottomleft: 9px;
	border-bottom-right-radius: 9px;
	border-bottom-left-radius: 9px;
}

.post-footer .more-link{
	margin: 8px 0;
}

.post-footer p {
	margin: 0;
	line-height: inherit;
}

.post-footer .author-info{
	width: calc(50% - 30px);
	margin-left: 30px;
}
.post-footer .author-info .avatar{
	width: auto;
	height: auto;
	max-height: 47px;
	max-width: 47px;
	border: 0;
}
.post-footer .author-info .author{
	font-weight: 700;
	line-height: 1em;
}

.post_cats,
.post_tags {
	line-height: 1.5;
}

.post_cats span,
.post_tags span {
	font-weight: 700;
	line-height: inherit;
	white-space: nowrap;
}
.post_cats span:before,
.post_tags span:before{
	content: "\f054";
	font-size: 0.7em;
	padding-right: 5px;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 767px){
	.post-meta{
		width: 50px;
	}
	.post-item .post-meta ~*{
		margin-left:50px;
	}
	.post-meta .post-date .day{
		font-size: 22px;
	}
	.post-meta .post-date .month{
		font-size: 11px;
	}
	.post-meta .post-comments{
		font-size: 10px;
	}
	.post-meta .post-comments:after{
		top: -13px;
	}

	.post-footer .author-info{
		width: 100%;
		margin-left: 0;
		margin-bottom: 7px;
	}
}
/* ---- Comment list ---- */

.content-container #respond_block {
	margin: 0 40px 0 35px;
}
#respond_block .comments_q{
	color: inherit;
}
#respond_block .comments_q>*{
	margin-bottom: 10px;
	color: inherit;
}

#respond_block .add-comment>h1{
	margin-bottom: 28px;
}

.content-container #respond_block h3 {
	position: relative;
	display: inline-block;
	padding: .38em 1.2em;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	background: #b1d0e5;
}

.content-container #respond_block h3:after {
	position: absolute;
	bottom: -16px;
	left: 80px;
	width: 23px;
	height: 16px;
	/*background: url(../images/arrow.png) no-repeat;*/
	content: '';
}

.content-container .comment-list {
	margin-bottom: 1.5em;
	padding: 9px 20px 0 20px;
	background: #eaf7fb;
	border: 1px solid #9bc0dd;
	-moz-border-radius:8px;
	-webkit-border-radius:8px;
	border-radius:8px;
}
.entry-container {
	overflow: hidden;
}
.entry-container #respond_block {
	-moz-border-radius:9px;
	-webkit-border-radius:9px;
	border-radius:9px;
	border: 1px solid;
	padding: 20px;
}

.entry-container *+#respond_block{
	margin-top: 60px;
}

.entry-container .comment-list {
	position:relative;
	margin-bottom: 30px;
	z-index: 1;
}

.entry-container .comment-list ol{
	margin-left: 0;
}

.comment-list ol li,
.comment-list ol ul{
	margin:0;
	list-style:none;
}

.entry-container #comments ol > .comment {
	clear:both;
	display:block;
	font-size: inherit;
	border-bottom: 1px solid;
}

.comment-list .comment-body{
	padding: 20px 0;
}
.avatar {
	width: 60px;
	height: 60px;
	border: 3px solid;
	float: left;
	border-radius: 4px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	margin-right: 15px;
}

.footer .avatar,
.top-panel .avatar{
	border-color: rgba(255,255,255,0.5);
}

.avatar img {border: 1px solid #fff;}

.entry-container .comment-text {
	overflow: hidden;
}

.content-container {
	line-height: 1.5em;
}

.content-container .comment-text {
	padding-top: 10px;
}

.comment-date {
	font-family: 'Averia Sans Libre';
	color: #0d0d0e;
	font-style: italic;
	font-size: 0.917em;
}
#respond_block .comment-author{
	font-weight: 700;
	line-height: 1em;
	font-size: 1.2em;
}
#respond_block .comment-author a {
	font-weight: inherit;
}

.entry-container .comment-entry {padding-top: 5px;}
.content-container .comment-entry {padding-top: 15px;}
.entry-container .comment-meta {
	font-size: 0.9em;
	padding-top: 5px;
	line-height: 1;
}

.content-container .comment-meta {
	margin-top: 1.2em;
}
#respond_block .comment-reply-link,
#respond_block .comment-edit-link{
	float: right;
	padding: 0.35em 0.6em 0.35em;
	font-size: 13px;
	position: relative;
	display: inline-block;
	outline: none;
	text-align: center;
	font-weight: 700;
	text-decoration: none !important;
	font-style: normal !important;
	cursor: pointer;
	line-height: 1;
	color: #fff;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}
#respond_block .comment-edit-link{
	margin-right: 5px;
}
.entry-container .children .comment {
	border-top: 1px solid;
	padding-left: 70px;
	font-size: inherit;
	position: relative;
	z-index: 1;
}
.entry-container .children .comment:after{
	content:"\f064";
	display: inline-block;
	font: normal normal normal 14px/1 FontAwesome;
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	left: 25px;
	top: 20px;
	font-size: 20px;
	color: #dad9d9;
	-webkit-transform: rotateX(180deg);
	-moz-transform: rotateX(180deg);
	-ms-transform: rotateX(180deg);
	transform: rotateX(180deg);
}
.entry-container .children .comment:before{
	display: none;
}

.content-container .children .comment {
	margin-bottom: 1.5em;
	padding: 20px 20px 0 20px;
	border: 1px solid #87bed7;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	background-color: #fff;
}

/* ---- Comment form ---- */

.entry-container .comment-form {

}

.content-container .comment-form {
	margin-bottom: 1.5em;
	padding: 20px 20px 0 20px;
	background: #eaf7fb;
	border: 1px solid #9bc0dd;
	-moz-border-radius:8px;
	-webkit-border-radius:8px;
	border-radius:8px;
}

.entry-container .comment-form .row {
	clear:both;
	padding: 3px 0 10px 0;
}

.content-container .comment-form .row {
	padding: 3px 0 10px 0;
}

.content-container .comment-form .row.align-btn-right {
	width: 368px;
}

.content-container .comment-form .row .button {
	margin-bottom: .4em;
	padding: 0.10em .5em 0.20em;
}

.content-container .comment-form .row button[type="reset"] {
	margin-right: 2.2em;
}

.comment-form .row-btn {
	clear: both;
	padding: 15px 0 0 0;
}

.entry-container .comment-form .row label {
	display:block;
	margin-bottom: .5em;
	font-size: 0.93em;
}

.content-container .comment-form .row label {
	display: block;
	margin-bottom: .15em;
}

.comment-form .inputtext {
	width: 100%;
}


.content-container .comment-form .inputtext {
	width: 200px;
	border:1px solid #9dc1de;
	background:#fff;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	font-size: 1.1em;
}

.content-container .comment-form .textarea {
	margin-bottom: 1em;
	padding: 5px 10px;
	width: 348px;
	max-width: 348px;
	min-width: 348px;
	height: 136px;
	min-height: 136px;
	max-height: 350px;
	border:1px solid #9dc1de;
	background:#fff;
	font-size: 1.1em;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

/* ----- Blog Page  --> End ----- */

#search-form {
	display: block !important;
}
#search-form:after{
	content: "";
	display: table;
	clear: both;
}

#search-form .button{
	-webkit-appearance: none;
	position: relative;
	display: inline-block;
	padding: 0.4em 0.6em 0.4em;
	font-size: 18px;
	outline: none;
	text-align: center;
	font-weight: 700;
	text-decoration: none !important;
	font-style: normal !important;
	cursor: pointer;
	line-height: 1;
	color: #fff;
	height: auto;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	float: right;
}

/* ----- WP Specific Fixes ----- */

.widget {
    list-style: none outside none;
}
.kids_bottom_container>div>.wrapper{
	display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    /**/
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
	flex-wrap: wrap;
	/**/
	margin-left: -30px;
	padding-top: 25px;
}
.kids_bottom_container .widget{
	-webkit-box-flex: 1;
	-moz-box-flex: 1;
	-webkit-flex: 1 1 270px;
	-ms-flex: 1 1 270px;
	flex: 1 1 270px;
	min-width: 25%;
	padding-left: 30px;
	box-sizing:border-box;
	-moz-box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-ms-box-sizing:border-box;
}
.widget_recent_comments .tweet_list li {
    margin: 0;
}
.widget_recent_comments .tweet_list li p {
    margin-bottom: 5px;
    overflow: hidden;
}
.footer .widget-title,
.top-panel .widget-title{
    color: #fff !important;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 10px;
    margin-top: 0;
    font-weight: 700;
    text-align: left;
}

.section-title,
.post-content .widget-title,
.entry-container main .widget-title{
	margin-bottom: 0.5em;
}

.section-title,
.post-content .widget-title,
.entry-container aside:not(#sidebar-right):not(#sidebar-left) .widget-title,
.entry-container main .widget-title{
	font-weight: 700;
}

.footer .widget_categories a,
.top-panel .widget_categories a{
	color: #FFFFFF;
}

.footer .widget_categories>ul>li,
.top-panel .widget_categories>ul>li,
.footer .widget_nav_menu ul li,
.top-panel .widget_nav_menu ul li{
	padding-left: 0;
}
.footer .widget_categories>ul>li li,
.top-panel .widget_categories>ul>li li{
	padding-left: 0.714em;
}

.footer .latest-posts-widget .kids_post_content h4 a,
.top-panel .latest-posts-widget .kids_post_content h4 a{
    color: rgba(255,255,255,0.5);
    text-decoration: none;
}

.footer .latest-posts-widget .kids_post_content h4 a:hover,
.top-panel .latest-posts-widget .kids_post_content h4 a:hover{
	color: #fff;
}

.footer .latest-posts-widget ul li,
.top-panel .latest-posts-widget ul li{
	border-top: 1px solid rgba(255,255,255,0.5);
}
.footer .latest-posts-widget ul li:first-child,
.top-panel .latest-posts-widget ul li:first-child{
	border-top: none;
}

.top-panel .widget_cws_nav a,
.footer .widget_cws_nav a {
    color: #FFFFFF;
    font-size: 13px;
}
.
.top-panel .kids_video_wrapper figure {
	height: 100% !important;
}
.widget_tag_cloud a {
	color: inherit;
}
.footer .widget_tag_cloud a,
.top-panel .widget_tag_cloud a {
	color: #FFF;
}
.footer .textwidget a,
.top-panel .textwidget a {
	color: #FFF;
}

.gl_cat ul li:nth-child(2) {
	border-top: 0 none !important;
}
.nav_cat ul li:nth-child(2) {
	border-top: 0 none !important;
}
/*Widget theme skins END*/

.form-submit {
    clear: both;
    margin: 0;
}
.form-submit:after{
	content: "";
	clear: both;
	display: table;
}
#post_comment {
	position: relative;
	display: inline-block;
	padding: 0.4em 0.6em 0.4em;
	font-size: 18px;
	outline: none;
	text-align: center;
	font-weight: 700;
	text-decoration: none !important;
	font-style: normal !important;
	cursor: pointer;
	line-height: 1;
	color: #fff;
	height: auto;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	float: right;
}
.post_format_video {
	height: auto !important;
	width: 100% !important;
}
.post_format_video iframe {
	width: 100% !important;
	display:block;
}

img.border{
	margin-bottom: 10px;
	border: 10px solid;
	border-radius: 9px;
	display: block;
	-webkit-border-radius: 9px;
	-moz-border-radius: 9px;
	-ms-border-radius: 9px;
	border-radius: 9px;
}
img.border.noborder{
	border: 0!important;
}
img.border.noborder+.kids_curtain,
.noborder .kids_curtain{
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
.wp-image-popup{
	max-width: 100%;
	height: auto;
	overflow: hidden;
}
.wp-image-popup{
	position: relative;
	z-index: 1;
}
.wp-image-popup img{
	box-sizing: border-box;
	max-width: 100%;
	height: auto;
	margin: 0;
	float: none;
}
.wp-image-popup .kids_curtain{
	top: 10px;
	bottom: 10px;
	left: 10px;
	right: 10px;
}
.wp-image-popup:hover .kids_curtain{
	opacity: 1;
}
.wp-image-popup .kids_curtain:before{
	content: "\f0c1";
}

.wp-image-popup.prettyPhoto .kids_curtain:before{
	content: "\f002";
}
.alignright>img[class*="wp-image"].alignright{
	margin-left: 0;
}
.alignleft>img[class*="wp-image"].alignright{
	margin-right: 0;
}
.hover-link{
	display: inline-block;
	position: relative;
	z-index: 1;
}
.hover-link .kids_curtain{
	top: 10px;
	bottom: 10px;
	left: 10px;
	right: 10px;
}

.wp-caption-text,
.gallery-caption{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-size: 0.75em;
	font-style: italic;
	line-height: 1.5;
	margin: 2px 0;
}

.cws-widget-content {
	overflow: hidden;
}

.widget .carousel_header .widget_carousel_nav,
.cws-widget .carousel_header .widget_carousel_nav{
	float: right;
	margin-left: 10px;
	position: relative;
	z-index: 2;
}
aside .widget .carousel_header .widget_carousel_nav{
	display: none;
}
.widget .carousel_header .widget_carousel_nav i,
.cws-widget .carousel_header .widget_carousel_nav i{
	float: left;
	font-size: 22px;
	line-height: 0.7;
	margin-top: 0.2em;
	padding: 0 2px;
	cursor: pointer;
}

.widget .carousel_header>.widget-title,
.cws-widget .carousel_header>.widget-title{
	clear: none;
}
aside .widget .textwidget .carousel_header>.widget-title,
aside .cws-widget .textwidget  .carousel_header>.widget-title{
	font-size: 24px;
	margin-bottom: 10px;
}
aside .owl-carousel .owl-buttons{
	top: 0;
}
aside .shortcode_carousel .carousel_content{
	padding-top: 40px;
}
.cws-widget .carousel_header>.widget-title{
	padding-right: 70px;
}

[data-carousel-column="4"]>*:not([class*="owl-"]){
	width: calc(25% - 30px);
	margin-left: 30px !important;
	float: left;
}
[data-carousel-column="3"]>*:not([class*="owl-"]){
	width: calc(33.3% - 30px);
	margin-left: 30px !important;
	float: left;
}
[data-carousel-column="2"]>*:not([class*="owl-"]){
	width: calc(50% - 30px);
	margin-left: 30px !important;
	float: left;
}

.footer .widget_cws_tweets .latest_tweets li a,
.top-panel .widget_cws_tweets .latest_tweets li a{
	font-style: normal !important;
	text-decoration: none;
	color: rgba(255,255,255,0.5);
}
.footer .latest_tweets li a:hover,
.top-panel .latest_tweets li a:hover{
	color: rgba(255,255,255,1);
}
.latest_tweets li {
	border-top: none;
	font-size: 1em;
}
ul.latest_tweets li:before{
	display: none;
}
aside ul.latest_tweets li{
	padding:0;
}
.latest_tweets li{
	font-size: inherit;
	line-height: 1.3em;
	border-top: 1px solid rgba(255,255,255,0.5);
	margin-bottom: 0;
	padding: 1em 0 .9em;
	clear: both;
}
.footer .latest_tweets li,
.top-panel ul.latest_tweets li li{
	margin-bottom: 0 !important;
	border-color: rgba(255,255,255,0.5);
}
aside .latest_tweets li{
	padding-left: 0 !important;
	padding-right: 10px;
	margin: 0 !important;
}
.latest_tweets{
	margin-bottom: 0;
}
.latest_tweets a{
	word-break: break-word;
}
.latest_tweets p{
	margin-bottom: 0;
}
.latest_tweets li:first-child{
	border-top: none;
	padding-top: .6em;
}
.top-panel .latest_tweets li:first-child{
	padding-top: 0.1em;
}
.top-panel .latest_tweets li:last-child{
	padding-bottom: 0;
}
ul.latest_tweets li ul{
	margin-left: 0;
}
ul.latest_tweets li li .icon_frame,
.tweet_list .icon_frame{
	float: left;
	margin-right: 10px;
	line-height: 0;
}
.top-panel ul.latest_tweets li li .icon_frame i{
	font-size: 1em;
}
.top-panel .latest_tweets li>div:not(.icon_frame){
	margin-top: 0;
	margin-left: 40px;
}
.top-panel .latest_tweets .tweet_list,
aside .tweet_list {
	margin-bottom: 0;
}
ul.latest_tweets span.date{
	display: none;
}
.latest_tweets li>div:not(.icon_frame){
	margin-top: -0.3em;
	margin-left: 40px;
}
.latest_tweets li .icon_frame i.fa:before{
	position: relative;
	color: #fff;
	text-align: center;
	left: 0!important;
	font-size: 20px!important;
	float: left;
	line-height: 30px!important;
	width: 30px!important;
	height: auto !important;
	padding: 0!important;
	border-radius: 4px;
	overflow: hidden;
	display: inline-block;
}
.footer .latest_tweets li .icon_frame i.fa:before,
.top-panel .latest_tweets li .icon_frame i.fa:before{
	background: transparent !important;
	color: rgba(255,255,255,0.5);
	text-shadow: none;
	font-size: 30px!important;
}
.top-panel .latest_tweets li .icon_frame i.fa:before{
	font-size: 23px!important;
	line-height: 22px!important;
	width: 25px!important;
}

.footer .widget_cws_tweets ul.latest_tweets li li:before,
.top-panel .widget_cws_tweets ul.latest_tweets li li:before{
	background: rgba(255,255,255,0.5);
}
.widget_cws_tweets li:after{
	content: "";
	clear: both;
	display: table;
}
.widget_cws_tweets ul li:before,
.widget_cws_tweets .tweet_time  {
	display: none;
}
.kids_bottom_container .block_recent_tweets{
	color: #fff;
}
.widget_recent_comments .block_recent_tweets a{
	word-break: break-all;
}
.footer .widget_recent_comments .block_recent_tweets a:hover,
.top-panel .widget_recent_comments .block_recent_tweets a:hover{
	color: #fff;
}
.textwidget {
    padding: 0 10px;
}
.textwidget>*{
	margin-bottom: 0;
}

.kids-footer-copyrights .wrapper>*{
    padding: 15px 0;
}

.gl_cat ul li{
    border-bottom: 1px solid #3069A4;
}

.accordion li:last-child {
    border-radius: 0 0 6px 6px !important;
    overflow: hidden;
}

#kids_middle_container blockquote p {margin: 0 !important; line-height: 1.4em !important;}
.post-entry .more-link {display: none !important;}

#contactForm .error-box {
	background: #FFDAD4 !important;
	padding: 2em;
}
#contactForm button{
	margin: 0px;
}
.message_box.error-box {
	display: none;
	margin-top: 10px;
}
#email_server_responce {
	margin-top: 10px;
}
.kids_top_content_middle.homepage {
	padding: 10px 0;
}

.footer a:hover,
.top-panel a:hover,
.footer a,
.top-panel a{
	color: #fff;
}
.footer .widget_archive a,
.footer .widget_recent_entries a,
.footer .widget_pages a,
.footer .widget_meta a,
.top-panel .widget_archive a,
.top-panel .widget_recent_entries a,
.top-panel .widget_pages a,
.top-panel .widget_meta a{
	color: #fff;
}
.entry-container .pages_w ul > li > a {
    background: url("../images/image.png") no-repeat scroll 0 9px transparent !important;
}

.catappendspan {
	padding: 0;
	position: absolute;
	top: 5px;
	display: block !important;
	right: 4px;
	font-size: 0.9em;
	height: 0.9em;
	width: 0.9em;
	line-height: 1;
	z-index: 1;
}
.catappendspan:before{
	content: "\f054";
	position: absolute;
	top: 0.4em;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition: all 250ms;
	-moz-transition: all 250ms;
	-ms-transition: all 250ms;
	-o-transition: all 250ms;
	transition: all 250ms;
}

.catappendspan.active:before {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.top-panel .catappendspan:before,
.footer .catappendspan:before{
	color: rgba(255,255,255,0.5);
}

aside .widget_cws_benefits .widget-title{
	clear: both;
	text-align: center;
}

@media screen and (max-width: 1190px){
	.double-sidebar main{
		width: 460px;
	}
	.single-sidebar aside,
	.double-sidebar aside{
		width: 220px;
	}

	.single-sidebar main{
		width: 700px;
	}

	aside#sidebar-left,
	.double-sidebar aside#sidebar-left{
		margin-right: 20px;
	}

	aside#sidebar-right,
	.double-sidebar aside#sidebar-right{
		margin-left: 20px;
	}

	.kids_bottom_container .widget{
		-webkit-flex: 1 1 25%;
		-ms-flex: 1 1 25%;
		flex: 1 1 25%;
	}

	aside .widget.type-3 h3.widget-title .cloud-element-1,
	aside .widget.type-3 h3.widget-title .cloud-element-2,
	aside .widget.type-3 h3.widget-title .cloud-element-3{
		width: calc(43% - 30px);
		top: -8px;
	}

}

@media screen and (max-width: 980px){
	.kids_bottom_container .widget{
		-webkit-flex: 1 1 50%;
		-ms-flex: 1 1 50%;
		flex: 1 1 50%;
	}

	.double-sidebar main{
		width: 360px;
	}

	.single-sidebar aside,
	.double-sidebar aside{
		width: 171px;
	}

	.single-sidebar main{
		width: 549px;
	}

	aside#sidebar-left,
	.double-sidebar aside#sidebar-left{
		margin-right: 18px;
	}

	aside#sidebar-right,
	.double-sidebar aside#sidebar-right{
		margin-left: 18px;
	}

	aside .widget.type-3 h3.widget-title .cloud-element-1,
	aside .widget.type-3 h3.widget-title .cloud-element-2,
	aside .widget.type-3 h3.widget-title .cloud-element-3{
		width: calc(46% - 30px);
	}
}
@media screen and (max-width: 767px){
	main,
	.single-sidebar main,
	.double-sidebar main{
		width: 100%;
		margin-left: 0;
	}
	main + aside,
	aside + aside{
		margin-top: 30px !important;
	}
	aside#sidebar-right,
	aside#sidebar-left{
		width: 100%;
	}
	aside{
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.double-sidebar main{
		margin-bottom: 30px;
	}
	aside{
		margin-bottom: 30px;
	}
	aside#sidebar-right{
		margin-bottom: 0;
	}

	.l-page-width{
		width: 100%;
	}

	.kids_bottom_container .widget{
		flex-basis: 100%;
		-webkit-flex: 1 1 100%;
		-ms-flex: 1 1 100%;
		flex: 1 1 100%;
		width: 100% !important;
		display: block;
	}
	aside .widget{
		margin-bottom: 30px;
	}
}
@media screen and ( max-width:479px ){
	.widget_search input[type="text"]{
		max-width: 100% !important;
	}
}

aside .widget.widget_categories>.ui-widget>li:first-child{
	border-top: 0;
}
.widget.widget_categories>.ui-widget>li{
	padding-right: 2.14em;
}


body .entry-container aside .widget .nav_cat>ul>li.active>a{
	color: #fff!important;
}
aside .latest-posts-widget ul li,
aside .widget ul li,
aside .twitter-carousel .column {
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
}
aside .widget .nav_cat ul li{
	padding-left: 0;
	padding-right: 0;
}
aside .widget .nav_cat ul > li a{
	padding-left: 10px;
	padding-right: 10px;
}
aside .widget_recent_comments ul li.recentcomments,
aside .widget_recent_entries ul li,
aside .widget_archive ul li,
aside .widget_pages ul li,
aside .widget_nav_menu ul li,
aside .widget_recent_comments ul li,
aside .widget_meta>ul>li,
aside .widget.woocommerce.widget_product_categories li,
aside .widget.woocommerce.widget_layered_nav li{
	padding-left: calc(2.14em + 10px)
}
.widget_recent_comments ul li.recentcomments:before,
.widget ul li:before,
.widget.woocommerce.widget_product_categories li:before,
.widget.woocommerce.widget_layered_nav li:before{
	left: 10px;
	font-size: 1em;
	height: 1.7em;
	width: 1.7em;
	line-height: 1.7em;
}
aside .widget_categories ul li ul li,
aside .widget_categories ul li ul li a{
	padding-left: 5px;
}

.widget_rss ul li:before{
	display: none;
}

.widget_categories ul ul{
	margin-top: 0.5em;
	margin-left: 0;
	margin-right: -0.64em;
}
.kids_bottom_container .widget_categories .noBackground:after,
.top-panel .widget_categories .noBackground:after {
	background: none !important;
}
.children .catappendspan{
	display: none;
}
.cat-item {
	position: relative;
}

.creaws_contact	#sidebar {
	padding-right: 85px;
}
.creaws_tiny_topwrapp {
	padding: 0 10px;
}

span.search-excerpt {
    background-color: #FFFF42;
    color: #FF0000;
}

.prettyPhoto .kids_curtain {
    z-index:5;
}
.more.link {
	margin-left: 20px;
}
.contactForm label.error {
    color: #FF0000;
    font-weight: normal;
    position: absolute;
    right: 0;
    top: 0;
}

.contactForm .row {
	position: relative;
}

.contact_Form .row input.error, .contact_Form .row textarea.error {
	border-color: #FF99A6;
}

.widget_search input[type="text"] {
    border-radius: 4px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 370px;
    box-sizing:border-box;
}
.widget_calendar caption,
.ui-datepicker-title {
	font-weight: 700;
	margin-bottom: 10px;
	text-align: center;
	font-size: 20px;
	border-radius: 4px;
}
.footer .widget_calendar caption,
.footer .widget_calendar .ui-datepicker-title,
.top-panel .widget_calendar caption{
	background: rgba(255,255,255,0.5);
	color: #fff;
}
.widget_calendar #calendar_wrap {
    border: 1px solid;
    padding: 10px;
    border-radius: 8px;
}
.widget_calendar table {
	width: 100%;
	box-sizing:border-box;
	border: 0;
	margin-bottom: 0;
}
.kids-footer-copyrights .widget_calendar thead{
	background: rgba(0,0,0,0.08);
}

.widget_calendar table th,
.widget_calendar table td{
	text-align: center;
	border: 0;
	padding: 8px 0;
	border-right: 1px solid transparent;
}
.widget_calendar table td#today:before{
	border-radius: 4px;
}
.widget_calendar table tfoot td{
	padding: 0;
	padding-top: 10px;
}
.widget_calendar table td>a{
	position: relative;
	z-index: 1;
	display: inline-block;
	width: 100%;
	height: 100%;
}
aside .widget_calendar table td>a{
	color: inherit;
}
aside .widget_calendar table td>a:hover{
	text-decoration: underline;
}
.widget_calendar table td.ui-datepicker-today>a:before,
.widget_calendar table td#prev>a:before{
	content: "";
	position: absolute;
	top: -8px;
	bottom: -8px;
	left: 0;
	right: 0;
	border-radius: 3px;
	z-index: -1;
}
.widget_calendar table tfoot td>a:before,
.widget_calendar table td#prev>a:before{
	top: 0;
	bottom: 0;
}
.widget_calendar table tfoot td>a{
	padding: 8px 0;
}

.widget_calendar thead th{
	font-weight: 700;
}
.footer .widget_calendar #calendar_wrap,
.top-panel .widget_calendar #calendar_wrap{
	background: rgba(0,0,0,0.08);
	border-color: rgba(255,255,255,0.5);
}
.footer .widget_calendar table tbody tr:nth-child(even),
.top-panel .widget_calendar table tbody tr:nth-child(even){
	background: rgba(0,0,0,0.08);
}
.footer .widget_calendar table td>a:before,
.top-panel .widget_calendar table td>a:before,
.footer .widget_calendar table td#today:before,
.top-panel .widget_calendar table td#today:before{
	background: rgba(255,255,255,0.5);
	color: #fff;
}
.footer .widget_calendar table td#today,
.top-panel .widget_calendar table td#today{
	position: relative;
	z-index: 1;
}
.footer .widget_calendar table td#today:before,
.top-panel .widget_calendar table td#today:before{
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

#kids_middle_container .description_error p {
	line-height: 1.5em !important;
}

.theme_without_slider {
	height: 82px;
}

.camera_next > span, .camera_prev > span{
	background: none !important;
}
.camera_commands {
	display: none !important;
}

.camera_caption > div {
	background-color: #7FACD1 !important;
	opacity: 0.95;
}
/* ----- WP Specific Fixes --> END ----- */

/* owl-carousel */

.owl-wrapper-outer{
	overflow:hidden;
}
.owl-wrapper{
	position:relative;
}
.owl-controls.clickable .owl-pagination{
	text-align: center;
	padding-left: 30px;
}
.owl-controls.clickable .owl-pagination .owl-page{
	width: 10px;
	height: 10px;
	border-radius: 5px;
	border: 1px solid;
	display: inline-block;
	margin-right: 3px;
	position: relative;
	z-index: 1;
}
.owl-controls.clickable .owl-pagination .owl-page.active span{
	position: absolute;
	width: 4px;
	height: 4px;
	top: 3px;
	left: 3px;
	border-radius: 4px;
}
.owl-controls.clickable .owl-pagination .owl-page:last-child{
	margin-right: 0;
}

.owl-item li{
	margin:0 !important;
	display:block !important;
	width:100% !important;
}

.products .owl-item li{
	width:auto !important;
}

.products .owl-item li img{
	width:100% !important;
	height:auto !important;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;
	box-sizing:border-box;
}

.owl-item {
	float:left !important;
	-webkit-box-sizing:border-box !important;
	-moz-box-sizing:border-box !important;
	-ms-box-sizing:border-box !important;
	box-sizing:border-box !important;
	padding:0 0 0 30px !important;
}

.owl-carousel{
	margin-left: -30px !important;
}

.owl-item>div>*{
	margin-bottom: 15px!important;
}

.owl-carousel .owl-buttons{
	position:absolute;
	top:8px;
	right:0;
}
.owl-carousel .owl-buttons>div,
main .cws-widget .carousel_header .widget_carousel_nav i{
	display: block;
	float:left;
	width:30px;
	height:30px;
	line-height:0px;
	text-align: center;
	cursor:pointer;
}
.top-panel .owl-carousel .owl-buttons>div,
.footer .owl-carousel .owl-buttons>div{
	float:left;
	width: auto;
	height: auto;
	font-size: 22px;
	line-height: 0.7;
	padding: 0 2px;
	text-align: center;
	background: transparent;
}
.top-panel .owl-carousel .owl-buttons>div>i,
.footer .owl-carousel .owl-buttons>div>i{
	font-weight: 400;
	font-size: 22px;
	margin-top: -0.17em;
	line-height: 0.7;
}
main .cws-widget .carousel_header .widget_carousel_nav{
	float: right;
}
.owl-carousel .owl-buttons>div>i,
main .cws-widget .carousel_header .widget_carousel_nav>i{
	line-height: 30px;
	font-size: 20px;
	vertical-align:middle;
	font-weight: 700;
}
main .cws-widget .carousel_header .widget_carousel_nav>i{
	margin-left: 2px;
	padding: 0;
}
.owl-carousel .owl-buttons>div.owl-next>i{
	padding-left: 0.15em;
}
.top-panel .owl-carousel .owl-buttons>div.owl-next>i,
.footer .owl-carousel .owl-buttons>div.owl-next>i{
	padding-left: 0;
}

.owl-carousel .owl-buttons>div + div{
	margin-left:1px;
}
.footer .owl-carousel .owl-buttons>div + div,
.top-panel .owl-carousel .owl-buttons>div + div{
	margin-left: 0;
}
@media screen and (max-width: 1190px){
	.owl-carousel{
		margin-left: -20px !important;
	}
	.owl-item {
		padding:0 0 0 20px !important;
	}
}
@media screen and (max-width: 980px){
	.owl-carousel{
		margin-left: -18px !important;
		width: -webkit-calc(100% + 18px) !important;
		width: -moz-calc(100% + 18px) !important;
		width: -ms-calc(100% + 18px) !important;
		width: calc(100% + 18px) !important;
	}
	.owl-item {
		padding:0 0 0 18px !important;
	}
}

/* owl-carousel */

.recent_projects{
	position:relative !important;
	padding-bottom: 30px;
}
.recent_projects .grid.isotope{
	margin-top: 12px;
}
.recent_projects .grid>.iso-item{
	float: left;
}
.recent_projects .projects_carousel{
	padding-top: 48px;
	width: calc(100% + 30px);
	margin-left: -30px;
}
.recent_projects .projects_carousel .iso-item{
	margin-left: 0;
}

.recent_projects .section-title{
	padding-right: 70px;
	margin-bottom: -36px;
}
.recent_projects.iso-column .section-title{
	margin-bottom: 12px;
}
.recent_projects div.iso-item>*:not(.post-item){
	text-align: center;
}

.media-block img{
	display: block;
	width: 100%;
	-webkit-box-sizing:border-box;
	-ms-box-szing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
}
.media-block .kids_picture{
	border: 10px solid;
	border-radius: 10px;
}

@media screen and (max-width: 1190px){
	.recent_projects .projects_carousel{
		width: calc(100% + 20px);
		margin-left: -20px;
	}
}
@media screen and (max-width: 980px){
	.recent_projects .projects_carousel{
		width: calc(100% + 18px);
		margin-left: -18px;
	}
}

/* color versions (carousel) */
.owl-carousel .owl-buttons>div,
main .cws-widget .carousel_header .widget_carousel_nav>i{
	border-radius: 3px;
}

.cws_video_shortcode figure{
	font-size: 0;
}


.kids_slider_wrapper .camera_pagination {
	width: 100%;
	position: inherit;
	text-align: center;
	z-index: 999999;
	bottom: 55px;
}

.kids_slider_bg.flex .kids_slider_wrapper .camera_pagination {
	position: static;
}

.kids_slider_wrapper .camera_wrap .camera_pag .camera_pag_ul li {
    height: 11px;
    margin: 0 0 0 4px;
    width: 11px;
}
.kids_slider_wrapper .camera_pag .camera_pag_ul .cameracurrent > span,
.kids_slider_wrapper .camera_pag .camera_pag_ul .camera_hover > span
{
	background: none !important;
}
.tparrows.default{
	background-image: none !important;
	width: 44px !important;
	height: 44px !important;
	opacity: 0;
	text-align: center;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.tparrows.default:after{
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0.8;
	z-index: -1;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-ms-border-radius: 5px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}
.tparrows.default:hover:after{
	opacity: 1;
}
.tparrows{
	z-index: 20 !important;
}
.tp-leftarrow.tparrows.default,
.tp-rightarrow.tparrows.default{
	top: calc(50% - 22px) !important;
	margin-top: 0 !important;
}
.tp-rightarrow.tparrows.default{
	right: 2px !important;
}
.tp-leftarrow.tparrows.default{
	left: 2px !important;
}

.tparrows.default:before{
	font-family: FontAwesome;
	line-height: 44px !important;
	color: #fff;
	font-size: 38px !important;
}
.tp-leftarrow.tparrows.default:before{
	content: "\f104";
}
.tp-rightarrow.tparrows.default:before{
	content: "\f105";
}
.tp-rightarrow.tparrows:before{
	padding-left: 0.15em;
}

.flex .kids_slider_inner_wrapper {
	height: auto !important;
}

/**************************** FORM ****************************/
form label {
	margin: 0.2em 0 !important;
	vertical-align:middle !important;
}
select{
	height: auto;
	-webkit-box-sizing:border-box;
	-ms-box-szing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
}

input,select{
	width:auto;
	outline:none;
 	height: auto;
 	line-height: 1.24em;
	padding: 10px;
	border: none;
	color: inherit;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-sizing:border-box;
	-ms-box-szing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
}

input[type*="text"],
input[type="password"],
input[type="email"],
.wpcf7 input{
	border:1px solid;
}
.wpcf7 .wpcf7-form-control-wrap{
	margin-bottom: 10px;
	display: inline-block;
	max-width: 100%
}
.wpcf7 input[type="submit"]{
	-webkit-appearance: none;
	border:0;
	position: relative;
	display: inline-block;
	padding: 0.4em 0.6em 0.4em;
	font-size: 18px;
	outline: none;
	text-align: center;
	font-weight: 700;
	text-decoration: none !important;
	font-style: normal !important;
	cursor: pointer;
	line-height: 1;
	color: #fff;
	height: auto;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	float: right;
}
.wpcf7 .wpcf7-captchac{
	vertical-align: middle;
}
select option{
	padding:0 10px;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;
	box-sizing:border-box;
}
select{
	text-align:center;
	font-family: inherit;
	font-variant: inherit;
	font-stretch: inherit;
	font-weight: 400px;
	padding: 6px;
	font-size: 1em;
	float: right;
	margin-bottom: 20px;
	background: transparent !important;
}
input[type*="submit"]{
	line-height:1;
}
form input{
	display:block;
}
form label{
	margin:0.2em 0;
}
form label {
	display:inline-block;
	vertical-align: middle;
}
form input.input-checkbox{
	display:inline-block;
	vertical-align:middle;
}
form label img{
	vertical-align: middle;
	margin:0 1em;
}
form textarea{
	display:block;
	border: 1px solid;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	width:100%;
	padding: 10px 10px;
	color: inherit;
	resize: none;
	-webkit-box-sizing:border-box;
	-ms-box-szing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
}
input[type="range"]{
	margin-left:0;
	padding:0;
	border:0 !important;
}
select,input[type="date"]{
	max-width: 100%;
}
select,input,textarea{
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-appearance: none;
}
input[type="radio"]{
	padding:0;
	display:inline-block;
	position:relative;
	-webkit-appearance:none;
	appearance:none;
	-webkit-box-sizing:border-box;
	-ms-box-szing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	background: #fff;
	border:1px solid;
	width:16px;
	height:16px;
	border-radius:50%;
	margin-top:0;
	vertical-align: middle;
}
input[type="radio"]:before{
	display:block;
	position:absolute;
	content:"";
	width:10px;
	height:10px;
	-webkit-box-sizing:border-box !important;
	-ms-box-szing:border-box !important;
	-moz-box-sizing:border-box !important;
	box-sizing:border-box !important;
	border-radius:50%;
	top:2px;
	left:2px;
	background:transparent;
	z-index:999999;
}
input[type="file"]{
	padding:0;
	border:0;
	background: none;
	vertical-align:middle;
}
input[type="checkbox"]{
	display:inline-block;
	vertical-align: middle;
	margin-top:0;
	margin-bottom:0;
	padding:0;
	line-height:0;
	-webkit-appearance:none;
	appearance:none;
	width:16px;
	height:16px;
	position:relative;
	-webkit-box-sizing:border-box;
	-ms-box-szing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	border-radius:3px;
	background: #fff;
	border:1px solid;
	vertical-align: middle;
}
input[type="checkbox"]:before{
	content:"";
	display:block;
	position:absolute;
	width:100%;
	height:100%;
	left:0;
	top:0;
	font-size:19px;
	line-height:0.5;
	text-align: center;
	z-index:999999;
}
input[type="checkbox"]:checked:before{
	content:"\2714";
}
/***************** BUTTONS *********************/
[type="submit"]{
	outline:none !important;
	padding: 0.4em 0.6em 0.4em;
	font-size: 15px;
	cursor: pointer;
	font-family: Arial;
	line-height: 1.4;
	-webkit-border-radius: 4px;
	   -moz-border-radius: 4px;
			border-radius: 4px;
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	transition:all 0.3s ease;
}

.footer [type="submit"],
.top-panel [type="submit"],
.footer .cws_button,
.top-panel .cws_button{
	background: rgba(255,255,255,0.5) !important;
	color: #fff;
}
.footer [type="submit"]:hover,
.top-panel [type="submit"]:hover,
.footer .cws_button:hover,
.top-panel .cws_button:hover{
	background: rgba(0,0,0,0.3) !important;
}

.footer .cws_button:after,
.top-panel .cws_button:after{
	display: none;
}

/***************** BUTTONS *********************/
div.wpcf7-response-output{
	color: #fff;
	margin: 1em 0;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	border-radius: 8px;
	min-height: 35px;
	padding: 15px 15px 15px 53px;
	overflow: hidden;
	position: relative;
	border: 0;
	z-index: 1;
}
div.wpcf7-response-output:before {
	content: "";
	position: absolute;
	top: 15px;
	left: 10px;
	width: 35px;
	height: 35px;
	background: rgba(255, 255, 255, 0.9);
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	font-size: 28px;
	line-height: 35px;
	text-align: center;
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}
.wpcf7-validation-errors{
	background: #fb7f7f;
}
div.wpcf7-response-output.wpcf7-validation-errors:before{
	content: "\f00d";
	color: #fb7f7f;
}
.no-ie div.wpcf7-response-output.wpcf7-validation-errors:before{
	text-shadow: rgb(229, 218, 218) 1px 1px,
	 rgb(229, 218, 218) 2px 2px,
	 rgb(229, 218, 218) 3px 3px,
	 rgb(229, 218, 218) 4px 4px,
	 rgb(229, 218, 218) 5px 5px,
	 rgb(229, 218, 218) 6px 6px,
	 rgb(229, 218, 218) 7px 7px,
	 rgb(229, 218, 218) 8px 8px,
	 rgb(229, 218, 218) 9px 9px,
	 rgb(229, 218, 218) 10px 10px,
	 rgb(229, 218, 218) 11px 11px,
	 rgb(229, 218, 218) 12px 12px,
	 rgb(229, 218, 218) 13px 13px,
	 rgb(229, 218, 218) 14px 14px,
	 rgb(229, 218, 218) 15px 15px,
	 rgb(229, 218, 218) 16px 16px,
	 rgb(229, 218, 218) 17px 17px,
	 rgb(229, 218, 218) 18px 18px,
	 rgb(229, 218, 218) 19px 19px,
	 rgb(229, 218, 218) 20px 20px;
}
.wpcf7-mail-sent-ok{
	background: #8ddd67;
}
div.wpcf7-response-output.wpcf7-mail-sent-ok:before{
	content: "\f00c";
	color: #8ddd67;
}
.no-ie div.wpcf7-response-output.wpcf7-mail-sent-ok:before{
	text-shadow: rgb(219, 226, 216) 1px 1px,
	 rgb(219, 226, 216) 2px 2px,
	 rgb(219, 226, 216) 3px 3px,
	 rgb(219, 226, 216) 4px 4px,
	 rgb(219, 226, 216) 5px 5px,
	 rgb(219, 226, 216) 6px 6px,
	 rgb(219, 226, 216) 7px 7px,
	 rgb(219, 226, 216) 8px 8px,
	 rgb(219, 226, 216) 9px 9px,
	 rgb(219, 226, 216) 10px 10px,
	 rgb(219, 226, 216) 11px 11px,
	 rgb(219, 226, 216) 12px 12px,
	 rgb(219, 226, 216) 13px 13px,
	 rgb(219, 226, 216) 14px 14px,
	 rgb(219, 226, 216) 15px 15px,
	 rgb(219, 226, 216) 16px 16px,
	 rgb(219, 226, 216) 17px 17px,
	 rgb(219, 226, 216) 18px 18px,
	 rgb(219, 226, 216) 19px 19px,
	 rgb(219, 226, 216) 20px 20px;
}

div.wpcf7 input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]).error,
.widget div.wpcf7 select.error,
.widget div.wpcf7 textarea.error{
	border-color: #fb7f7f;
}

/***************** /BUTTONS *********************/
/***************** wpcf7 *********************/
div.wpcf7 .wpcf7-form-control-wrap{
	margin-left: 8px;
}
form.wpcf7-form>p{
	margin-left: -8px;
	text-indent: 8px;
}
form.wpcf7-form>p:after{
	display: table;
	content: "";
	clear: both;
}
form.wpcf7-form>p>*:first-child{
 margin-left: 0;
 text-indent: 0;
}
.footer .wpcf7-form-control-wrap select,
.footer .wpcf7-form-control-wrap textarea,
.footer .wpcf7-form-control-wrap input[type="url"],
.footer .wpcf7-form-control-wrap input[type="tel"],
.footer .wpcf7-form-control-wrap input[type="text"],
.footer .wpcf7-form-control-wrap input[type="email"],
.footer .wpcf7-form-control-wrap input[type="password"],
.footer .wpcf7-form-control-wrap input[type="search"],
.footer .wpcf7-form-control-wrap input[type="date"],
.footer .wpcf7-form-control-wrap input[type="number"],
.top-panel .wpcf7-form-control-wrap select,
.top-panel  .wpcf7-form-control-wrap textarea,
.top-panel .wpcf7-form-control-wrap input[type="url"],
.top-panel .wpcf7-form-control-wrap input[type="tel"],
.top-panel .wpcf7-form-control-wrap input[type="text"],
.top-panel .wpcf7-form-control-wrap input[type="email"],
.top-panel .wpcf7-form-control-wrap input[type="password"],
.top-panel .wpcf7-form-control-wrap input[type="search"],
.top-panel .wpcf7-form-control-wrap input[type="date"],
.top-panel .wpcf7-form-control-wrap input[type="number"],
aside .wpcf7-form-control-wrap select,
aside .wpcf7-form-control-wrap textarea,
aside .wpcf7-form-control-wrap input[type="url"],
aside .wpcf7-form-control-wrap input[type="tel"],
aside .wpcf7-form-control-wrap input[type="text"],
aside .wpcf7-form-control-wrap input[type="email"],
aside .wpcf7-form-control-wrap input[type="password"],
aside .wpcf7-form-control-wrap input[type="search"],
aside .wpcf7-form-control-wrap input[type="date"],
aside .wpcf7-form-control-wrap input[type="number"]{
	width: 100%;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;
	box-sizing:border-box;
}
.widget div.wpcf7{
	background:transparent;
	border:0;
	padding:0;
}
 div.wpcf7 input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
.widget div.wpcf7 select,
.widget div.wpcf7 textarea{
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	-ms-box-sizing:border-box;
	box-sizing:border-box;
	max-width: 100%;
}
.footer #search-form input,
.top-panel #search-form input,
.footer .wpcf7 input,
.top-panel .wpcf7 input,
.footer .wpcf7 textarea,
.top-panel .wpcf7 textarea{
	border-color: rgba(255,255,255,0.5);
}
/***************** /wpcf7 *********************/

.kids_bottom_container .textwidget{
	padding: 0;
}


/**************************** /FORM ****************************/
@media screen and (max-width:767px){

	div.wpcf7 input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
	.widget div.wpcf7 select,
	.widget div.wpcf7 textarea{
		width: 100%;
	}
	form.wpcf7-form>p>*:first-child input {
		width: calc(100% - 8px) !important;
	}
	#post-content:after{
		display:block;
		content:"";
		clear:both;
	}
	.product-category{
		max-width:100%;
		float:none !important;
	}
	.product-category img{
		height: auto !important;
		width: 100% !important;
		-webkit-box-sizing:border-box !important;
		-moz-box-sizing:border-box !important;
		-ms-box-sizing:border-box !important;
		box-sizing:border-box !important;
	}
}

li.empty{
	width:100% !important;
	padding-left:0 !important;
	text-align: center !important;
}

/* fonts */
html body{
	font-family: Lato;
	color: #a2825c;
	font-size: 16px;
	line-height: 1.375;
}

.pagenavi .prevpostslink:before,
.pagenavi .nextpostslink:before,
a.page-numbers,
span.page-numbers{
	font-size: 16px;
}

a.alt,
.footer .wpcf7,
#breadcrumbs .delimiter:before,
.footer input,
.top-panel input,
select,
input[type="date"]{
	color: #a2825c;
}
#kids_main_nav a{
	font-family: 'Salsa', cursive;
	color: #fff;
	font-size: 19px;
	line-height: 1.5;
}

#free-trial-button a{
	font-family: 'Salsa', cursive;
	color: #fff;
	font-size: 19px;
	line-height: 1.5;
	background-color: orange;
}

.section-title,
.post-content .widget-title,
.entry-container aside:not(#sidebar-right):not(#sidebar-left) .widget-title,
.entry-container main .widget-title{
	font-size: 32px;
	color: #3185cb;
	line-height: 1.25;
}

.widget-title,
.section-title,
.title,
.header_container h1,
#respond_block h1,
.button,
#respond_block .comment-reply-link,
#respond_block .comment-edit-link,
.cws_button,
input[type="submit"],
.widget_calendar caption,
.pricing_table_column .price_part,
blockquote:before,
blockquote:after,
.testimonial p:before,
.testimonial p:after,
.slogan>.widget_text:before,
.slogan>.widget_text:after,
.post_cats span,
.post_tags span,
.post-meta .post-date,
.post-title a,
ul.products li.product .price,
.woocommerce .widget_shopping_cart_content .buttons a.button,
.woocommerce .products a.add_to_cart_button,
.woocommerce .products a.added_to_cart,
.woocommerce .summary>.product_title,
.woocommerce .summary .price,
.woocommerce-page .button,
.woocommerce-page [type="submit"],
.woocommerce-page [type="button"]:not(.plus):not(.minus),
.woocommerce-page .added_to_cart,
.woocommerce .button,
.woocommerce [type="submit"],
.woocommerce [type="button"]:not(.plus):not(.minus),
.woocommerce .added_to_cart,
.holder404,
.ui-datepicker-title{
	font-family: 'Salsa', cursive;
}
.ui-datepicker-prev.ui-corner-all,
.ui-datepicker-next.ui-corner-all{
	display: none;
}
#respond_block .comment-author{
	color: #3185cb;
}

a,
ul.type-2 li:before,
.post-title a:hover,
a.alt:hover{
	color: #3185cb;
}



/* Colors for: top bar, menu, buttons, widget titles */
.cws_button ,
.kids_curtain:before,
.accordion li a.opener:before,
ul li:before,
.post-meta .post-date:before,
b.trigger:before,
.accordion_title .accordion_icon,
.accordion_title  .acc_featured_icon,
aside .widget h3.widget-title,
aside .widget.type-3 h3.widget-title .cloud-element-1:before,
aside .widget.type-3 h3.widget-title .cloud-element-1:after,
aside .widget.type-3 h3.widget-title .cloud-element-2:before,
aside .widget.type-3 h3.widget-title .cloud-element-2:after,
aside .widget.type-3 h3.widget-title .cloud-element-3:before,
aside .widget.type-3 h3.widget-title .cloud-element-3:after,
#kids_main_nav ul ul li:hover,
#kids_main_nav > ul > li > a:before,
#kids_main_nav ul ul,
.touch_device #kids_main_nav>ul,
.single_bar .progress,
#respond_block .comment-reply-link,
#respond_block .comment-edit-link,
#post_comment,
#search-form .button,
.wpcf7 input[type="submit"],
input[type="radio"]:checked:before,
.soc_icon.fa,
.woocommerce .widget_shopping_cart_content .buttons a.button,
.woocommerce .products a.add_to_cart_button,
.woocommerce .products a.added_to_cart,
.woocommerce-page .button,
.woocommerce-page [type="submit"],
.woocommerce-page [type="button"]:not(.plus):not(.minus),
.woocommerce-page .added_to_cart,
.woocommerce .button,
.woocommerce [type="submit"],
.woocommerce [type="button"]:not(.plus):not(.minus),
.woocommerce .added_to_cart,
#kids-back-top span,
.gl_cat .backLava,
.nav_cat .backLava,
.owl-controls.clickable .owl-pagination .owl-page.active span,
.tparrows.default:after,
.menu-button{
	background-color: #3185cb;
}

.pricing_table_header .title,
.widget_categories ul li:before,
.catappendspan:before{
	color: #3185cb;
}

input[type="checkbox"]:before{
	color: #3185cb;
}
.latest_tweets li .icon_frame i.fa:before{
	background-color: rgba(49,133,203,0.4);
}

.cws_button:hover,
#kids_main_nav > ul > li:hover > a:before,
#kids_main_nav > ul > li.current-menu-item > a:before,
#kids_main_nav > ul > li.current-menu-item>a + li >a:before,
#kids_main_nav > ul > li.current-menu-ancestor > a:before,
#kids_main_nav > ul > li ul li.current-menu-item,
#kids_main_nav > ul > li ul li.current-menu-parent,
#respond_block .comment-reply-link:hover,
#respond_block .comment-edit-link:hover,
#post_comment:hover,
#search-form .button:hover,
.wpcf7 input[type="submit"]:hover,
.woocommerce .widget_shopping_cart_content .buttons a.button:hover,
.woocommerce .products a.add_to_cart_button:hover,
.woocommerce .products a.added_to_cart:hover,
.woocommerce-page .button:hover,
.woocommerce-page [type="submit"]:hover,
.woocommerce-page [type="button"]:not(.plus):not(.minus):hover,
.woocommerce-page .added_to_cart:hover,
.woocommerce .button:hover,
.woocommerce [type="submit"]:hover,
.woocommerce [type="button"]:not(.plus):not(.minus):hover,
.woocommerce .added_to_cart:hover,
#kids-back-top a:hover span{
	background: #ff633c;
}
.cws_button:hover,
.soc_icon.fa:hover{
	background: #ff633c !important;
}

/* Colors for: footer */
.footer,
.footer .widget_calendar table tbody,
.top-panel .widget_calendar table tbody,
.top-panel,
#kids_header .kids_social .openbtn a,
#kids_header .kids_social span,
#kids_header .kids_social li.hide,
.kids_social .lang_bar>div>ul a:after,
.kids_social .lang_bar>div>ul a:before{
	background-color: #3185cb;
}
#kids_header .kids_social li,
.kids_social .lang_bar>div>ul>li>a:before{
	background-color: rgba(49,133,203,0.65);
}
.top-panel{
	border-color: #3185cb;
}

/* Colors for: middle content (the sand color) */
aside .widget>.latest-posts-widget>.widget-content,
aside .widget.widget_categories>.ui-widget,
aside .widget.widget_archive>ul,
aside .widget_calendar>#calendar_wrap,
aside .widget_recent_comments>#recentcomments,
aside .widget>.textwidget,
aside .widget>.tagcloud,
aside .widget_search>#search-form,
aside .widget_cws_video .widget-content>.widget_video,
aside .widget>.contact-us,
aside .widget_cws_nav>.widget-content>.nav_cat,
aside .widget_meta>ul,
aside .widget_recent_entries>ul,
aside .widget_pages>ul,
aside .widget_categories>ul,
aside .cws_tweets,
aside .postform,
aside .flickr-badge,
aside .widget_cws_benefits .cws-widget-content .text_part,
aside .widget_shopping_cart_content,
aside .woocommerce.widget_price_filter>form,
aside .woocommerce.widget_product_categories .product-categories,
aside .woocommerce.widget_products .product_list_widget,
aside .woocommerce.widget_product_search>form,
aside .woocommerce.widget_recent_reviews .product_list_widget,
aside .woocommerce.widget_recently_viewed_products .product_list_widget,
aside .woocommerce.widget_top_rated_products  .product_list_widget,
aside .woocommerce.widget_layered_nav_filters>ul,
.flickr-badge img,
.kids_image_wrapper,
.woocommerce .product_list_widget img{
	border-color: #fcf5d5;
}

.kids_top_content_middle,
.post-entry,
.accordion_content,
.callout_widget .icons_part,
.pagenavi .page,
.pagenavi .nextpostslink,
.pagenavi .prevpostslink,
.pagenavi a.page-numbers,
.tab_items,
.tab_container,
.widget.woocommerce.widget_price_filter .ui-slider-range,
.pricing_table_column .button_part,
.page-content blockquote,
.testimonial{
	background-color: #fcf5d5;
}
.chosen-container-single .chosen-single,
.chosen-container-active.chosen-with-drop .chosen-single,
.country_select{
	background: #fcf5d5;
}

/* Colors for middle content helper */
.media-block .kids_picture,
body.home .kids_top_content_middle.homepage .bottom-border,
.kids_top_content_middle,
.entry-container #comments ol > .comment,
.entry-container .children .comment,
form textarea,
input[type*="text"],
input[type="password"],
input[type="email"],
textarea,
.user-login input[type="text"],
.search input[type="text"],
.contact-us input[type="text"],
.contact-us textarea,
.wpcf7 input,
.pricing_table_column,
.map-container,
.entry-container #respond_block,
.avatar,
pre,
table,
th,
td,
.kids_posts_container .widget_wrapper>div,
.tabs .tab,
.accordion_section,
.accordion_widget,
.toggle_widget,
.tab_items,
.slogan:after,
.callout_widget,
img.border,
select,
.woocommerce-ordering>select,
.woocommerce-result-count+ul.products,
.woocommerce-ordering+ul.products,
.content-wrapper figure,
.woocommerce div.product div.images div.thumbnails .content-wrapper figure,
.woocommerce-page div.product div.images div.thumbnails .content-wrapper figure,
.woocommerce-page #reviews #comments ol.commentlist li,
.woocommerce-tabs #reviews #comments ol.commentlist li,
.woocommerce .product-thumbnail img,
.chosen-container-single .chosen-single,
.chosen-container .chosen-drop,
.chosen-container-active.chosen-with-drop .chosen-single,
.chosen-container-single .chosen-search input[type=text],
#order_review,
.iso-column .iso-item,
.portfolio>.item,
.portfolio .filter,
.widget.woocommerce.widget_shopping_cart ul>li,
.widget.woocommerce.widget_price_filter .ui-slider-horizontal,
.widget.woocommerce.widget_price_filter .ui-slider-handle,
.widget.woocommerce.widget_product_categories li,
.widget.woocommerce.widget_layered_nav li,
.widget.woocommerce.widget_product_categories li ul li:first-child,
.widget.woocommerce.widget_layered_nav li ul li:first-child,
.widget.woocommerce.widget_shopping_cart ul>li,
.woocommerce .product_list_widget>li,
ul.tabs>li,
.woocommerce-page .shipping_calculator,
.woocommerce-page .cart_totals,
.woocommerce-page .shipping_calculator .shipping-calculator-form .country_to_state,
main .cws_tweets .latest_tweets li,
aside .cws_tweets .latest_tweets li,
aside .widget_recent_entries ul li,
aside .widget_archive ul li,
aside .widget_pages ul li,
aside .widget_nav_menu ul li,
aside .cws_tweets .latest_tweets li,
aside .widget_categories ul li,
aside .widget_recent_comments ul li,
aside .gl_cat ul > li,
aside .nav_cat ul > li,
aside .latest-posts-widget ul li,
aside .widget_meta ul li,
.tab_container,
.widget_recent_entries ul li,
.widget_archive ul li,
.widget_pages ul li,
.widget_nav_menu ul li,
.widget_categories ul li,
.widget_recent_comments ul li,
.widget_meta ul li,
.widget.woocommerce.widget_product_categories li,
.widget.woocommerce.widget_layered_nav li,
.latest-posts-widget ul li,
.gl_cat ul > li,
.nav_cat ul > li,
.latest_tweets li,
.widget_calendar #calendar_wrap,
.country_select,
.select2-container--default .select2-selection--single{
	border-color: #fee6b4;
}
.owl-carousel .owl-buttons>div,
main .cws-widget .carousel_header .widget_carousel_nav i,
.pricing_table_column .price_part,
.pricing_table_header,
.tabs .tab.active,
.accordion_section.active .accordion_title,
.accordion_section .accordion_title:hover,
.pagenavi .current,
.pagenavi .page:hover,
.pagenavi .prevpostslink:hover,
.pagenavi .nextpostslink:hover,
.pagenavi a.page-numbers:hover,
.widget_calendar table td#today:before,
.widget_calendar table td>a:before,
.post-footer,
ul.tabs>li.active,
.post-meta .post-comments,
.widget_calendar caption,
.widget_cws_benefits .widget-icon>i{
	background-color: #fee6b4;
}

/* Colors for: main color */
.kids-bg-level-1{
	background: #8fc0ea;
}
.kids_social .lang_bar>div>ul ul{
	border-color: #8fc0ea;
}

/* sand color */
hr{
	border-color: #fee6b4;
}



/* long shadow for kids_curtain */
.no-ie .kids_curtain:before{
	text-shadow: rgb(39,106,162) 1px 1px,
	 rgb(39,106,162) 2px 2px,
	 rgb(39,106,162) 3px 3px,
	 rgb(39,106,162) 4px 4px,
	 rgb(39,106,162) 5px 5px,
	 rgb(39,106,162) 6px 6px,
	 rgb(39,106,162) 7px 7px,
	 rgb(39,106,162) 8px 8px,
	 rgb(39,106,162) 9px 9px,
	 rgb(39,106,162) 10px 10px,
	 rgb(39,106,162) 11px 11px,
	 rgb(39,106,162) 12px 12px,
	 rgb(39,106,162) 13px 13px,
	 rgb(39,106,162) 14px 14px,
	 rgb(39,106,162) 15px 15px,
	 rgb(39,106,162) 16px 16px,
	 rgb(39,106,162) 17px 17px,
	 rgb(39,106,162) 18px 18px,
	 rgb(39,106,162) 19px 19px,
	 rgb(39,106,162) 20px 20px;
}

.pp_social {display:none;}
.page-content .container {padding-bottom: 160px;}
.kids_bottom_content .content_bottom_bg{height:160px}
.bg-level-1 {background-image: url(../images/clouds_retina4-2yqdoj5quytw8tu84f9b7u.png);}
.kids_bottom_content .content_bottom_bg {background-image: url(../images/grass_retina4-2yqdohngqzju07o7oeuwwa.png);}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (-o-device-pixel-ratio: 3/2),
					only screen and (min-device-pixel-ratio: 1.5) {.bg-level-1 {background-image: url(../images/clouds_retina4.png); background-size: 1928px;}
.kids_bottom_content .content_bottom_bg {background-image: url(../images/grass_retina4.png); background-size: 1920px;}
}


/*   gallery  */

.ngallery{
	margin-left: -2%;
}
.ngallery dd{
	margin-bottom: 0;
}
.ngallery .content-wrapper figure,
.ngallery .content-wrapper figure img{
	width: 100%;
	margin: 0;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-ms-box-sizing: border-box;
}
.ngallery .content-wrapper figure>a{
	display:block;
}
.ngallery>.gallery-item{
	float: left;
	margin-left: 2%;
	margin-bottom: 2%;
}
.ngallery.column-1>.gallery-item{
	width: 98%;
}
.ngallery.column-2>.gallery-item{
	width: 48%;
}
.ngallery.column-3>.gallery-item{
	width: 31.333%;
}
.ngallery.column-4>.gallery-item{
	width: 23%;
}
.ngallery.column-5>.gallery-item{
	width: 18%;
}
.ngallery.column-6>.gallery-item{
	width: 14.66%;
}
.ngallery.column-7>.gallery-item{
	width: 12.28%;
}
.ngallery.column-8>.gallery-item{
	width: 10.5%;
}
.ngallery.column-9>.gallery-item{
	width: 9.11%;
}

.ngallery.column-2>.gallery-item:nth-child(2n+3),
.ngallery.column-3>.gallery-item:nth-child(3n+4),
.ngallery.column-4>.gallery-item:nth-child(4n+5),
.ngallery.column-5>.gallery-item:nth-child(5n+6),
.ngallery.column-6>.gallery-item:nth-child(6n+7),
.ngallery.column-7>.gallery-item:nth-child(7n+8),
.ngallery.column-8>.gallery-item:nth-child(8n+9),
.ngallery.column-9>.gallery-item:nth-child(9n+10){
	clear: left;
}

@media screen and (max-width: 980px){
	.ngallery.column-5>.gallery-item,
	.ngallery.column-6>.gallery-item,
	.ngallery.column-7>.gallery-item,
	.ngallery.column-8>.gallery-item,
	.ngallery.column-9>.gallery-item{
		width: 23%;
	}
	.ngallery.column-5>.gallery-item:nth-child(5n+6),
	.ngallery.column-6>.gallery-item:nth-child(6n+7),
	.ngallery.column-7>.gallery-item:nth-child(7n+8),
	.ngallery.column-8>.gallery-item:nth-child(8n+9),
	.ngallery.column-9>.gallery-item:nth-child(9n+10){
		clear: none;
	}
	.ngallery.column-5>.gallery-item:nth-child(4n+5),
	.ngallery.column-6>.gallery-item:nth-child(4n+5),
	.ngallery.column-7>.gallery-item:nth-child(4n+5),
	.ngallery.column-8>.gallery-item:nth-child(4n+5),
	.ngallery.column-9>.gallery-item:nth-child(4n+5){
		clear: left;
	}
}
@media screen and (max-width: 767px){
	.ngallery{
		margin-left: 0;
	}
	.ngallery.column-2>.gallery-item,
	.ngallery.column-3>.gallery-item,
	.ngallery.column-4>.gallery-item,
	.ngallery.column-5>.gallery-item,
	.ngallery.column-6>.gallery-item,
	.ngallery.column-7>.gallery-item,
	.ngallery.column-8>.gallery-item,
	.ngallery.column-9>.gallery-item{
		width: 100%;
		margin-left: 0;
	}
	.ngallery.column-3>.gallery-item:nth-child(4n+5),
	.ngallery.column-4>.gallery-item:nth-child(4n+5),
	.ngallery.column-5>.gallery-item:nth-child(4n+5),
	.ngallery.column-6>.gallery-item:nth-child(4n+5),
	.ngallery.column-7>.gallery-item:nth-child(4n+5),
	.ngallery.column-8>.gallery-item:nth-child(4n+5),
	.ngallery.column-9>.gallery-item:nth-child(4n+5){
		clear: none;
	}
	.ngallery.column-3>.gallery-item:nth-child(2n+3),
	.ngallery.column-4>.gallery-item:nth-child(2n+3),
	.ngallery.column-5>.gallery-item:nth-child(2n+3),
	.ngallery.column-6>.gallery-item:nth-child(2n+3),
	.ngallery.column-7>.gallery-item:nth-child(2n+3),
	.ngallery.column-8>.gallery-item:nth-child(2n+3),
	.ngallery.column-9>.gallery-item:nth-child(2n+3){
		clear: left !important;
	}


}


.tp-caption a {
    color: #fff;
    text-shadow: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out
}

.tp-caption a:hover {
    color: #fff
}

.tp-caption.kids-slider-header,
.kids-slider-header {
    font-size: 43px;
    line-height: 36px;
    font-weight: 700;
    font-family: "'Salsa', cursive";
    color: rgb(255, 255, 255);
    text-decoration: none;
    padding: 6px 21px 12px;
    text-shadow: none;
    background-color: rgb(49, 133, 203);
    border-radius: 7px 7px 7px 7px;
    border-width: 0px;
    border-color: rgb(0, 0, 0);
    border-style: none
}

@media screen and (max-width: 1200px) {
    .tp-caption.kids-slider-header,
    .kids-slider-header {
        font-size: 30px;
        line-height: 28px;
        font-weight: 550;
        font-family: "'Salsa', cursive";
        color: rgb(255, 255, 255);
        text-decoration: none;
        padding: 6px 21px 12px;
        text-shadow: none;
        background-color: rgb(49, 133, 203);
        border-radius: 7px 7px 7px 7px;
        border-width: 0px;
        border-color: rgb(0, 0, 0);
        border-style: none;

    }
}

.tp-caption.kids-slider-header-alt,
.kids-slider-header-alt {
    font-size: 19px;
    line-height: 20px;
    font-weight: 400;
    font-family: "'Salsa', cursive";
    color: rgb(255, 255, 255);
    text-decoration: none;
    padding: 8px 21px;
    text-shadow: none;
    background-color: rgb(255, 99, 60);
    border-radius: 7px 7px 7px 7px;
    border-width: 0px;
    border-color: rgb(0, 0, 0);
    border-style: none
}

/* fix class */
.margin-top-none {
	margin-top: 0 !important;
}
.margin-bottom-none {
	margin-bottom: 0 !important;
}
.select2.select2-container {
	width: 100% !important;
}

/* Base styles */
.testimonial-table {
  width: 100%;
  border: none;
  border-collapse: separate;
}

.testimonial-table tr,
.testimonial-table td {
  border: none;
}

.testimonial-wrapper {
  float: left;
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-direction: row;
}

/* Responsive styles */
@media (max-width: 1023px) {
 .testimonial-wrapper {
   float: left;
   width: 100%;
   box-sizing: border-box;
   display: inline-block;
   display: flex;
   flex-direction: column;
 }
}

#trade-mark {
  font-size: smaller;
  position: relative;
  top: -0.5em;
  float: right;
}

