.Thankyou-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../public/images/Background.jpg");
    background-size: cover;
    min-height: 100vh;
}

.plan-title-head {
    color: #ffffff;
    margin-top: 60px;
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 40px;
}

.thankyou-text {
    color: #ffffff;
    font-size: 19px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0px;
}

.textGap {
    margin-top: 40px;
}

.thankyou-btn {
    display: flex;
    margin-top: 70px;
    justify-content: center;
}

.thankyou-btn button {
    width: 280px;
    height: 50px;
    padding: 10px 30px;
    border: none;
    border-radius: 30px;
    background-color: #ff613a;
    color: white;
    font-weight: bold;
    cursor: pointer;
    font-family: "Poppins";
    font-weight: 700;
    font-size: 19px;
}

.top-logo {
    position: absolute;
    top: 5px;
    left: 5px;
}

.top-logo > img {
    width: 60px;
    height: 55px;
}

@media screen and (max-width: 1024px) {
    .Thankyou-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: url("../public/images/Background.jpg");
        background-size: cover;
        min-height: 100vh;
        padding: 35px;
    }
}
