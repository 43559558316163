.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../public/images/Background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 30px;
}

.profile-title-head {
  color: #ffffff;
  font-family: "Lato";
  margin-top: 40px;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 40px;
}

.user-profile {
  width: 100%;
  max-width: 900px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-left: 50px;
  padding-right: 50px;
}

/* .profile-left-side{

} */

.prfile-data {
  display: flex;
  gap: 10px;
  color: #ffffff;
}

.prfile-data h5 {
  min-width: 139px;
  text-align: start;
  font-weight: 700;
}

.profile-form {
  width: 100%;
  max-width: 900px;
}

.profile-fields {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.profile-fields h4 {
  min-width: 280px;
  margin-top: 10px;
  font-weight: 600;
  color: #ffffff;
  text-align: end;
}

.profile-fields input {
  width: 100%;
  border: none;
  height: 45px;
  border-radius: 50px;
  color: #000000;
}

.profile-input::placeholder {
  padding: 0 10px !important;
}

.crad-number {
  display: flex;
  gap: 10px;
}

.save-btn {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

.save-btn button {
  width: 160px;
  height: 50px;
  padding: 10px 30px;
  border: none;
  border-radius: 30px;
  background-color: #ff613a;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 19px;
}

@media (max-width:1024px) {
  .profile {
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .profile-fields {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }

  .profile-right-side {
    align-self: flex-start;
  }

  .profile-left-side {
    align-self: flex-start;
  }

  .prfile-data h5 {
    min-width: 30px;
  }

  .profile-fields h4 {
    min-width: 80px;
    margin-top: 10px;
    font-weight: 600;
    color: #ffffff;
    text-align: start;
  }

  .user-profile {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 0px;
    padding-left: 26px;
  }

  .profile {
    padding: 15px;
  }
}
