.chatbox-container {
    display: flex;
    padding-top: 60px;
    justify-content: center;
    background-image: url("../public/images/Background.jpg");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    min-height: 100vh;
    position: relative;
}

.carousel-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    overflow: hidden;
}

#next-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 40%;
    right: 70px;
    padding: 15px;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
}
.clicked-label {
    background-color: #f9cf9d; /* You can change this to the desired color */
}

.feedbackControls {
    background-color: #0073e6; /* Change this to the desired background color */
    color: #ffffff; /* Change this to the desired text color */
    border: none; /* Remove any border if needed */
    cursor: pointer;
    padding: 10px 20px; /* Adjust padding as needed */
    font-size: 16px; /* Adjust font size as needed */
    margin-left: 0.5rem;
}

/* Style for the disabled button */
.disabled-button {
    background-color: darkgray; /* Change this to the desired color for the disabled button */
    color: white; /* Change this to the desired text color for the disabled button */
    cursor: not-allowed; /* Change the cursor style */
}
#previous-btn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 40%;
    left: 70px;
    padding: 15px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.leftborders {
    display: flex;
}

.rightBorders {
    display: flex;
}

.audio-button {
    position: absolute;
    top: 9px;
    left: 24px;
}

.audio-button > button {
    width: 116px !important;
    padding: 10px !important;
    display: flex !important;
    align-items: center;
    gap: 5px;
    margin-left: 5px;
    border-radius: 27px !important;
    background-color: #2b80c7 !important;
}

.audio-button > button > svg {
    font-size: 15px;
}

#page {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 100%;
}

.story-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* padding: 10px; */
    background-color: #2b80c7 !important;
    position: absolute;
    color: white !important;
    bottom: 17px;
    left: 24px;
    width: 64px;
    height: 40px;
    border-radius: 27px;
}

.audio-button > button > img {
    margin-left: 5px;
}
.div1 {
    height: 100%;
    width: 6px;
    background: #ff643d;
}
.div2 {
    height: 100%;
    width: 6px;
    background: #959595;
}
.div3 {
    height: 100%;
    width: 5px;
    background: #b5b5b5;
}
.div4 {
    height: 100%;
    width: 10px;
    background: #dcdcdc;
}
.div5 {
    height: 100%;
    background: #ffffff;
    display: flex;
    align-items: center;
    padding-left: 65px;
    /* padding-top: 30px;  */
    padding-right: 83px;
    padding-bottom: 15px;
}
.div6 {
    height: 100%;
    background: #eaeaea;
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    min-width: 100% !important;
}
.sendStory {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sendStory > button {
    display: flex;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    align-items: center;
    height: 55px !important;
    width: 210px;
    gap: 5px;
    font-weight: 600;
    border-radius: 27px !important;
    background-color: #ff643d !important;
}

.sendStory > button > img {
    margin-left: 14px;
    width: 36px !important;
    height: 24px !important;
}

.firstLetterStyle {
    color: black;
    font-weight: bold;
    font-size: 30px;
}

.mobileBorders {
    display: flex;
}

.mobileBorders .div4 {
    height: 100%;
    width: 10px;
    background: #dcdcdc;
}

.mobileBorders .div3 {
    height: 100%;
    width: 5px;
    background: #b5b5b5;
}
.mobileBorders .div2 {
    height: 100%;
    width: 6px;
    background: #959595;
}

#slick-parent {
    display: flex;
    min-width: 100%;
}

.slick-wrapper {
    display: flex !important;
    justify-content: center;
}

.slick-prev:before {
    content: none;
}

.slick-next:before {
    content: none;
}

.slick-prev {
    left: -32px;
}

.slick-next {
    z-index: 9999;
}

.slick-prev {
    z-index: 9999;
}

@media (max-width: 1030px) {
    #next-btn {
        display: none;
    }

    #previous-btn {
        display: none;
    }
    #page {
        flex-direction: column;
        height: 100%;
    }
    .chatbox-container {
        padding: 83px;
    }
    .carousel-container {
        display: none !important;
    }

    #reponsive-carousel {
        display: flex !important;
        flex-direction: column;
        height: 100%;
        border-radius: 10px !important;
        padding-top: 10px;
    }

    #bottomLogo {
        position: absolute;
        top: -13px;
        left: 400px;
    }
}

@media (max-width: 875px) {
    #bottomLogo {
        position: absolute;
        left: 46%;
        transform: translateX(-50%);
    }
}

@media (max-width: 768px) {
    #bottomLogo {
        position: absolute;
        left: 46%;
        transform: translateX(-50%);
    }
    .div6 {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
}

@media (max-width: 475px) {
    #bottomLogo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    .chatbox-container {
        padding-top: 60px;
        padding-left: 22px;
        padding-right: 23px;
        padding-bottom: 42px;
    }
}

@media (max-width: 400px) {
    #bottomLogo {
        position: absolute;
        left: 46%;
        transform: translateX(-50%);
    }
}

/* CSS for book page turn animation */
.flip-page {
    animation: flip-page-animation 0.6s forwards;
}

@keyframes flip-page-animation {
    0% {
        transform: perspective(1000px) rotateY(0deg);
    }
    50% {
        transform: perspective(1000px) rotateY(90deg);
    }
    100% {
        transform: perspective(1000px) rotateY(180deg);
    }
}

#mySelect option:checked {
    background-color: #ffcccb; /* Change to your desired color */
    color: #000; /* Change to your desired text color */
}
