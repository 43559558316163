.form-logo-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-logo-section img {
    max-width: 100%;
    height: auto;
}

.myform {
    margin-top: 20px;
}

.formHeading {
    text-align: center;
    font-size: 33px;
    color: white !important;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 25px;
    font-family: "Poppins";
}

.formrow {
    display: flex;
    align-items: center;
    max-width: 900px;
    width: 100%;
    gap: 20px;
    margin-bottom: 20px;
}

.formLabel {
    margin: 0;
    color: white;
    font-family: "Poppins";
    font-weight: bold;
    width: 154px;
}

.formlabelsmall {
    text-align: end;
}

.forminput {
    padding: 15px;
    border: none !important;
    border-radius: 30px;
    color: black;
    outline: none;
    height: 50px;
    width: 100%;
}

.forminput::placeholder {
    color: #7d7d7d;
    font-family: "Popins";
    font-weight: 500;
}

.forminput-short {
    max-width: 90px;
}

.forminput-medium {
    width: 428px;
}

.forminput-long {
    width: 785px;
}

.formrowBottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 575px;
}

.formLabel-end {
    text-align: end;
}

.submitbutton {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.submitbutton button {
    display: flex;
    align-items: center;
    gap: 5px;
}

#langiage-dropdown {
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    padding: 15px;
    text-align: left;
    background-color: white !important;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC")
        no-repeat right center / 30px 30px;
    margin-bottom: 0;
    border-radius: 30px;
    width: 193px;
}

.language-selected {
  background-color: white !important; 
  color: #000 !important; 
}

.language-label {
    text-align: start;
    margin-left: 10px;
    width: 100px;
}

#age{
    margin-left: -10px;
}

.submitbutton button {
    padding: 10px 30px;
    border: none;
    border-radius: 30px;
    background-color: #ff613a;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.submitbutton > button > img {
    width: 45px !important;
    height: 35px !important;
}

@media (max-width:1024px){
    .forminput{
        max-width: 661px;
        width:100%
    }

    .formrowBottom{
        flex-direction: column;
    }
        #age {
            margin-left: 0px;
        }
}

@media (max-width: 768px) {
    .form-logo-section {
        min-height: 100px;
    }
}

@media (max-width: 768px) {
    .formrow {
        flex-direction: column;
        align-items: flex-start;
    }

    .formLabel {
        width: auto;
        margin-bottom: 5px;
    }

    .forminput {
        width: 100%;
    }

    .submitbutton {
        margin-top: 20px;
    }

    .formrowBottom {
        flex-direction: column;
    }

    .formHeading {
        font-size: 24px;
        color: white !important;
    }

    .forminput-short,
    .forminput-medium,
    .forminput-long {
        width: 100%;
    }
 #age {
            margin-left: 0px;
     }
}

.form-field-error {
    border: 2px solid red !important;

}