.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../public/images/Background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

.login-inputs {
  width: 100%; /* Set the input container to occupy the full width */
  max-width: 400px; /* Set a maximum width for the input container */
  margin: 0 auto; /* Center the input container horizontally */
}

.login-feilds {
  border: none !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.login-feilds input {
  width: 100%;
  height: 50px;
  /* padding-left: 20px; */
  border-radius: 40px;
  border: none !important;
  color: black;
}

.top-logo {
  position: absolute;
  top: 5px;
  left: 5px;
}

.top-logo > img {
  width: 60px;
  height: 55px;
}

.google-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-password {
  margin-top: 25px;
}

.forgot-password span {
  color: #fbe7a0;
  font-family: "Lato";
  font-weight: 600;
  font-size: 18px;
}

.or-text {
  color: #ffffff;
  font-family: "Lato";
  font-weight: 600;
  margin-top: 5px;
}

.rounded-button {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 10px 40px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  max-width: 330px;
}

.google-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

button {
  margin: 10px 0;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background-color: #0077cc;
  color: white;
  cursor: pointer;
  width: 200px;
  text-align: center;
}

a {
  color: #0077cc;
  text-decoration: none;
  margin: 10px;
}

.link {
  margin-top: 10px;
  text-align: center;
  color: #ffffff;
  font-family: "Lato";
  font-weight: 600;
  font-size: 18px;
}

.link span {
  color: #fbe7a0;
  font-family: "Lato";
  font-weight: 600;
}

.linkmt {
  margin-top: 25px;
}

.error-message {
  white-space: normal;
}

.heading {
  text-align: left;
}

.table {
  max-width: 50%;
  overflow-x: auto;
  justify-content: center;
  align-items: center;
  position: relative;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
}

.table td {
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
}

td {
  width: 50%;
  border-collapse: collapse;
  border-spacing: 0;
  border: none;
}

/* .profile {
  font-family: "Lato";
  color: black;
  font-size: 16px;
  line-height: 1.375;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(background_img.png);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: "center";
  padding: "10%";
  height: auto;
} */

/* Default styles */
.contact-us {
  font-family: "Lato";
  color: black;
  font-size: 16px;
  line-height: 1.375;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../public/images/Background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  min-height: 100vh;
  padding-bottom: 20px;
}

.help-title-head {
  margin-top: 40px;
  color: #ffffff;
  font-size: 35px;
  font-family: "Lato";
  font-weight: 700;
  margin-bottom: 50px;
}

.text-div p {
  font-family: "Lato";
  font-family: "Lato";
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 0px !important;
}

.contact-fields {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 35px;
  width: 100%;
  max-width: 800px;
  padding: 20px;
}

.field-row {
  display: flex;
  gap: 5px;
}

.label {
  width: 100px; /* Adjust the width as needed */
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  font-family: "Lato";
  margin-bottom: 10px !important;
}

.subject-field {
  border: none !important;
  border-radius: 40px !important;
  height: 50px;
  font-size: 15px !important;
  font-family: "Lato";
}

#message {
  resize: vertical;
  border: none;
  padding: 12px !important;
  border-radius: 20px !important;
  font-size: 15px !important;
  font-family: "Lato";
}

.form-logo-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.form-logo-section img {
  max-width: 100%;
  height: auto;
}

.help-btn {
  display: flex;
  justify-content: center;
}

.help-btn button {
  position: relative;
  width: 160px;
  height: 50px;
  padding: 10px 30px;
  border: none;
  border-radius: 30px;
  background-color: #ff613a;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 19px;
}

/* Forgot password page Css  */

.forgot-form {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.forgot-field {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 649px;
  width: 100%;
  gap: 15px;
}

.forgot-field h4 {
  margin-top: 10px !important;
  font-weight: 700;
  color: #ffffff;
  width: 140px;
}

.forgot-field input {
  flex: 1;
  border: none !important;
  height: 55px;
  border-radius: 50px;
  color: #000000;
}

.forgot-btn {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

.forgot-btn button {
  min-width: 150px;
  height: 50px;
  padding: 10px 30px;
  border: none;
  border-radius: 30px;
  background-color: #ff613a;
  color: white;
  font-weight: bold;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 19px;
}

.forgot-title-head {
  color: #ffffff;
  font-family: "Lato";
  margin-top: 60px;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 60px;
}

/* Media queries for responsiveness */

/* Small devices (up to 768px) */
@media (max-width: 768px) {
  .contact-fields {
    flex-direction: column;
  }

  .field-row {
    flex-direction: column;
    flex: 1;
  }

  .label {
    align-self: flex-start;
  }

  .subject-field,
  #message {
    width: 100%;
  }

  .forgot-field {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .contact-us {
    padding-top: 20px;
    padding-bottom: 20px;
  }
    .forgot-field input {
      width: 100%;
      border: none !important;
      height: 55px;
    }
    .forgot-field h4 {
      text-align: start !important;
    }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .login-feilds {
    max-width: 400px;
  }
  .login-container {
    padding: 20px;
  }
}
